import {
  Box,
  CircularProgress,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import axios from '../axios';
import axiosAws from 'axios';
import SinglePhoto from './SinglePhoto';
import ProcedureHeader from './ProcedureHeader';
import Cookies from 'js-cookie';
import Review from './Review';
import CreatedUpdated from './CreatedUpdated';
import DeleteBtn from './DeleteBtn';
import Save from './Save';
import AlertDeleteDialog from "./Popup/alert-delete-dialog.component";
import { adminActions, caseRecordTypes } from '../util/constants';

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: '2rem',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    },
    flex: {
      display: 'flex',
      columnGap: 10,
      marginBottom: '1rem',
    },
    singlePhotoBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      ['@media only screen and (max-width: 500px) ']: {
        flex: '33%',
      },
    },
    imageTitle: {
      color: '#212B36',
      fontSize: '9px !important',
      letterSpacing: 0,
      lineHeight: '16.8px',
      textAlign: 'center',
    },
    progressContainer: {
      marginBottom: '5px',
    }
  };
});
const AppointmentXray = ({
  appointmentId,
  userId,
  appointmentType,
  handleTabsExpand,
  index,
  openCloseTabs,
  scrollToExpandedTab,
  updateReviewStatus,
  delayedActionJob,
}) => {
  const classes = useStyles();
  const [iconXray, setIconXray] = useState(null);
  const [open, setOpen] = useState(false);
  const [xRayData, setXRayData] = useState(null);
  const [hasDelayedAction, setHasDelayedAction] = useState(false);
  const [success, setSuccess] = useState(false);
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const [popupActive, setPopupActive] = useState(false);
  const [filesXRay, setFilesXRay] = useState([]);
  const [imageKeyXRay, setImageKeyXRay] = useState(null);
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [xRayErrorMsg, setXRayErrorMsg] = useState('');
  const [uploadProgress, setUploadProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenImage = () => {
    setIsOpen(true);
  };

  const handleDataFetched = () => {
    setPopupActive(true);
    axios.get(`admin/v2/users/case-record-xray/${appointmentId}?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      setHasDelayedAction(res.data.has_delayed_action);
      if (res.data.success) {
        setXRayData(res.data.data);
        setIconXray(res.data.isAllProvided);
      } else {
        setIconXray(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setPopupActive(false);
    })
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const handleReviewStatus = (status) => {
    if(status) {
      let data = {
        review_status: status,
        appointment_id: appointmentId,
        procedure: appointmentType?.procedure,
        record_id: xRayData?.id,
        record_type: caseRecordTypes.UserCaseRecordXray,
      };
      let body = {
        action: adminActions.caseRecordsStatus,
        data,
      }
      updateReviewStatus(body, 'Status Updated', `${caseRecordTypes.UserCaseRecordXray}-${xRayData?.id}`);
    }
  };

  const handleDelete = async () => {
    if(xRayData?.id){
      setLoading(true);
      const response = await axios.delete(`admin/v2/users/${xRayData.id}/case-record-xray-delete`, {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('token'),
        },
      });
      if(response?.data?.success){
        handleDataFetched();
        setLoading(false);
      }
    }
  }

  const handleSave = async () => {
    if(imageKeyXRay){
      setSuccess(false);
      setXRayErrorMsg('');
      const form_data = new FormData();
      imageKeyXRay?.x_ray && form_data.append("x_ray", imageKeyXRay.x_ray);
      form_data.append("user_scan_info_id", appointmentId);
      form_data.append("procedure", appointmentType?.procedure);
      try {
        const response = await axios.post(`admin/v2/users/${userId}/case-record-xray`, form_data, {
            headers: {
              Authorization: 'Bearer ' + Cookies.get('token'),
            },
          }
        );
        if (response.status === 200) {
          handleDataFetched();
          setSuccess(true);
        }
      } catch (error) {
        setSuccess(false);
        if (imageKeyXRay?.xray) {
          setXRayErrorMsg('Upload failed');
        }
        console.log(error);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const sendImageXRay = useCallback(
    async (e) => {
      if(e.target.files.length == 0){
        return;
      }
      let filesXRay = e.target.files[0];
      setSuccess(false);
      setXRayData(prev => ({
        ...prev, x_ray_url: validImageTypes.indexOf(filesXRay.type) > 0 ?
          window.URL.createObjectURL(filesXRay) :
          '../assets/images/Fileicon.svg'
        })
      );
      const imageType = filesXRay.type;
      const form_data = new FormData();
      form_data.append('content_type', imageType);
      form_data.append('file_name', filesXRay.name);
      try {
        const response = await axios.post('/admin/v1/images/s3', form_data, {
          headers: {
            Authorization: 'Bearer ' + Cookies.get('token'),
          },
        });
        if (response.status === 200) {
          setImageKeyXRay({
            x_ray: response.data.key
          });
          const url2 = response.data.url;
          try {
            const awsResponse = await axiosAws.put(
              url2,
              filesXRay,
              {
                headers: {
                  'x-amz-acl': 'public-read-write',
                  'Content-Type': imageType,
                },
                onUploadProgress: (event) => uploadConfig(event, 'x_ray'),
              }
            );
            if (awsResponse.status === 200) {
              setCallHandleSave(1);
            }
          } catch (error) {
            console.log('AWS Error', error);
            setFilesXRay([]);
            setImageKeyXRay({
              x_ray: undefined
            });
            setXRayData(prev => ({...prev, x_ray_url: ''}));
            setXRayErrorMsg('Upload failed');
          }
        }
      } catch (error) {
        console.log(error);
        setFilesXRay([]);
        setImageKeyXRay({
          x_ray: undefined
        });
        setXRayData(prev => ({...prev, x_ray_url: ''}));
        setXRayErrorMsg('Upload failed');
      }
    },
    [filesXRay]
  );

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if(delayedActionJob.refetchedRecord === `${caseRecordTypes.UserCaseRecordXray}-${xRayData?.id}`) {
      handleDataFetched();
    }
  }, [delayedActionJob]);

  useEffect(() => {
    scrollToExpandedTab(index, 'xray');
  }, [openCloseTabs]);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='xray'
      headerTitle={<p><span className="procedure-header-title">X-Ray:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={iconXray}
    >
      {openCloseTabs[index]?.xray && (
        <Box className={`${classes.wrapper} xray-${index}`}>
          {popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <>
              <Box className={classes.flex}>
                <Box className={classes.singlePhotoBox}>
                  <SinglePhoto
                    disable={success}
                    value={xRayData?.x_ray_url}
                    handleImages={sendImageXRay}
                    id='X-Ray'
                    index={0}
                    handleIndexChange={handleOpenImage}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    imagesArray={[xRayData?.x_ray_url]}
                    identifier={`xray-${appointmentType?.procedure}`}
                  />
                  <Typography className={classes.imageTitle}>X-Ray</Typography>
                  {uploadProgress?.x_ray && (
                    <Box className={classes.progressContainer}>
                      <LinearProgress
                        variant='determinate'
                        value={uploadProgress?.x_ray}
                      />
                    </Box>
                  )}
                  <DeleteBtn disabled={loading || !xRayData?.x_ray_url} handleDelete={handleClickOpen} />
                </Box>
                <CreatedUpdated createdAt={xRayData?.x_ray_url ? xRayData?.created_at : ''} updatedAt={xRayData?.x_ray_url ? xRayData?.updated_at : ''} />
              </Box>
              <Review
                disabled={hasDelayedAction || delayedActionJob.disabledRecord === `${caseRecordTypes.UserCaseRecordXray}-${xRayData?.id}` || iconXray == 0 || imageKeyXRay?.review_status || xRayData?.review_status != null}
                reviewStatus={xRayData?.review_status}
                reviewer={xRayData?.reviewer}
                reviewedAt={xRayData?.reviewed_at}
                handleChange={() => handleReviewStatus('Reviewed')}
              />
              <Save uploadedBy={iconXray != 0 ? xRayData?.uploaded_by_user : ''} handleSave={handleSave} success={success} errorMessage={xRayErrorMsg} />
            </>
          }
        </Box>
      )}
      <AlertDeleteDialog open={open} setOpen={setOpen} handleAction={handleDelete} />
    </ProcedureHeader>
  );
};

export default AppointmentXray;
