import { Card, DataTable, DisplayText, TextStyle } from "@shopify/polaris";
import emptyIcon from "../../../img/emptyList.svg";

function ClinicHistory({ items }) {
  const isListEmpty = items.length === 0;
  const emtyState = isListEmpty ? (
    <div className="message-no-result">
      <img src={emptyIcon} className="empty-state-image"></img>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;
  return (
    <Card title="HISTORY">
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text"]}
        headings={[
          <TextStyle variation="strong">Actor</TextStyle>,
          <TextStyle variation="strong">Activity </TextStyle>,
          <TextStyle variation="strong">Date</TextStyle>,
          <TextStyle variation="strong">IP</TextStyle>,
          <TextStyle variation="strong">Action ID</TextStyle>,
        ]}
        rows={items}
      />
      {emtyState}
    </Card>
  );
}

export default ClinicHistory;
