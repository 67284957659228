import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  DataTable,
  Button,
  Image,
  DisplayText,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Select,
  Filters,
  Badge,
  Page,
} from "@shopify/polaris";
import emptyIcon from "../img/emptyList.svg";
import { ShopContext } from "../ShopContext";
import history from "../history";
import axios from "../axios";
import Cookie from "js-cookie";
import Axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import ThinLoading from '../components/Loading/thin-loading.component';
import { makeStyles } from '@material-ui/core';
import ShopLayout from "../components/ShopLayout";
import { Link } from 'react-router-dom';


const styles = makeStyles(() => ({
    link: {
      color: 'inherit',
      textDecoration: 'none'
    }
  }));

export default function ShopOrdersListing() {
  const classes = styles();
  const {
    states: {
      shopItems,
    },
    actions: {
      getShopProducts,
    }
  } = useContext(ShopContext);

  const [items, setItems] = useState([]);
  const [{month, year}, setDate] = useState({month: 1, year: 2018});
  const [selectedDates, setSelectedDates] = useState({
    start: new Date('Wed Feb 07 2018 00:00:00 GMT-0500 (EST)'),
    end: new Date('Mon Mar 12 2018 00:00:00 GMT-0500 (EST)'),
  });
  const [paymentType, setPaymentType] = useState('all');
  const [totalPages, setTotalPages] = useState(0);
  const [sortState, setSortState] = useState('id DESC');
  const perPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleSelectChange = useCallback(
    (value) => setPaymentType(value),
    [],
  );
  const handleMonthChange = useCallback(
    (month, year) => setDate({month, year}),
    [],
  );
  function handleSort(index) {
    switch (index) {
      case 0:
        setSortState(sortState === "id ASC" ? "id DESC" : "id ASC");
        break;
      case 1:
        setSortState(sortState === "user_id ASC" ? "user_id DESC" : "user_id ASC");
        break;
      default:
    }
  }
  function getData(ourRequest) {
    setLoading(true);
    axios
      .get(
            `admin/v1/shop/orders?sort_by=${sortState}&per_page=${perPage}&page=${currentPage}&payment_type=${paymentType}`,
        {
          cancelToken: ourRequest.token,
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        setTotalPages(Math.ceil(result.data.total / perPage));
        setItems(
          result.data.data.map((item) => [
            <Link
              to={`/admin/shop/orders/${item.id}`}
              className={classes.link}
            >
              <b>{item.id}</b>
            </Link>,
            <Link
              to={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              {item.user_id}
            </Link>,
            <Link
              to={`/admin/shop/orders/${item.id}`}
              className={classes.link}
            >
              {item.user.full_name}
            </Link>
            ,
            <Link
              to={`/admin/shop/orders/${item.id}`}
              className={classes.link}
            >
              {item.currency}
            </Link>,
            <Link
              to={`/admin/shop/orders/${item.id}`}
              className={classes.link}
            >
              {item.total_price/100}
            </Link>,
            <Link
              to={`/admin/shop/orders/${item.id}`}
              className={classes.link}
            >
              {item.shipping_price/100}
            </Link>,
            <Link
              to={`/admin/shop/orders/${item.id}`}
              className={classes.link}
            >
              {(item.total_price + item.shipping_price)/100}
            </Link>,

          ]),
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    getData(ourRequest);
    return () => {
      ourRequest.cancel();
    }
  }, [paymentType, currentPage, sortState]);

  const options = [
    {label: 'All', value: 'all'},
    {label: 'Stripe', value: 'stripe'},
    {label: 'Tabby', value: 'tabby'},
    {label: 'Cash', value: 'cash'},
  ];

  const emptyState = loading ? (
    <div className="spinnerContainer">
      <div className="vertical-center">
        <Spinner accessibilityLabel="Spinner example" size="large" />
      </div>
    </div>
  ) : (
    !items.length &&
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  );

  return (
    <ShopLayout>
    <Page
      fullWidth
      title="Shop Orders"
    >
        <Card>
          <Card>
            <Card.Section>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Select
                    label="Payment Type: "
                    labelInline
                    options={options}
                    onChange={handleSelectChange}
                    value={paymentType}
                />
                {/* <DatePicker
                    month={month}
                    year={year}
                    onChange={setSelectedDates}
                    onMonthChange={handleMonthChange}
                    selected={selectedDates}
                    multiMonth
                    allowRange
                /> */}
              </div>
            </Card.Section>
            {loading ? (
              <ThinLoading />
            ) : (
              <>
                <DataTable
                  hideScrollIndicator
                  columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text"]}
                  headings={["Order ID", "User Id", "User Name", "Currency", "Price", "Total Shipping", "Total Amount"]}
                  rows={items}
                  sortable={[true, true, false, false, false, false, false, false]}
                  initialSortColumnIndex={0}
                  defaultSortDirection="descending"
                  onSort={handleSort}
                  verticalAlign="center"
                />
                {emptyState}
                {totalPages > 1 && (
                  <div
                    style={{
                      paddingTop: "10px",
                      textAlign: "center",
                      paddingBottom: "10px",
                    }}
                  >
                    <Pagination
                      size="large"
                      page={Number(currentPage)}
                      count={totalPages}
                      boundaryCount={2}
                      style={{ margin: "auto", width: "fit-content" }}
                      showFirstButton
                      showLastButton
                      onChange={(e, p) => {
                        setCurrentPage(p);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </Card>
      </Card>
    </Page>
    </ShopLayout>
  );
}

