import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Button,
  TextStyle,
  FormLayout,
  TextField,
  Card,
  Toast,
  Layout,
  Icon,
  TextContainer,
  Banner,
  Modal,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import { useParams } from "react-router-dom";
import Accordion from "../../../components/Accordion/Accordion";
import { FormControl, FormGroup, InputLabel, MenuItem, Select } from "@material-ui/core";
import { ArrowUpMinor, ArrowDownMinor } from "@shopify/polaris-icons";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import moment from "moment";
import FirstTimeWearingAligner from "../FirstTimeWearingAligner/FirstTimeWearingAligner";
import { Box, Chip, TextField as TextField2 } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import StepImage from "../../../components/StepImage/StepImage";
import { adminActions, GeneralFormats } from "../../../util/constants";
import UndoAction from "../../../components/UndoAction";
import RefreshComponent from "../../../components/RefreshComponent";

function SmileJourney(props) {
  let { id } = useParams();
  const node = useRef(null);
  const progressImages = [
    { label: 'All', value: 'all' },
    { label: 'Front Closed With Aligner', value: 'front_closed_with_aligner' },
    { label: 'Front Closed Without Aligner', value: 'front_closed_without_aligner' },
    { label: 'Left Closed With Aligner', value: 'left_closed_with_aligner' },
    { label: 'Left Closed Without Aligner', value: 'left_closed_without_aligner' },
    { label: 'Right Closed With Aligner', value: 'right_closed_with_aligner' },
    { label: 'Right Closed Without Aligner', value: 'right_closed_without_aligner' },
    { label: 'Top With Aligner', value: 'top_with_aligner' },
    { label: 'Top Without Aligner', value: 'top_without_aligner' },
    { label: 'Bottom With Aligner', value: 'bottom_with_aligner' },
    { label: 'Bottom Without Aligner', value: 'bottom_without_aligner' },
    { label: 'Big Smile Without Aligner', value: 'big_smile_without_aligner' },
  ];
  const trackingOptions = [
    { label: 'On Track', value: 'On Track' },
    { label: 'Off Track', value: 'Off Track' },
    { label: 'Add More Time', value: 'Extended by' },
    { label: '1 step back', value: 'Taken 1 Step Back' },
    { label: 'Photos Not Clear', value: 'Requested Clearer Photos' },
    { label: 'Elastics Not Clear', value: 'Requested Clearer Elastics' },
    { label: 'Possible Refinement', value: 'Possible Refinement' },
    { label: 'Photos Not Correct', value: 'Photos Not Correct' },
  ];
  const replaceAlignerOptions = [
    { label: 'Both', value: 'Both' },
    { label: 'Previous only', value: 'Previous only' },
  ];
  const photosNotCorrectOptions = [
    { label: 'With aligners', value: true },
    { label: 'Without aligners', value: false },
  ];
  const [dispatchDetails, setDispatchDetails] = useState({
    options: [],
    option: '',
    id: null,
    arches: '',
  });
  const [delayedActionJob, setDelayedActionJob] = useState({
    id: null,
    title: 'Step tracked successfully',
    disabledRecord: null,
    refetchedRecord: null,
    isRefreshEnabled: false,
  });
  const alignerSteps = [
    { label: `Lower Arches`, value: 'lower' },
    { label: 'Upper Arches', value: 'upper' },
    { label: 'Both', value: 'both' },
  ];
  const guaranteeOptions = [
    { label: 'Keep guarantee', value: 'Keep guarantee' },
    { label: 'Lose guarantee', value: 'Lose guarantee' },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const days = useRef(null);
  const [isOpenSectionSmileJourney, setisOpenSectionSmileJourney] = useState(true);
  const [smileJourneyInfo, setSmileJourneyInfo] = useState({
    images: [],
    index: 0,
    isOpen: false,
  });
  let images = [];
  const [arraySmileJourney, setArraySmileJourney] = useState([]);
  const [active, setActive] = useState(false);
  const [data, setData] = useState({
    loading: false,
    trackingOption: '',
    comment: '',
    steps: [],
    stepsWithoutExtendedImages: [],
    procedures: [],
    currentStepInd: -1,
    isTreatementCompleted: false,
  });
  const [loading, setLoading] = useState(false);
  const [replacedImages, setReplacedImages] = useState([]);
  const [replaceAligners, setReplaceAligners] = useState('');
  const [alignersStatus, setAlignersStatus] = useState('');
  const [guarantee, setGuarantee] = useState('');
  const [toastMessage, setToastMessage] = useState("Account Disactivated");
  const [valueComment, setValueComment] = useState("");
  const [index, setIndex] = useState(-1);
  const dateFormat = GeneralFormats.dateFormat;

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;

  const checkDispatchDate = () => {
    axios.get(
      `/admin/v1/product-requests/${id}/dispatches`,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get('token')}`,
        },
      }
    ).then(response => {

      if (response.status === 200) {
        if (response.data.success === true) {
          const { dispatch } = response.data;
          if(dispatch) {
            let upcomingDispatchDate = moment(dispatch.target_dispatch_date).format(dateFormat);
            setDispatchDetails(prev => ({
              ...prev,
              id: dispatch.id,
              options: [
                { label: `Add to the upcoming batch ${upcomingDispatchDate}`, value: 'link' },
                { label: 'Create a separate delivery to be sent immediately', value: 'create_new' },
              ],
            }));
          } else {
            setDispatchDetails(prev => ({
              ...prev,
              options: [
                { label: 'Create a separate delivery to be sent immediately', value: 'create_new' },
              ],
            }));
          }
        }
      }
    }).catch(err => {
      console.log('checkDispatchDate error', err);
    });
  }

  const handleTrackingOption = (e) => {
      let { value } = e.target;
      setData(prev => ({
        ...prev,
        trackingOption: value
      }))
      if(value == 'Taken 1 Step Back') {
        checkDispatchDate();
      }
  }

  const handleSelectReplacedImages = (e) => {
    const {
      target: { value },
    } = e;
    setReplacedImages(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSelectReplacedAligners = (e) => {
    const {
      target: { value },
    } = e;
    setReplaceAligners(value);
  };

  const handleSelectAlignersStatus = (e) => {
    const {
      target: { value },
    } = e;
    setAlignersStatus(value);
  };

  const handleSelectDispatchOption = (e, name) => {
    const {
      target: { value },
    } = e;
    setDispatchDetails(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectGuarantee = (e) => {
    const {
      target: { value },
    } = e;
    setGuarantee(value);
  };

  function handleButtonSubmit() {
    let bodyObj = {
      step_id: data.stepsWithoutExtendedImages[index].id,
      action: data.trackingOption,
    };
    if(data.trackingOption == 'Extended by') {
      let extendedDays = days.current?.value;
      if(extendedDays) {
        bodyObj = {
          ...bodyObj,
          extended_days: extendedDays,
        };
      } else {
        return;
      }
    }

    setIndex(-1);
    setLoading(true);

    if(data.trackingOption == 'Requested Clearer Photos' && replacedImages.length) {
      bodyObj = {
        ...bodyObj,
        replaced_images: replacedImages,
      };
    }

    if(data.trackingOption == 'Taken 1 Step Back' && replaceAligners) {
      bodyObj = {
        ...bodyObj,
        replace_aligners: replaceAligners,
        dispatch_option: dispatchDetails.option,
        dispatch_id: dispatchDetails.id,
        dispatch_arches: dispatchDetails.arches,
      };
    }

    if(data.trackingOption == 'Photos Not Correct' && alignersStatus !== '') {
      bodyObj = {
        ...bodyObj,
        with_aligners: alignersStatus,
      };
    }

    if(data.trackingOption == 'Possible Refinement' && guarantee) {
      bodyObj = {
        ...bodyObj,
        guarantee,
      };
    }

    if(valueComment) {
      bodyObj = {
        ...bodyObj,
        comment: valueComment,
      };
    }

    let body = {
      action: adminActions.progressTracking,
      data: bodyObj,
    }

    axios.post(`admin/v2/users/delayed-action`, body, {
      headers: {
        Authorization: 'Bearer ' + Cookie.get('token'),
      },
    }).then((res) => {
      setDelayedActionJob(prev => ({
        ...prev,
        id: res.data.delayed_action_job_id,
      }));
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
      if(valueComment) {
        setValueComment('');
      }
    })
  };

  function handleOnClickImageSmileJourney(img) {
    if(!smileJourneyInfo.images.length && images.length) {
      setSmileJourneyInfo(prev => ({
        ...prev,
        images,
        index: images.indexOf(img),
        isOpen: true,
      }));
    } else {
      setSmileJourneyInfo(prev => ({
        ...prev,
        index: images.indexOf(img),
        isOpen: true,
      }));
    }
  }

  function toggleSmileJourney() {
    setisOpenSectionSmileJourney(!isOpenSectionSmileJourney);
  }

  function downloadPicturesSmileJourney() {
    axios
      .get("admin/v1/users/" + id + "/download-smile-journey", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        var win = window.open(
          "https://api.basma.com/storage/" + result.data.data,
          "_blank"
        );
        win.focus();
      })
      .catch(
        (err) => toggleActive(),
        setToastMessage("No images found to download")
      );
  }

  const handleFocus = useCallback(() => {
    if (node.current == null) {
      return;
    }
    node.current.input.select();
    document.execCommand("copy");
  }, []);

  const getData = () => {
    setData(prev => ({
      ...prev,
      loading: true,
    }));
    axios
      .get(`admin/v1/users/${id}/smile-journey`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        let latestSmilePlanId = result.data.data.latest_smile_plan_id;
        let stepsArray = result.data.data.steps;
        let proceduresArray = result.data.data.procedures;
        let stepsWithoutExtendedImagesArray = stepsArray.filter(step => step.extended_id == null);
        let latestStepsWithoutExtendedImagesArray = stepsWithoutExtendedImagesArray.filter(step => step.smile_plan_id == latestSmilePlanId);
        let ind = latestStepsWithoutExtendedImagesArray.findIndex(step => step.date == null || moment().isSameOrBefore(moment(new Date(step.date))));
        ind = stepsWithoutExtendedImagesArray.length == latestStepsWithoutExtendedImagesArray.length ? ind : ind + stepsWithoutExtendedImagesArray.length - latestStepsWithoutExtendedImagesArray.length;
        let currentStepIndex = ind != -1 ? stepsWithoutExtendedImagesArray.findLastIndex(e => e.smile_plan_id == latestSmilePlanId && e.id == stepsWithoutExtendedImagesArray[ind].id) : null;
        setData({
          trackingOption: '',
          steps: stepsArray,
          stepsWithoutExtendedImages: stepsWithoutExtendedImagesArray,
          procedures: proceduresArray,
          currentStepInd: currentStepIndex,
          isTreatementCompleted: result.data.data.treatment_completed,
        })
        result.data.data && result.data.data.starting_date && props.onGetFirstTimeWearingAligner(result.data.data.starting_date);
      })
      .catch((err) => {
        console.log(err)
      }).finally(f => {
        setData(prev => ({
          ...prev,
          loading: false,
        }));
        if(delayedActionJob.isRefreshEnabled) {
          setDelayedActionJob(prev => ({
            ...prev,
            isRefreshEnabled: false,
          }));
        }
      });
  };

  const Status = (status) => {
    if (status == 1 || status == 2) {
      return <CheckIcon style={{ color: status == 1 ? 'green' : 'gray', fontSize: '20px' }} />;
    }
    if (status == 3) {
      return <CloseIcon style={{ color: '#CC0909', fontSize: '20px' }} />;
    }
    if (status == 4 || status == 5) {
      return <WarningIcon style={{ color: status == 4 ? '#FCBD17' : '#CC0909', fontSize: '20px' }} />;
    } else {
      return null;
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(data.stepsWithoutExtendedImages.length && data.steps.length) {
      let proceduresLinkedToStep = [];
      setArraySmileJourney(
        data.stepsWithoutExtendedImages.map((item, i) => {
          let stepProcedures = [];
          let proc = data.procedures.find(procedure => {
              if(item.uaki_id && procedure.images_id) {
                return procedure.images_id == item.uaki_id
              }
              if(procedure.step_id == item.id && !proceduresLinkedToStep.includes(item.id)) {
                proceduresLinkedToStep.push(item.id); 
                return procedure.step_id == item.id;
              }
          });
          if(proc) {
            stepProcedures = proc.procedures;
          }
          let extendedImages = data.steps.filter(step => step.extended_id && step.extended_id == item.uaki_id);
          extendedImages.unshift(item);
          let actions = [];
          extendedImages.forEach(img => {
            actions.push(...img.actions_with_actors);
          });
          let length = extendedImages.length;
          let image = length ? extendedImages[length - 1] : null;
          const latestAction = actions.length ? actions[actions.length - 1] : null;
          const replacedImgs = latestAction && latestAction.action === 'Requested Clearer Photos' && latestAction.properties.length ? latestAction.properties[latestAction.properties.length - 1] : null;
          let step = item.position ? `${(item.step_nb)}.${item.position}` : `${(item.step_nb)}`;
          let title = `Step ${step} ${(item.is_refinement || item.is_mca) ? `(${item.smile_plan?.name})` : ''} ${item?.mca_disabled_step ? ' - REPLACED' : ''}`;
          let saveDisabled = !data.trackingOption || 
                             (data.trackingOption === 'Requested Clearer Photos' && !replacedImages.length) ||
                             (data.trackingOption === 'Taken 1 Step Back' && (!replaceAligners || (dispatchDetails.options.length > 0 ? (!dispatchDetails.option || !dispatchDetails.arches) : false))) ||
                             (data.trackingOption === 'Possible Refinement' && !guarantee) ||
                             (data.trackingOption === 'Photos Not Correct' && alignersStatus === '') ||
                             (data.trackingOption === 'Off Track' && !valueComment) ||
                             loading;
          return (
            <div key={i}>
              {stepProcedures.length ?
                <>
                  {
                    stepProcedures.map((stepProcedure, key) => {
                      return (
                        <div className="procedure-container" key={key}>
                          <TurnRightIcon style={{ fontSize: '20px' }} />
                          <p className="appt-title">Appt #{stepProcedure.appt}</p>
                          {Status(stepProcedure.status)}
                          <p>{stepProcedure.types}</p>
                          {stepProcedure.auto_created == 1 && <Chip style={{height: 'initial'}} label='Auto' />}
                        </div>
                      )
                    })
                  }
                </> :
                <></>
              }
              <FormGroup>
                <Accordion
                  mcaDisabledStep={item?.mca_disabled_step}
                  title={title}
                  isNearest={data.currentStepInd == i}
                  trackStatus={actions.map(img => {
                    if(img.extended_days) {
                      return `${img.action} ${img.extended_days} days`;
                    }
                    return img.action;
                  }).join(', ')}
                  status={(item.smile_plan_id < data.stepsWithoutExtendedImages[data.stepsWithoutExtendedImages.length - 1].smile_plan_id || i <= data.currentStepInd) ? ((i == data.currentStepInd && image.is_all_provided == 0) ? null : image.is_all_provided) : null}
                  isRefinement={item.is_refinement ? 'REF' : item.is_mca ? 'MCA' : null}
                  duration={item.duration}
                  totalExtendedBy={item.total_extended_by}
                  date={item.date ? moment(new Date(item.date)).format("DD-MM-YYYY") : null}
                  changeHeight={[replacedImages, data.trackingOption]}
                >
                  <Card.Section
                    title={`Step ${step}`}
                    actions={[
                      {
                        content: "Edit Date",
                        url: `/admin/users/${id}/dashboard/editsmilejourneydate/${item.id}`,
                        disabled: String(Cookie.get("userType")) === "50" && true,
                      },
                      {
                        content: "Edit Images",
                        url: `/admin/users/${id}/dashboard/editsmilejourney/${item.id}/images/${item.uaki_id}`,
                        disabled: String(Cookie.get("userType")) === "50" && true,
                      },
                    ]}
                  >
                    <FormLayout>
                      <TextStyle>{"Date to wear until " + moment(item.date).format('DD-MM-YYYY')}</TextStyle>
                      {item && item.latest_progress_answer && item.latest_progress_answer.platform === 'web' ? <TextStyle><strong>Answered on Web</strong></TextStyle> : null}
                      {item && item.latest_progress_answer && item.latest_progress_answer.wearingHours ? <TextStyle><strong>Average wear time:</strong> {item?.latest_progress_answer?.wearingHours}</TextStyle> : null}
                      {item && item.latest_progress_answer && item.latest_progress_answer.pressureAtBeginning ? <TextStyle><strong>Pressure level at the beginning:</strong> <strong><span style={item?.latest_progress_answer?.pressureAtBeginning < 3 ? {color: 'green'} : item?.latest_progress_answer?.pressureAtBeginning > 3 ? {color: 'red'} : {color:'orange'}}>{item?.latest_progress_answer?.pressureAtBeginning}/5</span></strong></TextStyle> : null}
                      {item && item.latest_progress_answer && item.latest_progress_answer.pressureAtEnd ? <TextStyle><strong>Pressure level at the end:</strong> <strong><span style={item?.latest_progress_answer?.pressureAtEnd < 3 ? {color: 'green'} : item?.latest_progress_answer?.pressureAtEnd > 3 ? {color: 'red'} : {color:'orange'}}>{item?.latest_progress_answer?.pressureAtEnd}/5</span></strong></TextStyle> : null}
                      {
                        extendedImages.map((extendedImage, key) => {
                          if(extendedImage.front_closed_with_aligner_url) {
                            images.push(extendedImage.front_closed_with_aligner_url);
                          }
                          if(extendedImage.left_closed_with_aligner_url) {
                            images.push(extendedImage.left_closed_with_aligner_url);
                          }
                          if(extendedImage.right_closed_with_aligner_url) {
                            images.push(extendedImage.right_closed_with_aligner_url);
                          }
                          if(extendedImage.top_with_aligner_url) {
                            images.push(extendedImage.top_with_aligner_url);
                          }
                          if(extendedImage.bottom_with_aligner_url) {
                            images.push(extendedImage.bottom_with_aligner_url);
                          }
                          if(extendedImage.front_closed_without_aligner_url) {
                            images.push(extendedImage.front_closed_without_aligner_url);
                          }
                          if(extendedImage.left_closed_without_aligner_url) {
                            images.push(extendedImage.left_closed_without_aligner_url);
                          }
                          if(extendedImage.right_closed_without_aligner_url) {
                            images.push(extendedImage.right_closed_without_aligner_url);
                          }
                          if(extendedImage.top_without_aligner_url) {
                            images.push(extendedImage.top_without_aligner_url);
                          }
                          if(extendedImage.bottom_without_aligner_url) {
                            images.push(extendedImage.bottom_without_aligner_url);
                          }
                          if(extendedImage.big_smile_with_aligner_url) {
                            images.push(extendedImage.big_smile_with_aligner_url);
                          }
                          if(extendedImage.big_smile_without_aligner_url) {
                            images.push(extendedImage.big_smile_without_aligner_url);
                          }
                          let divStyle = {
                            display: 'flex',
                            flexDirection: 'column',
                          }
                          if(key == 1) {
                            divStyle = {
                              ...divStyle,
                              borderTop: '1px solid rgb(122 130 134)',
                              paddingTop: '20px',
                              marginTop: '10px',
                            }
                          }
                          return (
                            (extendedImage.uaki_id && extendedImage.is_all_provided) ? (
                              <div key={key} >
                                {key == 1 ? <p style={{marginTop: '40px', fontSize: '18px', color: 'rgb(122 130 134)'}}>Extension Images</p> : null}
                                <div style={divStyle}>
                                  <TextStyle>{`Uploaded On: ${moment(extendedImage.uploaded_on ? extendedImage.uploaded_on : extendedImage.created_at).format('DD-MM-YYYY')}`}</TextStyle>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      flexWrap: 'wrap',
                                      columnGap: 20,
                                      marginTop: '10px',
                                    }}
                                  >
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.front_closed_with_aligner_url}
                                      title='Front Closed'
                                      subTitle='With Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.front_closed_with_aligner == 1}
                                    />
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.left_closed_with_aligner_url}
                                      title='Left Closed'
                                      subTitle='With Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.left_closed_with_aligner == 1}
                                    />
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.right_closed_with_aligner_url}
                                      title='Right Closed'
                                      subTitle='With Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.right_closed_with_aligner == 1}
                                    />
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.top_with_aligner_url}
                                      title='Top'
                                      subTitle='With Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.top_with_aligner == 1}
                                    />
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.bottom_with_aligner_url}
                                      title='Bottom'
                                      subTitle='With Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.bottom_with_aligner == 1}
                                    />
                                  </div>
      
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: 'wrap',
                                      flexDirection: "row",
                                      marginTop: 20,
                                      gap: 20,
                                    }}
                                  >
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.front_closed_without_aligner_url}
                                      title='Front Closed'
                                      subTitle='Without Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.front_closed_without_aligner == 1}
                                    />
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.left_closed_without_aligner_url}
                                      title='Left Closed'
                                      subTitle='Without Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.left_closed_without_aligner == 1}
                                    />
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.right_closed_without_aligner_url}
                                      title='Right Closed'
                                      subTitle='Without Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.right_closed_without_aligner == 1}
                                    />
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.top_without_aligner_url}
                                      title='Top'
                                      subTitle='Without Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.top_without_aligner == 1}
                                    />
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.bottom_without_aligner_url}
                                      title='Bottom'
                                      subTitle='Without Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.bottom_without_aligner == 1}
                                    />
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.big_smile_with_aligner_url}
                                      title='Big Smile'
                                      subTitle='With Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.big_smile_with_aligner == 1}
                                    />
                                    <StepImage
                                      handleOnClickImageSmileJourney={handleOnClickImageSmileJourney}
                                      extendedImage={extendedImage.big_smile_without_aligner_url}
                                      title='Big Smile'
                                      subTitle='Without Aligner'
                                      replacedImg={key == extendedImages.length - 1 && replacedImgs?.big_smile_without_aligner == 1}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : <></>
                          )
                        })
                      }
                      <div className="tracking-dropdown-container">
                        <FormControl>
                          <InputLabel>Tracking Options</InputLabel>
                          <Select
                            onChange={handleTrackingOption}
                            name={`tracking-opt-${i}`}
                            value={data.trackingOption}
                            className="tracking-dropdown-select"
                            disabled={!image.is_all_provided}
                          >
                            {
                              trackingOptions.map((option, key) => {
                                return (
                                  <MenuItem disabled={option.value == 'Taken 1 Step Back' && item.step_nb == 1} key={key} value={option.value}>{option.label}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                      {data.trackingOption == 'Photos Not Correct' &&
                        <div className="tracking-dropdown-container">
                          <FormControl>
                            <InputLabel>Patient uploaded all photos with/without aligners</InputLabel>
                            <Select
                              onChange={handleSelectAlignersStatus}
                              value={alignersStatus}
                              className="tracking-dropdown-select"
                            >
                              {
                                photosNotCorrectOptions.map((option, key) => {
                                  return (
                                    <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl>
                        </div>
                      }
                      {data.trackingOption == 'Taken 1 Step Back' ?
                        <>
                          <div className="tracking-dropdown-container">
                            <FormControl>
                              <InputLabel>Replacement aligners will be sent for</InputLabel>
                              <Select
                                onChange={handleSelectReplacedAligners}
                                value={replaceAligners}
                                className="tracking-dropdown-select"
                              >
                                {
                                  replaceAlignerOptions.map((option, key) => {
                                    return (
                                      <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                                    )
                                  })
                                }
                              </Select>
                            </FormControl>
                          </div>
                          {
                            dispatchDetails.options.length > 0 &&
                            <>
                              <div className="tracking-dropdown-container" style={{ marginTop: 13 }}>
                                <FormControl>
                                  <InputLabel>Dispatch Details</InputLabel>
                                  <Select
                                    onChange={(e) => handleSelectDispatchOption(e, 'option')}
                                    value={dispatchDetails.option}
                                    className="tracking-dropdown-select"
                                  >
                                    {
                                      dispatchDetails.options.map((option, key) => {
                                        return (
                                          <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                                        )
                                      })
                                    }
                                  </Select>
                                </FormControl>
                              </div>
                              <div className="tracking-dropdown-container" style={{ marginTop: 13 }}>
                                <FormControl>
                                  <InputLabel>Arches to send</InputLabel>
                                  <Select
                                    onChange={(e) => handleSelectDispatchOption(e, 'arches')}
                                    value={dispatchDetails.arches}
                                    className="tracking-dropdown-select"
                                  >
                                    {
                                      alignerSteps.map((option, key) => {
                                        return (
                                          <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                                        )
                                      })
                                    }
                                  </Select>
                                </FormControl>
                              </div>
                            </>
                          }
                        </> :
                        null
                      }
                      {data.trackingOption == 'Possible Refinement' ?
                        <div className="tracking-dropdown-container">
                          <FormControl>
                            <InputLabel>Guarantee</InputLabel>
                            <Select
                              onChange={handleSelectGuarantee}
                              value={guarantee}
                              className="tracking-dropdown-select"
                            >
                              {
                                guaranteeOptions.map((option, key) => {
                                  return (
                                    <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl>
                        </div> :
                        null
                      }
                      {data.trackingOption == 'Requested Clearer Photos' ?
                        <div className="tracking-dropdown-container">
                          <FormControl>
                            <InputLabel>Images to be replaced</InputLabel>
                            <Select
                              multiple
                              value={replacedImages}
                              onChange={handleSelectReplacedImages}
                              renderValue={() => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {replacedImages.map((value) => (
                                    <Chip key={value} label={progressImages.find(img => img.value == value).label} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {progressImages.map((img, key) => (
                                <MenuItem
                                  key={key}
                                  value={img.value}
                                >
                                  {img.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div> :
                        null
                      }
                      {data.trackingOption == 'Extended by' ?
                        <div className="tracking-textfield-container">
                          <FormControl>
                            <TextField2
                              type="number"
                              id="standard-basic"
                              label="Extend step by (days)"
                              variant="standard"
                              inputRef={days}
                              InputProps={{
                                inputProps: { min: 1 }
                              }}
                            />
                          </FormControl>
                        </div> :
                        null
                      }
                      <TextContainer>
                        <TextField
                          multiline={3}
                          label="Comment"
                          onFocus={handleFocus}
                          value={!saveDisabled ? valueComment : ''}
                          onChange={(value) => {
                            setValueComment(value);
                          }}
                        />
                        {(data.trackingOption == 'Off Track' && !valueComment) && <span style={{color: 'red', fontSize: 12}}>Comment is required</span>}
                      </TextContainer>
                      <div className="tracking-actions-container">
                        {
                          actions.reverse().map((action, key) => {
                            let extraDetails = null;
                            if(action.dispatch_details) {
                              extraDetails = action.dispatch_details;
                            } else if(action.guarantee !== null) {
                              extraDetails = action.guarantee ? 'Keep guarantee' : 'Lose guarantee';
                            } else if(action.replace_aligners) {
                              extraDetails = `Replacement aligners will be sent for ${action.replace_aligners}`;
                            } else if(action.with_aligners !== null) {
                              extraDetails = `Photos were uploaded ${action.with_aligners ? 'with aligners' : 'without aligners'}`;
                            } else if(action.properties.length) {
                              let property = action.properties[action.properties.length - 1];
                              let replacedImgs = Object.keys(property).filter(img => property[img] == 1).map(img => img.replaceAll('_', ' '));
                              let imgs = replacedImgs.length == 11 ? 'All' : <span style={{textTransform: 'capitalize'}}>{replacedImgs.join(', ')}</span>;
                              extraDetails = <p>Images to be replaced: {imgs}</p>;
                            }

                            return (
                              <div key={key}>
                                <TextStyle>{`${action.actor}, ${moment(action.created_at).format('DD-MM-YYYY')}`}</TextStyle>
                                <br />
                                <TextStyle>{`${action.action}${action.extended_days ? ` ${action.extended_days} days` : ''}${action.comment ? `, ${action.comment}` : ''}`}</TextStyle>
                                {
                                  extraDetails !== null &&
                                  <>
                                    <br />
                                    <TextStyle>{extraDetails}</TextStyle>
                                  </>
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className="tracking-btn">
                        <Button
                          id={`button-${i}`}
                          primary
                          disabled={saveDisabled}
                          loading={data.trackingOption && loading}
                          onClick={() => setIndex(i)}
                        >
                          Save
                        </Button>
                      </div>
                    </FormLayout>
                  </Card.Section>
                </Accordion>
              </FormGroup>
            </div>
          );
        })
      );
    }
  }, [data, replacedImages, replaceAligners, guarantee, loading, dispatchDetails, valueComment, alignersStatus]);

  return (
    <Layout.Section>
      <Card
        sectioned
        title="SMILE JOURNEY"
        actions={[
          {
            content: 'Refresh',
            onClick: getData,
            loading: data.loading,
            disabled: data.loading,
          },
          {
            content: isOpenSectionSmileJourney ? (
              <Icon source={ArrowUpMinor}></Icon>
            ) : (
              <Icon source={ArrowDownMinor}></Icon>
            ),
            onClick: toggleSmileJourney,
          },
        ]}
        primaryFooterAction={{
          content: "Download Pictures",
          onClick: downloadPicturesSmileJourney,
        }}
      >
          <FirstTimeWearingAligner
              dateValue={props.dateValue}
              planType={props.planType}
              pageTitle={props.pageTitle}
              status={props.status}
              clinicName={props.clinicName}
              clinicStatus={props.clinicStatus}
              userId={props.userId}
          />
        <br />
          
        {isOpenSectionSmileJourney ? arraySmileJourney : null}
        {data.isTreatementCompleted && (
          <div style={{ marginTop: "10px" }}>
            <Banner status="success">
              <b>Treatment Completed</b>
            </Banner>
          </div>
        )}
        {delayedActionJob.id ? <UndoAction job={delayedActionJob} setJob={setDelayedActionJob} /> : null}
        {delayedActionJob.isRefreshEnabled ? <RefreshComponent refresh={getData} /> : null}
      </Card>
      {toastMarkup}
      {smileJourneyInfo.isOpen && smileJourneyInfo.images.length > 0 &&
        <Lightbox
          images={smileJourneyInfo.images}
          startIndex={smileJourneyInfo.index}
          onClose={() => setSmileJourneyInfo(prev => ({...prev, isOpen: false}))}
        />
      }
      <Modal
        open={index >= 0}
        onClose={() => setIndex(-1)}
        title='Progress Tracking'
        primaryAction={{
          content: "No",
          onAction: () => setIndex(-1),
        }}
        secondaryActions={[
          {
            content: "Yes",
            onAction: handleButtonSubmit,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to mark this patient as <u><strong style={{fontSize: 'larger'}}>{trackingOptions.find(option => option.value == data.trackingOption)?.label}</strong></u>?</p>
            <span>The patient will recieve communication based on your response.</span>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Layout.Section>
  );
}

export default React.memo(SmileJourney);
