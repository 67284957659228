import React, { useEffect, useState } from "react";
import {
  AppProvider,
  Image,
  DisplayText,
} from "@shopify/polaris";
import { useLocation, useParams } from "react-router-dom";
import UserHeader from "../UserLayout/user-header";
import "./case-records.css";
import axios from "../../axios";
import Cookies from "js-cookie";
import AppointmentProcedures from "../AppointmentProcedures";
import { CircularProgress } from "@material-ui/core";
import ProcedureHeader from "../ProcedureHeader";
import emptyIcon from "../../img/emptyList.svg";
import UndoAction from "../UndoAction";
import RefreshComponent from "../RefreshComponent";
import CaseDiscussion from "../CaseDiscussion";

function CaseRecordFiles() {
  const { id } = useParams();
  const location = useLocation();
  let linkState = { planType: '', clinicStatus: '', clinicName: '', status: '', userId: id, pageTitle: '', appointmentNumber: '', type: '' };
  if (location.state !== undefined) {
    const { planType, clinicStatus, clinicName, status, userId, pageTitle, appointmentNumber, type } = location.state;
    linkState = { planType, clinicStatus, clinicName, status, userId, pageTitle, appointmentNumber, type }
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle, appointmentNumber, type } = linkState;
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [disable, setDisable] = useState(false);
  const [openCloseTabs, setOpenCloseTabs] = useState([{}]);
  const [openMainCloseTabs, setMainOpenCloseTabs] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [emptyState, setEmptyState] = useState(null);
  const [delayedActionJob, setDelayedActionJob] = useState({
    id: null,
    title: '',
    disabledRecord: null,
    refetchedRecord: null,
    isRefreshEnabled: false,
  });
  const [index, setIndex] = useState(null);
  const [appointmentNb, setAppointmentNb] = useState(appointmentNumber);

  const handleChangeNav = (appointment) => {
    setAppointmentNb(appointment);
  }

  const refresh = () => {
    setDelayedActionJob(prev => ({
      ...prev,
      refetchedRecord: prev.disabledRecord,
      isRefreshEnabled: false,
      disabledRecord: null,
    }));
  }

  const updateReviewStatus = (body, title, disabledRecord) => {
    setDelayedActionJob(prev => ({
      ...prev,
      disabledRecord,
    }));
    if(status) {
      axios.post(`admin/v2/users/delayed-action`, body, {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('token'),
        },
      }).then((res) => {
        setDelayedActionJob(prev => ({
          ...prev,
          title,
          id: res.data.delayed_action_job_id,
        }));
      }).catch((err) => {
        console.log(err);
      })
    }
  };

  const getAppointmenetDetails = () => {
    setLoading(true);
    axios.get(`admin/v2/users/${id}/case-records?fetch_data=${true}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(response => {
      let appointmenetDetailsArray = response.data.data;
      if(appointmenetDetailsArray.length){
        let reversedArray = appointmenetDetailsArray.reverse();
        setAppointmentDetails(reversedArray);
      } else {
        setEmptyState(
          <div className="message-no-result">
            <div className="message-no-result-img">
              <Image style={{ display: "inline-block", width: "50px" }} src={emptyIcon}></Image>
            </div>
            <DisplayText size="small">No results found</DisplayText>
            <br />
          </div>
        );
      }
    }).catch(err => {
      console.log('getAppointmenetDetails error', err);
    }).finally(f => {
      setLoading(false);
    })
  }

  const scrollToExpandedTab = (id, type) => {
    let dox = document.querySelector(`.${type}-${id}`);
    if (dox) {
      document.documentElement.style.scrollPaddingTop = "150px";
      dox.scrollIntoView({ behavior: "smooth" });
      document.documentElement.style.scrollPaddingTop = "0";
    }
  };

  const handleTabsExpand = (id, type) => {
    const arr = Array(openCloseTabs.length).fill({});
    if (openCloseTabs[id] === undefined || openCloseTabs[id][type] === undefined) {
      arr[id] = { [type]: true };
    }
    setOpenCloseTabs([...arr]);
  };

  const handleMainTabExpand = (id, type) => {
    const arr = Array(openMainCloseTabs.length).fill({});
    if (openMainCloseTabs[id] === undefined || openMainCloseTabs[id][type] === undefined) {
      arr[id] = { [type]: true };
    }
    setMainOpenCloseTabs([...arr]);
  };

  useEffect(() => {
    getAppointmenetDetails();
  }, [])

  useEffect(() => {
    if(appointmentDetails.length && appointmentNb){
      let ind = appointmentDetails.findIndex(app => app.appointment_number == appointmentNb);
      setIndex(ind);
    }
  }, [appointmentDetails, appointmentNb])

  useEffect(() => {
    if(index !== null){
      handleMainTabExpand(index, 'appointment');
    }
  }, [index])

  useEffect(() => {
    if(index !== null && openMainCloseTabs[index]?.appointment){
      scrollToExpandedTab(index, 'appointment');
      handleTabsExpand(index, type);
    }
  }, [index, openMainCloseTabs]);

  return (
    <AppProvider>
      <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType} />
      <div className="case-records-files-wrapper">
        <br />
        {loading ?
          <div style={{ textAlign: "center" }}>
            <CircularProgress color='primary' />
          </div>
          :
          <div className="case-record-files-wrapper">
            <div>
              {appointmentDetails.map((appointment, key) => {
                let appNb = appointmentDetails.length - key;
                let active = appointmentNb == `#${appNb}`;
                let first = key == 0;
                let last = key == appointmentDetails.length - 1;
                return (
                  <div key={key} className="case-record-step-wrapper">
                    <div className="case-record-step">
                      <div className={first ? "case-record-line-end" : "case-record-line"} />
                      <div className={active ? "case-record-circle-active" : "case-record-circle"} onClick={() => handleChangeNav(appointment.appointment_number)} />
                      <div className={last ? "case-record-line-end" : "case-record-line"} />
                    </div>
                    <div className="case-record-step-content-wrapper" onClick={() => handleChangeNav(appointment.appointment_number)}>
                      <p>{`Appointment ${appointment.appointment_number}`}</p>
                      <p className="case-record-appointment-completed">{`Completed: ${appointment.appointment_completed_at}`}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="case-record-files">
              {appointmentDetails.map((appointment, key) => (
                <div key={key} id={appointment.appointment_number} className="case-record-appointment">
                  <ProcedureHeader
                    handleTabsExpand={handleMainTabExpand}
                    type="appointment"
                    headerTitle={<p className="procedure-header-title">Appointment {appointment.appointment_number}{appointment.before_step !== '-' ? ` Before ${appointment.before_step}` : ''}: {appointment.type.join(', ')}</p>}
                    index={key}
                    showCloseButton
                  >
                    {openMainCloseTabs[key]?.appointment && (
                      <div className={`${appointment.has_required_files_data ? 'case-record-files-container' : ''} appointment-${key}`} >
                        {appointment.case_record_requirements_array.map((caseRecord, index) => {
                          return (
                            <AppointmentProcedures
                              key={index}
                              handleTabsExpand={handleTabsExpand}
                              procedure={caseRecord}
                              index={key}
                              userId={id}
                              openCloseTabs={openCloseTabs}
                              appointmentId={appointment.id}
                              appointmentNumber={`${appointment.appointment_number}${appointment.before_step !== '-' ? ` Before ${appointment.before_step}` : ''}`}
                              setDisable={setDisable}
                              disable={disable}
                              scrollToExpandedTab={scrollToExpandedTab}
                              delayedActionJob={delayedActionJob}
                              updateReviewStatus={updateReviewStatus}
                            />
                          )
                        })}
                      </div>
                    )}
                  </ProcedureHeader>
                </div>
              ))}
            </div>
          </div>
        }
        {emptyState}
        {delayedActionJob.id ? <UndoAction job={delayedActionJob} setJob={setDelayedActionJob} /> : null}
        {delayedActionJob.isRefreshEnabled ? <RefreshComponent refresh={refresh} /> : null}
      </div>
      <CaseDiscussion />
    </AppProvider>
  );
}
export default CaseRecordFiles;
