import React, { useState,useEffect } from "react";
  import {
    Card,Button,DataTable,ButtonGroup,
    DisplayText,Image, Layout,
    Modal,
    TextContainer,
  } from "@shopify/polaris";
  import axios from "../axios";
  import Cookie from "js-cookie";
  import "@zendeskgarden/react-pagination/dist/styles.css";
  import emptyIcon from "../img/emptyList.svg";
  import moment from "moment";
  import { useParams } from "react-router-dom";
  import history from "../history";
import { CircularProgress } from "@material-ui/core";
import UserCostsPopup from "./user-costs-popup.component";
  
  export default function PaymentDetails({ costs, totalClinicCost }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isListEmpty, setIsListEmpty] = useState(false);
    const [userCostsPopup, setUserCostsPopup] = useState(false);
    const [disabled, setIsDisabled] = useState(false);
    const [hybridPendingPayment, setHybridPendingPayment] = useState(false);
  
    const [items, setItems] = useState([]);
    const { id } = useParams();
    let titleStyle = {textAlign: 'center', fontWeight: 'bold'};
    
    const showUserCosts = () => {
        setUserCostsPopup(true);
    }
    
    const handleUserCosts = () => {
        setUserCostsPopup(false);
    }

    useEffect(() => {
      let isMounted = true;
      getData();
      return() => {
        isMounted = false;
      }
    }, []);

    function turnToHybrid(){
      setIsDisabled(true)
      axios.post(`admin/v1/user/${id}/payments/hybrid`,{}, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },  
      }).then((result) => {
        setIsDisabled(false)
        setHybridPendingPayment(false)
        window.location.reload();

      })
      .catch((err) => {
        setIsDisabled(false)
      })
    }

    function getData() {
      setIsLoading(true);
      setIsListEmpty(false);
      axios.get(`admin/v1/user/${id}/batches?payments=${true}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }).then((result) => {
        if (result.data.payments.length === 0) {
          setIsListEmpty(true);
        } else {
          let orders = result.data.orders;
          setItems(
            result.data.payments.map((item, key) => {
              let upcomingPayment = {fontWeight: 'thin', color: 'black', textAlign: 'center'};
              let actionGroup = {display: "flex", gap:"5px"} 
              return[
              <p style={upcomingPayment} key={key}>{key+1}</p>,
              <p style={upcomingPayment} key={key}>
                {item.withdraw_date}
              </p>,
              <p style={upcomingPayment} key={key}>{item.currency + " " + item.total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>,
                <p style={upcomingPayment} key={key}>
                  {item.paid
                    ? "Paid"
                    : moment(item?.withdraw_date).isAfter(
                      moment(new Date()).format("YYYY-MM-DD"),
                    ) &&
                    moment(item?.withdraw_date).isBefore(
                      moment(new Date())
                        .add(8, "days")
                        .format("YYYY-MM-DD"),
                    )
                      ? "Upcoming"
                      : moment(item?.withdraw_date).isBefore(
                        moment(new Date()).format("YYYY-MM-DD"),
                      ) &&
                      moment(item?.withdraw_date).isAfter(
                        moment(new Date())
                          .subtract(3, "months")
                          .format("YYYY-MM-DD"),
                      )
                        ? "Overdue"
                        : ""}
                </p>,
              <p style={upcomingPayment} key={key}>{item.settled_on && moment(item.settled_on).format('YYYY-MM-DD')}</p>,
              <p style={upcomingPayment} key={key}>{item.days_over_due}</p>,
              <p style={upcomingPayment} key={key}>{item.due_in}</p>,
              <p style={upcomingPayment} key={key}>{item.currency + ' ' + (orders[key] ? (orders[key].refunded_amount / 100).toFixed(2) : "0.00")}</p>,
              <p style={upcomingPayment} key={key}>{result.data.payments[key].promo_code}</p>,
              <p style={upcomingPayment} key={key}>{result.data.payments[0].discount_percentage}</p>,
              <p style={upcomingPayment} key={key}>{item.gateway}</p>,
              <p style={upcomingPayment} key={key}>{item.id}</p>,
              <p style={upcomingPayment} key={key}>{item.order_id}</p>,
              <div style={actionGroup}>                
              {item.failed_count ?<ButtonGroup>
              <Button
                onClick={(e) =>{
                  setHybridPendingPayment(item.id)
                }}
              >
                Hybrid
              </Button>
              </ButtonGroup>:""}
              {item.due_in !="Paid"? <ButtonGroup>
              <Button
                onClick={(e) =>{
                  history.push(`/admin/orders/manual/payments/${result.data.smilePlan.user.email}`);
                }}
              >
              Settle Payment
              </Button>
              </ButtonGroup>:""}
              </div>
            ]})
          );
        }
      }).catch(
        (err) => console.log(err)
      ).finally(f => {
        setIsLoading(false);
      });
    }

    const emtyState = isListEmpty ? (
      <div className="message-no-result"
      >
        <div className="message-no-result-img">
          <Image src={emptyIcon}></Image>{" "}
        </div>
        <DisplayText size="small">No results found</DisplayText>
        <br />
      </div>
    ) : null;

    return (
      <Layout.Section>
          <UserCostsPopup userCostsPopup={userCostsPopup} handleUserCosts={handleUserCosts} costs={costs} totalClinicCost={totalClinicCost} />
        <Card sectioned  title="PAYMENT SCHEDULE" actions={[{content: 'View User Costs', onAction: showUserCosts}]}>
          {isLoading ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress color='primary' />
          </div>
          :
          <>
          <DataTable
            columnContentTypes={["text","text","text","text","text","text","text","text","text","text",'text','text','text']}
            headings={[
              <p style={titleStyle}>Payments</p>,
              <><p style={titleStyle}>Due</p><p style={titleStyle}>Date</p></>,
              <><p style={titleStyle}>Payment</p><p style={titleStyle}>Amount</p></>,
              <><p style={titleStyle}>Payment</p><p style={titleStyle}>Status</p></>,
              <><p style={titleStyle}>Settled</p><p style={titleStyle}>On</p></>,
              <><p style={titleStyle}>Days</p><p style={titleStyle}>Over Due</p></>,
              <p style={titleStyle}>Due In</p>,
              <><p style={titleStyle}>Refund</p><p style={titleStyle}>Status</p></>,
              <p style={titleStyle}>Promocode</p>,
              <><p style={titleStyle}>Discount</p><p style={titleStyle}>Percentage</p></>,
              <p style={titleStyle}>Gateway</p>,
              <p style={titleStyle}>Payment ID</p>,
              <p style={titleStyle}>Order ID</p>,
              <p style={titleStyle}>Actions</p>,
            ]}
            rows={items}
            sortable={[false, false, false, false, false, false, false, false, false, false, false, false, false]}
            defaultSortDirection="descending"
            verticalAlign="center"
          />
          {emtyState}
          </>}
        </Card>
        <Modal
        open={hybridPendingPayment}
        onClose={()=>{setHybridPendingPayment(false)}}
        title={"Convert to Hybrid Plan"}
        primaryAction={{
          content: "No",
          onAction: () => {
            setHybridPendingPayment(false)
          }
        }}
        secondaryActions={[
          {
            content: "Yes",
            onAction: turnToHybrid,
            loading:disabled,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to convert to Hybrid Plan?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
        </Layout.Section>
        
    );
  
  }
  