import { useEffect } from 'react';
import ThinLoading from "../components/Loading/thin-loading.component";
import { Card, DataTable, TextStyle } from "@shopify/polaris";
import Pagination from "@material-ui/lab/Pagination";
import { FormControl, MenuItem, Select } from "@material-ui/core";

function ListOffersTable({
  getOffers,
  loading,
  offers,
  total,
  setPage,
  page,
  perPage,
  setPerPage,
}) {
  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    void getOffers(signal);
    return () => {
      abortController.abort();
    };
  }, [page, perPage]);

  if (loading) {
    return (
      <Card>
        <ThinLoading label="Offers Loading" />
      </Card>
    );
  }

  return (
    <Card>
      <Card.Section>
        {offers && offers.length > 0 ? (
          <>
            <DataTable
              columnContentTypes={[
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
              ]}
              headings={[
                <TextStyle variation="strong">Title</TextStyle>,
                <TextStyle variation="strong">Product</TextStyle>,
                <TextStyle variation="strong">Quantity</TextStyle>,
                <TextStyle variation="strong">Start Date</TextStyle>,
                <TextStyle variation="strong">End Date</TextStyle>,
                <TextStyle variation="strong">Countries</TextStyle>,
                <TextStyle variation="strong">Is First</TextStyle>,
              ]}
              rows={offers}
            />
            {total > 1 && (
              <div
                style={{
                  paddingTop: "10px",
                  textAlign: "center",
                  paddingBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  size="large"
                  page={Number(page)}
                  count={Math.ceil(total / perPage)}
                  boundaryCount={2}
                  showFirstButton
                  showLastButton
                  onChange={(e, p) => {
                    setPage(p);
                  }}
                />
                <FormControl>
                  <Select
                    value={perPage}
                    onChange={(e) => {
                      setPerPage(e.target.value);
                      setPage(1);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </>
        ) : (
          <div style={{ textAlign: "center", paddingTop: "20px" }}>
            <TextStyle variation="strong">No Offers Found</TextStyle>
          </div>
        )}
      </Card.Section>
    </Card>
  );
}

export default ListOffersTable;
