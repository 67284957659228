import React, { useEffect, useState } from "react";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import {
  FormLayout,
  TextField,
} from "@shopify/polaris";
import axios from "../axios";
import axiosAws from "axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import center_bite_img from '../assets/images/center_bite.jpeg';
import left_bite_img from '../assets/images/left_bite.jpg';
import right_bite_img from '../assets/images/right_bite.jpeg';
import upper_teeth_img from '../assets/images/upper_teeth.jpeg';
import lower_teeth_img from '../assets/images/lower_teeth.jpeg';
import front_facing_no_smile_img from '../assets/images/front_facing_no_smile.jpg';
import front_facing_big_smile_img from '../assets/images/front_facing_big_smile.jpg';
import profile_no_smile_img from '../assets/images/right_side_profile.jpg';
import Save from "./Save";
import ScreeningPhotoUpload from "./ScreeningPhotoUpload";
import Review from "./Review";
import AlertDeleteDialog from "./Popup/alert-delete-dialog.component";
import { adminActions, caseRecordTypes } from "../util/constants";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    photoBox: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    photoWidthSM: {
      width: "60%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    photoWidth: {
      width: "70%",
      marginBottom: 15,
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
  };
});

const AppointmentRedoPhotos = ({
  handleTabsExpand,
  appointmentId,
  openCloseTabs,
  index,
  appointmentType,
  userId,
  scrollToExpandedTab,
  updateReviewStatus,
  delayedActionJob,
}) => {
  const classes = useStyles();
  const [photos, setPhotos] = useState(null);
  const [hasDelayedAction, setHasDelayedAction] = useState(false);
  const [open, setOpen] = useState(false);
  const [deletePhoto, setDeletePhoto] = useState({id: null, type: ''});
  const [redoScanPhotosIcon, setRedoScanPhotosIcon] = useState(null);
  const [dataKeys, setDataKeys] = useState(null);
  const [success, setSuccess] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [photosArray, setPhotosArray] = useState([]);
  const [errors, setErrors] = useState({
    photoUpload: '',
  });
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [uploadProgress, setUploadProgress] = useState({});
  const [loading, setLoading] = useState({
    centerBite: false,
    leftBite: false,
    rightBite: false,
    upperTeeth: false,
    lowerTeeth: false,
    frontFacingNoSmile: false,
    frontFacingBigSmile: false,
    profileNoSmile: false,
    popupActive: false,
    reviewDisabled: false,
  });
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const handleChange = (prop, value) => {
    setDataKeys({
      [prop]: value,
    })
  }

  const handleClickOpen = (idData, typeData) => {
    setDeletePhoto({id: idData, type: typeData});
    setOpen(true);
  };

  const handleDataFetched = () => {
    setLoading(prev => ({...prev, popupActive: true}));
    axios.get(`admin/v2/users/case-record-post-redo/${appointmentId}/show?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      setHasDelayedAction(res.data.has_delayed_action);
      if (res.data.success) {
        if(dataKeys){
          setDataKeys(null);
        }
        setPhotos(res.data.data);
        setRedoScanPhotosIcon(res.data.isAllProvided);
      } else {
        setRedoScanPhotosIcon(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setLoading(prev => ({...prev, popupActive: false}));
    })
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  }

  const handleReviewStatus = (status) => {
    if(status) {
      let data = {
        review_status: status,
        appointment_id: appointmentId,
        procedure: appointmentType?.procedure,
        record_id: photos?.id,
        record_type: caseRecordTypes.UserCaseRecordPostRedoPhoto,
      };
      let body = {
        action: adminActions.caseRecordsStatus,
        data,
      }
      updateReviewStatus(body, 'Status Updated', `${caseRecordTypes.UserCaseRecordPostRedoPhoto}-${photos?.id}`);
    }
  };

  const handleSave = async () => {
    setSuccess(false);
    setErrors({
      photoUpload: '',
    });
    const form_data = new FormData();
    dataKeys?.center_bite && form_data.append("center_bite", dataKeys?.center_bite);
    dataKeys?.left_bite && form_data.append("left_bite", dataKeys?.left_bite);
    dataKeys?.right_bite && form_data.append("right_bite", dataKeys?.right_bite);
    dataKeys?.upper_teeth && form_data.append("upper_teeth", dataKeys?.upper_teeth);
    dataKeys?.lower_teeth && form_data.append("lower_teeth", dataKeys?.lower_teeth);
    dataKeys?.front_facing_no_smile && form_data.append("front_facing_no_smile", dataKeys?.front_facing_no_smile);
    dataKeys?.front_facing_big_smile && form_data.append("front_facing_big_smile", dataKeys?.front_facing_big_smile);
    dataKeys?.profile_no_smile && form_data.append("profile_no_smile", dataKeys?.profile_no_smile);
    dataKeys?.note && form_data.append("note", dataKeys?.note);
    form_data.append("user_scan_info_id", appointmentId);
    form_data.append("procedure", appointmentType?.procedure);
    try {
      const response = await axios.post(`/admin/v2/users/${userId}/case-record-post-redo`, form_data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        setSuccess(true);
        handleDataFetched();
      }
    } catch (error) {
      setSuccess(false);
    } finally {
      if(popupActive){
        setPopupActive(false);
      }
    }
  }

  const handleDelete = () => {
    const { id, type} = deletePhoto;
    setLoading(prev => ({...prev, [type]: true}));
    axios.delete(`/admin/v2/case-record-post-redo/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      handleDataFetched();
    }).catch((err) => {
      setErrors(prev => ({
        ...prev,
        photoUpload: 'Upload failed'
      }));
      console.log('handleDelete err', err);
    }).finally(() => setLoading(prev => ({...prev, [type]: false})));
  }
  
  const handleUploadError = (type, err) => {
    setErrors(prev => ({
      ...prev,
      photoUpload: 'Upload failed. Please try again'
    }));
    setPhotos((prev) => ({
      ...prev,
      [type]: undefined,
    }));
    setDataKeys((prev) => ({
      // ...prev,
      [type]: undefined,
    }));
    console.log(err);
  };

  const handleImages = (e, id) => {
    if(!e.target?.files?.length){
      return;
    }
    setErrors(prev => ({
      ...prev,
      photoUpload: ''
    }));
    setPopupActive(true);
    const file = e.target.files[0];
    let imgUrl =
      validImageTypes.indexOf(file.type) > 0
        ? window.URL.createObjectURL(file)
        : "../assets/images/Fileicon.svg";
    setPhotos((prev) => ({
      ...prev,
      [id]: imgUrl,
    }));
    let imageType = file.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", file.name);
    axios.post("/admin/v1/images/s3", form_data, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      setDataKeys((prev) => ({
        // ...prev,
        [id]: res.data.key,
      }));
      const url2 = res.data.url;
      axiosAws.put(url2, file, {
        headers: {
          "x-amz-acl": "public-read-write",
          "Content-Type": imageType,
        },
        onUploadProgress: (event) => uploadConfig(event, id),
      }).then((res) => {
        setCallHandleSave(1);
      }).catch((err) => {
        handleUploadError(id, err);
      });
    }).catch((err) => {
      handleUploadError(id, err);
    })
  };

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if(delayedActionJob.refetchedRecord === `${caseRecordTypes.UserCaseRecordPostRedoPhoto}-${photos?.id}`) {
      handleDataFetched();
    }
  }, [delayedActionJob]);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, 'photos');
  }, [openCloseTabs]);

  useEffect(() => {
    if(photos){
      let arr = [];
      if(photos.front_facing_big_smile && !arr.includes(photos.front_facing_big_smile)){
        arr.push({id: 'front_facing_big_smile', url: photos.front_facing_big_smile});
      }
      if(photos.front_facing_no_smile && !arr.includes(photos.front_facing_no_smile)){
        arr.push({id: 'front_facing_no_smile', url: photos.front_facing_no_smile});
      }
      if(photos.profile_no_smile && !arr.includes(photos.profile_no_smile)){
        arr.push({id: 'profile_no_smile', url: photos.profile_no_smile});
      }
      if(photos.center_bite && !arr.includes(photos.center_bite)){
        arr.push({id: 'center_bite', url: photos.center_bite});
      }
      if(photos.right_bite && !arr.includes(photos.right_bite)){
        arr.push({id: 'right_bite', url: photos.right_bite});
      }
      if(photos.left_bite && !arr.includes(photos.left_bite)){
        arr.push({id: 'left_bite', url: photos.left_bite});
      }
      if(photos.upper_teeth && !arr.includes(photos.upper_teeth)){
        arr.push({id: 'upper_teeth', url: photos.upper_teeth});
      }
      if(photos.lower_teeth && !arr.includes(photos.lower_teeth)){
        arr.push({id: 'lower_teeth', url: photos.lower_teeth});
      }

      setPhotosArray(arr);
    }
  }, [photos]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='photos'
      headerTitle={<p><span className="procedure-header-title">Photos:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={redoScanPhotosIcon}
    >
      {openCloseTabs[index]?.photos && (
        <Box className={`${classes.wrapper} photos-${index}`}>
          {loading.popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <FormLayout>
              <Box className={`${classes.photoBox} ${classes.photoWidthSM}`}>
                <ScreeningPhotoUpload
                  disabled={popupActive}
                  value={photos?.front_facing_big_smile ? photos.front_facing_big_smile : front_facing_big_smile_img}
                  handleImages={handleImages}
                  id='front_facing_big_smile'
                  title='Front Facing - Big Smile'
                  uploadProgress={uploadProgress?.front_facing_big_smile}
                  handleDelete={() => handleClickOpen(photos?.front_facing_big_smile_id, 'frontFacingBigSmile')}
                  deleteDisabled={loading.frontFacingBigSmile || !photos?.front_facing_big_smile}
                  imagesArray={photosArray}
                  identifier={`redo-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={popupActive}
                  value={photos?.front_facing_no_smile ? photos.front_facing_no_smile : front_facing_no_smile_img}
                  handleImages={handleImages}
                  id='front_facing_no_smile'
                  title='Front Facing - No Smile'
                  uploadProgress={uploadProgress?.front_facing_no_smile}
                  handleDelete={() => handleClickOpen(photos?.front_facing_no_smile_id, 'frontFacingNoSmile')}
                  deleteDisabled={loading.frontFacingNoSmile || !photos?.front_facing_no_smile}
                  imagesArray={photosArray}
                  identifier={`redo-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={popupActive}
                  value={photos?.profile_no_smile ? photos.profile_no_smile : profile_no_smile_img}
                  handleImages={handleImages}
                  id='profile_no_smile'
                  title='Profile - No Smile'
                  uploadProgress={uploadProgress?.profile_no_smile}
                  handleDelete={() => handleClickOpen(photos?.profile_no_smile_id, 'profileNoSmile')}
                  deleteDisabled={loading.profileNoSmile || !photos?.profile_no_smile}
                  imagesArray={photosArray}
                  identifier={`redo-${appointmentType?.procedure}`}
                />
              </Box>
              <Box className={`${classes.photoBox} ${classes.photoWidth}`}>
                <ScreeningPhotoUpload
                  disabled={popupActive}
                  value={photos?.center_bite ? photos.center_bite : center_bite_img}
                  handleImages={handleImages}
                  id='center_bite'
                  title='Center Bite'
                  uploadProgress={uploadProgress?.center_bite}
                  handleDelete={() => handleClickOpen(photos?.center_bite_id, 'centerBite')}
                  deleteDisabled={loading.centerBite || !photos?.center_bite}
                  imagesArray={photosArray}
                  identifier={`redo-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={popupActive}
                  value={photos?.right_bite ? photos.right_bite : right_bite_img}
                  handleImages={handleImages}
                  id='right_bite'
                  title='Right Bite'
                  uploadProgress={uploadProgress?.right_bite}
                  handleDelete={() => handleClickOpen(photos?.right_bite_id, 'rightBite')}
                  deleteDisabled={loading.rightBite || !photos?.right_bite}
                  imagesArray={photosArray}
                  identifier={`redo-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={popupActive}
                  value={photos?.left_bite ? photos.left_bite : left_bite_img}
                  handleImages={handleImages}
                  id='left_bite'
                  title='Left Bite'
                  uploadProgress={uploadProgress?.left_bite}
                  handleDelete={() => handleClickOpen(photos?.left_bite_id, 'leftBite')}
                  deleteDisabled={loading.leftBite || !photos?.left_bite}
                  imagesArray={photosArray}
                  identifier={`redo-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={popupActive}
                  value={photos?.upper_teeth ? photos.upper_teeth : upper_teeth_img}
                  handleImages={handleImages}
                  id='upper_teeth'
                  title='Upper teeth'
                  uploadProgress={uploadProgress?.upper_teeth}
                  handleDelete={() => handleClickOpen(photos?.upper_teeth_id, 'upperTeeth')}
                  deleteDisabled={loading.upperTeeth || !photos?.upper_teeth}
                  imagesArray={photosArray}
                  identifier={`redo-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={popupActive}
                  value={photos?.lower_teeth ? photos.lower_teeth : lower_teeth_img}
                  handleImages={handleImages}
                  id='lower_teeth'
                  title='Lower teeth'
                  uploadProgress={uploadProgress?.lower_teeth}
                  handleDelete={() => handleClickOpen(photos?.lower_teeth_id, 'lowerTeeth')}
                  deleteDisabled={loading.lowerTeeth || !photos?.lower_teeth}
                  imagesArray={photosArray}
                  identifier={`redo-${appointmentType?.procedure}`}
                />
              </Box>
              <TextField
                label='Note'
                value={dataKeys?.note || photos?.note}
                onChange={(e) => handleChange('note', e)}
                multiline={4}
              />
              <Review
                disabled={hasDelayedAction || delayedActionJob.disabledRecord === `${caseRecordTypes.UserCaseRecordPostRedoPhoto}-${photos?.id}` || redoScanPhotosIcon == 0 || loading.reviewDisabled || photos?.review_status != null}
                reviewStatus={photos?.review_status}
                reviewer={photos?.reviewer}
                reviewedAt={photos?.reviewed_at}
                handleChange={() => handleReviewStatus('Reviewed')}
              />
              <Save uploadedBy={redoScanPhotosIcon != 0 ? photos?.owner_name : ''} handleSave={handleSave} success={success} errorMessage={errors.photoUpload} />
            </FormLayout>
          }
        </Box>
      )}
      <AlertDeleteDialog open={open} setOpen={setOpen} handleAction={handleDelete} />
    </ProcedureHeader>
  );
};

export default AppointmentRedoPhotos;
