import React from 'react';
import {
    Box,
    Button,
    makeStyles,
    Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
        saveBtn: {
            display: 'block',
            width: '65px',
            marginTop: '0.5rem',
            marginLeft: 'auto',
            borderRadius: '4.7px',
            color: '#fff',
            backgroundColor: '#FF2C46',
            '&:hover': {
                backgroundColor: '#FF2C46',
            },
        },
        saveWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginTop: 10,
            ["@media only screen and (max-width: 500px) "]: {
                flex: "33%",
            },
        },
        footer: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        errors: {
            color: '#D72C0D',
            fontSize: '12px !important',
            marginTop: '1rem',
        },
        reviewBoldTxt: {
            fontWeight: 'bold',
        },
        size11: {
            fontSize: '11px',
        }
    };
});

const Save = ({ uploadedBy, handleSave = () => {}, success, errorMessage = '' }) => {
    const classes = useStyles();

    return (
        <Box className={classes.saveWrapper}>
            <Box className={classes.footer}>
                {uploadedBy && <Typography className={classes.size11}><span className={classes.reviewBoldTxt}>Uploaded By:</span> <span>{uploadedBy}</span></Typography>}
                <Button
                    className={classes.saveBtn}
                    onClick={handleSave}
                >
                    {success ? "Saved" : "Save"}
                </Button>
            </Box>
            <Typography className={classes.errors}>{errorMessage}</Typography>
        </Box>
    )
}

export default Save