import {
  TextField,
  Button as PolarisButton,
} from "@shopify/polaris";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import axiosAws from "axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Save from "./Save";
import ApprovalActions from "./ApprovalActions";
import DisapprovalModal from "./DisapprovalModal";
import ScreeningPhotoUpload from "./ScreeningPhotoUpload";
import AlertDeleteDialog from "./Popup/alert-delete-dialog.component";
import { adminActions, caseRecordTypes, IssueTypes, ReviewStatus } from "../util/constants";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: '2rem',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    },
    uploadBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    float: {
      float: 'right',
    },
    input: {
      padding: '0.5rem 1.2rem',
      border: '1px lightgray solid',
      borderRadius: '5px',
      margin: '15px 0',
    },
    mediaWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '2rem',
      marginBottom: '1rem',
    }
  };
});

const AppointmentPostIprPhotos = ({
  handleTabsExpand,
  appointmentId,
  appointmentNumber,
  openCloseTabs,
  index,
  appointmentType,
  userId,
  scrollToExpandedTab,
  updateReviewStatus,
  delayedActionJob,
}) => {
  const classes = useStyles();
  const [iprData, setIprData] = useState(null);
  const [hasDelayedAction, setHasDelayedAction] = useState(false);
  const [addFilesPreview, setAddFilesPreview] = useState([]);
  const [postIprPhotosIcon, setPostIprPhotosIcon] = useState(null);
  const [success, setSuccess] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [addFilesSave, setAddFilesSave] = useState([]);
  const [errorFilesUpload, setErrorFilesUpload] = useState('');
  const [addFilesKey, setAddFilesKey] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [disableReviewBtn, setDisableReviewBtn] = useState(false);
  const [imageKey, setImageKey] = useState(null);
  const [fileImages, setFileImages] = useState([]);
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [imageKeys, setImageKeys] = useState([]);
  const [reviewData, setReviewData] = useState({
    comment: '',
    commentError: '',
    loading: false,
    modalOpened: false,
    status: '',
  });
  const [photosArray, setPhotosArray] = useState([]);
  const [issues, setIssues] = useState([]);
  const [options, setOptions] = useState([]);
  const [groupTeeth, setGroupTeeth] = useState(Array(1).fill([
    { num: 18, attachment: false },
    { num: 17, attachment: false },
    { num: 16, attachment: false },
    { num: 15, attachment: false },
    { num: 14, attachment: false },
    { num: 13, attachment: false },
    { num: 12, attachment: false },
    { num: 11, attachment: false },
    { num: 21, attachment: false },
    { num: 22, attachment: false },
    { num: 23, attachment: false },
    { num: 24, attachment: false },
    { num: 25, attachment: false },
    { num: 26, attachment: false },
    { num: 27, attachment: false },
    { num: 28, attachment: false },
    { num: 48, attachment: false },
    { num: 47, attachment: false },
    { num: 46, attachment: false },
    { num: 45, attachment: false },
    { num: 44, attachment: false },
    { num: 43, attachment: false },
    { num: 42, attachment: false },
    { num: 41, attachment: false },
    { num: 31, attachment: false },
    { num: 32, attachment: false },
    { num: 33, attachment: false },
    { num: 34, attachment: false },
    { num: 35, attachment: false },
    { num: 36, attachment: false },
    { num: 37, attachment: false },
    { num: 38, attachment: false },
  ]));
  const [sheets, setSheets] = useState([
    {
      notes: '',
    },
  ]);

  const handleDataFetched = () => {
    setPopupActive(true);
    axios.get(`admin/v2/users/case-record-post-ipr/${appointmentId}/show?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        setHasDelayedAction(res.data.has_delayed_action);
        if(!res.data.data.review_status && disableReviewBtn){
          setDisableReviewBtn(false);
        }
        setIprData(res.data.data);
        setPostIprPhotosIcon(res.data.isAllProvided);
        setAddFilesPreview(res.data.storage_file);
      } else {
        setPostIprPhotosIcon(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setPopupActive(false);
    })
  };

  const handleChangeComment = (newValue) => {
    setReviewData(prev => ({
      ...prev,
      comment: newValue,
    }));
  }

  const handleChangeModal = () => {
    setReviewData(prev => ({
      ...prev,
      modalOpened: false,
    }));
    setIssues([]);
  }

  const handleChange = (prop, newValue) => {
    setImageKey({[prop]: newValue});
  }

  const handleChangeReview = (status) => {
    setDisableReviewBtn(status == ReviewStatus.approved);
    if(status == ReviewStatus.approved){
      handleReviewStatus(status);
    } else {
      setReviewData(prev => ({
        ...prev,
        modalOpened: true,
        status,
      }));
    }
  }

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const handleUploadError = (err, id) => {
    setPhotosArray(prev => {
      const newState = prev.map((obj) => {
        if (obj.id == id) {
          obj.loading = false;
        }
        return obj;
      });
      return newState;
    });
    setErrorFilesUpload('Upload failed. Please try again.');
    setImageKey(null);
    console.log(err);
  };

  const handleImages = (e, id) => {
    setErrorFilesUpload('');
    setPhotosArray(prev => {
      const newState = prev.map((obj) => {
        if (obj.id == id) {
          obj.loading = true;
        }
        return obj;
      });
      return newState;
    });
    const file = e.target.files[0];
    let imageType = file.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", file.name);
    axios.post("/admin/v1/images/s3", form_data, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      if(imageKey){
        setImageKey(null);
      }
      setAddFilesKey([
        {
          id,
          file_type: imageType,
          file: res.data.key,
        },
      ]);
      const url2 = res.data.url;
      axiosAws.put(url2, file, {
        headers: {
          "x-amz-acl": "public-read-write",
          "Content-Type": imageType,
        },
        onUploadProgress: (event) => uploadConfig(event, id),
      }).then((res) => {
        setCallHandleSave(1);
      }).catch((err) => {
        handleUploadError(err, id);
      });
    }).catch((err) => {
      handleUploadError(err, id);
    });
  };

  const handleAddFiles = () => {
    setAddFilesPreview((prev) => [...prev, {}]);
  };

  const handleClickOpen = (id) => {
    setPhotoId(id);
    setOpen(true);
  };

  const handleDelete = () => {
    setPhotosArray(prev => {
      const newState = prev.map((obj) => {
        if (obj.id == photoId) {
          obj.loading = true;
        }
        return obj;
      });
      return newState;
    });
    axios.delete(`admin/v2/case-record-post-ipr/delete/${photoId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      handleDataFetched();
      if (res.data.success) {
        if (addFilesSave.length > 0) {
          setAddFilesSave([]);
        }
      }
    }).catch((err) => {
      setErrorFilesUpload("Delete failed. Please try again.");
      console.log(err);
    }).finally(() => {
      setPhotosArray(prev => {
        const newState = prev.map((obj) => {
          if (obj.id == photoId) {
            obj.loading = false;
          }
          return obj;
        });
        return newState;
      });
    });
  }

  const handleSave = async () => {
    setErrorFilesUpload('');
    const form_data = new FormData();
    addFilesKey.length && form_data.append("array_ipr_files", JSON.stringify(addFilesKey));
    imageKey?.note && form_data.append("note", imageKey.note);
    imageKey?.post_ipr_step_number && form_data.append("post_ipr_step_number", imageKey.post_ipr_step_number);
    form_data.append("procedure", appointmentType.procedure);
    form_data.append("user_scan_info_id", appointmentId);
    try {
      const response = await axios.post(`/admin/v2/users/${userId}/case-record-post-ipr`, form_data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        if (addFilesKey.length > 0) {
          setAddFilesKey([]);
        }
        if (addFilesSave.length > 0) {
          setAddFilesSave([]);
        }
        handleDataFetched();
      }
    } catch (error) {
      setSuccess(false);
    }
  };

  const handleReviewStatus = async (status) => {
    if (status != ReviewStatus.approved && !issues.length && !reviewData.comment) {
      setReviewData(prev => ({
        ...prev,
        commentError: 'Please enter a comment',
      }));
    } else {
      let data = {
        review_status: status,
        appointment_id: appointmentId,
        procedure: appointmentType?.procedure,
        record_id: iprData?.id,
        record_type: caseRecordTypes.UserCaseRecordPostIprPhoto,
      };
      if(status != ReviewStatus.approved){
        let comment = `
          Appointment ${appointmentNumber} - ${appointmentType?.procedure}<br/>
          Status: Photos ${status}
        `;
        if (options.length && !issues.length) {
          return;
        }
        if(issues.length) {
          comment += '<br/>IPR issues: ';
          comment += issues.map(issue => issue.label).join(', ');
        }
        let teeth = groupTeeth[0].filter(tooth => tooth.attachment);
        if(teeth.length) {
          comment += `<br/>Affected teeth: ${teeth.map(tooth => tooth.num).join(', ')}`;
        }
        if(sheets[0].notes) {
          comment += `<br/>Affected teeth notes: ${sheets[0].notes}`;
        }
        if(reviewData.comment) {
          comment += `<br/>Notes: ${reviewData.comment}`;
        }
        data = {
          ...data,
          comment,
          images: imageKeys,
          issues: issues.map(issue => issue.id),
        };
      }
      let body = {
        action: adminActions.caseRecordsStatus,
        data,
      }
      await updateReviewStatus(body, 'Status Updated', `${caseRecordTypes.UserCaseRecordPostIprPhoto}-${iprData?.id}`);
      setReviewData(prev => ({
        ...prev,
        loading: false,
        modalOpened: false,
      }));
    }
  };

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if(delayedActionJob.refetchedRecord === `${caseRecordTypes.UserCaseRecordPostIprPhoto}-${iprData?.id}`) {
      handleDataFetched();
    }
  }, [delayedActionJob]);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, 'photos');
  }, [openCloseTabs]);

  useEffect(() => {
    setPhotosArray(addFilesPreview.map(val => ({
      url: val?.file_url,
      id: val?.id,
      loading: false,
    })));
  }, [addFilesPreview]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='photos'
      headerTitle={<p><span className="procedure-header-title">Photos:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={postIprPhotosIcon}
    >
      {openCloseTabs[index]?.photos && (
        <Box className={`${classes.wrapper} photos-${index}`}>
          {popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <>
              <div style={{ textAlign: "right", marginTop: "1rem" }}>
                <PolarisButton
                  onClick={handleAddFiles}
                >
                  Add another file
                </PolarisButton>
              </div>
              <div className={classes.mediaWrapper}>
                {Array.from(Array(addFilesPreview.length).keys()).map((idx) => (
                  <ScreeningPhotoUpload
                    key={idx}
                    disabled={photosArray[idx]?.loading}
                    value={addFilesPreview[idx]?.file_url ? addFilesPreview[idx]?.file_url : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'}
                    fileType={addFilesPreview[idx]?.file_type}
                    handleImages={handleImages}
                    id={addFilesPreview[idx]?.id ? addFilesPreview[idx]?.id : 0}
                    title=''
                    uploadProgress={uploadProgress[`image_${addFilesPreview[idx]?.id}`]}
                    handleDelete={() => handleClickOpen(addFilesPreview[idx]?.id)}
                    deleteDisabled={photosArray[idx]?.loading || !addFilesPreview[idx]?.id}
                    imagesArray={photosArray}
                    identifier={`ipr-${appointmentType?.procedure}`}
                  />
                ))}
              </div>
              <TextField
                label="Note"
                value={imageKey?.note ? imageKey.note : (iprData?.note ? iprData?.note : '')}
                onChange={(e) => handleChange('note', e)}
                multiline={4}
                autoComplete=""
              />
              <br />
              <ApprovalActions
                disabled={hasDelayedAction || delayedActionJob.disabledRecord === `${caseRecordTypes.UserCaseRecordPostIprPhoto}-${iprData?.id}` || postIprPhotosIcon == 0 || disableReviewBtn || iprData?.review_status != null}
                reviewStatus={iprData?.review_status}
                reviewer={iprData?.reviewer}
                reviewedAt={iprData?.reviewed_at}
                handleChangeReview={handleChangeReview}
              />
              <Save uploadedBy={postIprPhotosIcon != 0 ? iprData?.owner_name : ''} handleSave={handleSave} success={success} errorMessage={errorFilesUpload} />
            </>
          }
        </Box>
      )}
      {reviewData.modalOpened && (
        <DisapprovalModal
          activeModal={reviewData.modalOpened}
          handleChangeModal={handleChangeModal}
          title={reviewData.status === ReviewStatus.approvedWithIssues ? 'Approve With Issues' : 'Disapprove Post IPR Photos'}
          dropDownTitle='IPR Issues'
          handleSubmitDisapprove={() => handleReviewStatus(reviewData.status)}
          loading={reviewData.loading}
          commentValue={reviewData.comment}
          handleChangeComment={handleChangeComment}
          commentError={reviewData.commentError}
          setFilesImage={setFileImages}
          filesImage={fileImages}
          images={images}
          setImages={setImages}
          imagesKey={imageKeys}
          setImageKey={setImageKeys}
          issueType={IssueTypes.iprNotApproved}
          setIssues={setIssues}
          issues={issues}
          showIPRDiagram
          sheets={sheets}
          setSheets={setSheets}
          groupTeeth={groupTeeth}
          setGroupTeeth={setGroupTeeth}
          options={options}
          setOptions={setOptions}
        />
      )}
      <AlertDeleteDialog open={open} setOpen={setOpen} handleAction={handleDelete} />
    </ProcedureHeader>
  );
};

export default AppointmentPostIprPhotos;
  