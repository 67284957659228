import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Thumbnail,
  Caption,
  DropZone,
  Stack,
  FormLayout,
  Card,
  Icon,
  Collapsible, 
  Spinner,
  Checkbox,
  Select,
  Button,
} from "@shopify/polaris";
import {
  MobileCancelMajor,
  ChevronDownMinor,
  ChevronUpMinor,
} from '@shopify/polaris-icons';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../axios";
import Cookie from "js-cookie";
import { useParams } from "react-router-dom";
import Procedure from "./Procedure";
import IPRSheetModal from "../../../components/IPRSheet/IPRSheetModal";
import DownloadSelectIprSheet from "./download-select-ipr-sheet.component";
import PDFSheetPreview from "./pdf-sheet-preview.component";

export const IPR = (props) => {
  const {loading, proceduresIprSheet} = props;
  const [previousIPRSheet, setPreviousIPRSheet] = useState(false);
  const [openModal,setOpenModal] = useState(false);
  const { id } = useParams()
  const [clinics, setClinics] = useState([])
  const [render, setRender] = useState(false)
  const [checkedClinic, setCheckedClinic ] = useState([])
  const [index, setIndex] = useState(0)
  const [IPRFileKey, setIPRFileKey] = useState("");
  const [fileIPR, setFileIPR] = useState([]);
  const [popupActive, setPopupActive] = useState(false);
  const [ appointmentsOptions, setAppointmentsOptions] = useState([]);
  const [Iprs,setIprs] = useState([]);
  const [iprData,setIprData]=useState([])
  const [openInfo, setOpenInfo] = useState({
    senario1: false,
    senario2: false,
    senario3: false,
    senario4: false,
    senario5: false,
  })

  const [procedures, setProcedures] = useState({
    opg: 0,
    xRay: 0,
    cleaning: 0,
    dentalCheckup: 0,
    periodontalCheckup: 0,
    ipr: 0,
    attachments: 0,
    buttons: 0,
    attachmentsRemoval: 0,
    buttonsRemoval: 0,
    redoScan: 0,
    redoScanErrorDental : 0,
    redoScanCleaning : 0,
    redoScanWire : 0 ,
    redoIk: 0,
    removeFixedRetainers: 0,
    refinementScan: 0,
    refinementScanError:0,
    redoScanPhotos: 0,
    rebondAttachment: 0,
    mcaScan : 0,
    mcaScanError:0,
    replace_powerchain:0,
    retainer_scan: 0,
    post_treatment_photos: 0,
    extraction: 0,
    measure_spaces: 0,
    rebond_buttons: 0,
    mca_photos: 0,
    refinement_photos: 0,
    remove_excess_around_attachments: 0,
    in_clinic_whitening: 0,
  })
  const optionsRequiredRedo = [
    { label: "Redo Impression", value: "Redo Impression" },
    { label: "Redo Scan", value: "Redo Scan" },
  ];
  const treatmentStage=props.treatmentStage;
  const [ show, setShow] = useState(props.showIpr);
  const [loadingProcedures, setLoadingProcedures] = useState(false);
  const optionsProcedures = [
    { label: "Attachments", value: "attachments" },
    { label: "Attachments Removal", value: "attachmentsRemoval" },
    { label: "Buttons", value: "buttons" },
    { label: "Buttons Removal", value: "buttonsRemoval" },
    { label: "Cleaning", value: "cleaning" },
    { label: "Dental Checkup", value: "dental_checkup" },
    { label: "Extraction", value: "extraction" },
    { label: "IPR", value: "ipr" },
    { label: "MCA - Scan", value: "mcaScan" },
    { label: "MCA Scan - Scan Error", value: "mcaScanError" },
    { label: "Replace Powerchain", value: "replace_powerchain" },
    { label: "OPG", value: "opg" },
    { label: "Periodontal Checkup ", value: "periodontal_checkup" },
    { label: "Rebond Attachment", value: "rebondAttachment" },
    { label: "Redo Ik", value: "redoIk" },
    { label: "Redo Scan - Scan Error", value: "redoScan" },
    { label: "Redo Scan - Post Cleaning", value: "redoScanCleaning" },
    { label: "Redo Scan - Post Dental Work", value: "redoScanErrorDental" },
    { label: "Redo Scan - Post Wire Removal", value: "redoScanWire" },
    { label: "Redo Scan Photos", value: "redoScanPhotos" },
    { label: "Refinement Scan", value: "refinementScan" },
    { label: "Refinement Scan - Scan Error", value: "refinementScanError" },
    { label: "Remove Fixed Retainers", value: "removeFixedRetainers" },
    { label: "Retainer Scan", value: "retainer_scan"},
    { label: "X-ray", value: "xray" },
    { label: "Post Treatment Photos", value: "post_treatment_photos" },
    { label: "Measure Spaces", value: "measure_spaces" },
    { label: "Rebond Buttons", value: "rebond_buttons" },
    { label: "Remove Excess Around Attachments", value: "remove_excess_around_attachments" },
    { label: "In-Clinic Whitening", value: "in_clinic_whitening" },
    { label: "MCA Photos", value: "mca_photos" },
    { label: "Refinement Photos", value: "refinement_photos" },
  ];
  const optionsProceduresHashMap = 
    { 
    attachments : "Attachments",
    attachmentsRemoval : "Attachments Removal",
    buttons : "Buttons",
    buttonsRemoval : "Buttons Removal",
    cleaning : "Cleaning",
    dental_checkup : "Dental Checkup",
    extraction : "Extraction",
    ipr : "IPR",
    mcaScan : "MCA - Scan",
    mcaScanError : "MCA Scan - Scan Error",
    replace_powerchain : "Replace Powerchain",
    opg : "OPG",
    periodontal_checkup : "Periodontal Checkup",
    rebondAttachment : "Rebond Attachment",
    redoIk : "Redo Ik",
    redoScan : "Redo Scan - Scan Error",
    redoScanErrorDental : "Redo Scan - Post Dental Work",
    redoScanCleaning : "Redo Scan - Post Cleaning",
    redoScanWire : "Redo Scan - Post Wire Removal",
    redoScanPhotos : "Redo Scan Photos",
    refinementScan : "Refinement Scan",
    refinementScanError : "Refinement Scan - Scan Error",
    removeFixedRetainers : "Remove Fixed Retainers",
    retainer_scan : "Retainer Scan",
    post_treatment_photos: "Post Treatment Photos",
    measure_spaces: "Measure Spaces",
    rebond_buttons: "Rebond Buttons",
    remove_excess_around_attachments: "Remove Excess Around Attachments",
    in_clinic_whitening: "In-Clinic Whitening",
    mca_photos: "MCA Photos",
    refinement_photos: "Refinement Photos",
    xray : "X-ray"
  };
  useEffect(() => {
    if(props.dataId){
      setLoadingProcedures(true);
      axios.get(`admin/v1/getProcedure/${props.dataId}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((response) => {
        setAppointmentsOptions(response.data.appointment_options);
        props.handleChangeBeforeStepNumberArray(response.data.data)
        props.setFirstAppointmentNumber(response.data.first_appointment_number);
      }).finally(() => setLoadingProcedures(false))
    }
  },[render, props.dataId])

  const getAvailableBeforeSteps = () => {
    if(props.dataId){
      axios.get(`admin/v1/get-available-before-steps/${props.dataId}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((response) => {
        setAppointmentsOptions(response.data.appointment_options);
      })
    }
  }

  useEffect(() => {
    axios.get('admin/v1/clinics', {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    })
    .then((result) => {
      setClinics(result.data.data)
    })
  },[])

  const handleDeleteStep = (stepId, step, idx) => {
    if(step !== undefined && step !== null ){
     try {
       setLoadingProcedures(true);
      axios.delete(`admin/v1/users/${id}/clinic/${idx.assigned_clinic}/deleteProcedure/${stepId}`,{
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((response) => {
        getAvailableBeforeSteps();
        setRender(prev => !prev)
        const newArray = props.beforeStepValueArray
        props.handleChangeBeforeStepNumberArray(newArray)
        if (response?.data?.success) {
          toast.success(response?.data?.message, 3000);
        } else {
          toast.error(response?.data?.message, 3000)
        }
      }).finally(() => setLoadingProcedures(false))
     } catch (err) {
      console.log(err)
     }
    }
  }
  
  const checkPreviousIpr = () => {
    if (proceduresIprSheet && proceduresIprSheet.length > 0 && 'previous' in proceduresIprSheet[0]) {
      setPreviousIPRSheet(true);
    }
  }
  
  useEffect(() => {
    checkPreviousIpr();
  }, [proceduresIprSheet]);


  const handleSave = async (step, idx, autoRP) => {
    const data = {
      step: step,
      xray: procedures.xRay,
      opg: procedures.opg,
      cleaning: procedures.cleaning,
      dental_checkup: (value && value[idx]?.dental_checkup) ? value && value[idx]?.dental_checkup : procedures?.dental_checkup, //procedures.dental_checkup ? the actual comment (handle it on the backend to put 1) : 0,
      extraction: (value && value[idx]?.extraction) ? value && value[idx]?.extraction : procedures.extraction,
      removeFixedRetainers:
        value && value[idx]?.removeFixedRetainers
          ? value && value[idx]?.removeFixedRetainers
          : procedures.removeFixedRetainers,
      periodontal_checkup: procedures.periodontalCheckup,
      ipr: procedures.ipr,
      attachments: procedures.attachments,
      buttons: procedures.buttons,
      attachmentsRemoval: procedures.attachmentsRemoval,
      buttonsRemoval: procedures.buttonsRemoval,
      redoScan: procedures.redoScan,
      redoScanErrorDental : procedures.redoScanErrorDental,
      redoScanCleaning : procedures.redoScanCleaning,
      redoScanWire : procedures.redoScanWire ,
      redoIk: procedures.redoIk,
      rebondAttachment: procedures.rebondAttachment,
      redoScanPhotos: procedures.redoScanPhotos,
      refinementScan: procedures.refinementScan,
      refinementScanError : procedures.refinementScanError,
      mcaScan : procedures.mcaScan,
      mcaScanError:procedures.mcaScanError,
      retainer_scan: procedures.retainer_scan,
      post_treatment_photos: procedures.post_treatment_photos,
      measure_spaces: procedures.measure_spaces,
      rebond_buttons: procedures.rebond_buttons,
      remove_excess_around_attachments: procedures.remove_excess_around_attachments,
      in_clinic_whitening: procedures.in_clinic_whitening,
      mca_photos: procedures.mca_photos,
      refinement_photos: procedures.refinement_photos,
      replace_powerchain:procedures.replace_powerchain,
      auto_rp: autoRP,
    }
      try{
        await axios.post(`admin/v1/users/${id}/plan/${props.dataId}/assign-procedure/${checkedClinic[idx]?.assignedClinic}/procedure-step/${step}`,data ,{
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((response) => {
          getAvailableBeforeSteps();
          toast.success(response?.data?.message, 3000);
          setRender(prev => !prev)
          const newArray = props.beforeStepValueArray
          props.handleChangeBeforeStepNumberArray(newArray)
        })
      }catch(err){
        console.log(err)
      }
  }

  const handleUpdate = async (stepId, step, idx, idxID, autoRP) => {
    const dentalCheckupObj = {value: value && value[idxID]?.dental_checkup ? value[idxID]?.dental_checkup : '', bool: idx.dental_checkup}
    const extractionObj = {value: value && value[idxID]?.extraction ? value[idxID]?.extraction : '', bool: idx.extraction}
    const removedFixedRetainerObj = {value: value && value[idxID]?.removeFixedRetainers ? value[idxID]?.removeFixedRetainers : '', bool: idx.removeFixedRetainers}
    const data = {
      iprStep: step,
      iprClinic: checkedClinic[idxID]?.assignedClinic,
      userId:id,
      completed: idx.completed ? 1 : 0,
      xray: idx.xray,
      opg: idx.opg,
      cleaning: idx.cleaning,
      dental_checkup: dentalCheckupObj,
      extraction: extractionObj,
      periodontal_checkup: idx.periodontal_checkup,
      ipr: idx.ipr,
      attachments: idx.attachments,
      buttons: idx.buttons,
      attachmentsRemoval: idx.attachmentsRemoval,
      buttonsRemoval: idx.buttonsRemoval,
      redoScan: idx.redoScan,
      redoScanErrorDental : idx.redoScanErrorDental,
      redoScanCleaning : idx.redoScanCleaning,
      redoScanWire : idx.redoScanWire ,
      redoIk: idx.redoIk,
      removeFixedRetainers: removedFixedRetainerObj,
      redoScanPhotos: idx.redoScanPhotos,
      refinementScan: idx.refinementScan,
      rebondAttachment: idx.rebondAttachment,
      refinementScanError : idx.refinementScanError,
      mcaScan : idx.mcaScan,
      mcaScanError:idx.mcaScanError,
      retainer_scan: idx.retainer_scan,
      replace_powerchain:idx.replace_powerchain,
      post_treatment_photos: idx.post_treatment_photos,
      measure_spaces: idx.measure_spaces,
      rebond_buttons: idx.rebond_buttons,
      remove_excess_around_attachments: idx.remove_excess_around_attachments,
      in_clinic_whitening: idx.in_clinic_whitening,
      mca_photos: idx.mca_photos,
      refinement_photos: idx.refinement_photos,
      position: idx.position,
      auto_rp: autoRP,
    }
    setLoadingProcedures(true);
      await axios.post(`admin/v1/updateProcedure/${stepId}`,data ,{
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    })
    .then((response) => {
      getAvailableBeforeSteps();
      toast.success(response?.data?.message, 3000);
      setRender(prev => !prev)
      const newArray = props.beforeStepValueArray
      props.handleChangeBeforeStepNumberArray(newArray)
    }).finally(()=> setLoadingProcedures(false))
  }

  const handleOpen = () => {
    setOpenModal(true)
  } 

  const handleClose = () => {
    setOpenModal(false)
  }
  const handleCounter = () => {
    props.handleChangeBeforeStepNumberArray((prev) => [...prev, ""]); // Add a new value placeholder for the newly added TextField
    setCheckedClinic(prev => [...prev, {ind: prev.length - 1, assignedClinic: 0}]);
    setProcedures({
      opg: 0,
      xRay: 0,
      cleaning: 0,
      dentalCheckup: 0,
      ipr: 0,
      attachments: 0,
      buttons: 0,
      periodontalCheckup: 0,
      attachmentsRemoval: 0,
      buttonsRemoval: 0,
      redoScan: 0,
      redoScanErrorDental : 0,
      redoScanCleaning : 0,
      redoScanWire : 0 ,
      redoIk: 0,
      removeFixedRetainers: 0,
      redoScanPhotos: 0,
      refinementScan: 0,
      refinementScanError: 0,
      mcaScan : 0,
      mcaScanError: 0,
      retainer_scan: 0,
      replace_powerchain: 0,
      rebondAttachment: 0,
      post_treatment_photos: 0,
      extraction: 0,
      measure_spaces: 0,
      rebond_buttons: 0,
      remove_excess_around_attachments: 0,
      in_clinic_whitening: 0,
      mca_photos: 0,
      refinement_photos: 0,
    })
  };

  const handleRemoveStep = (data) => {
    props.handleChangeBeforeStepNumberArray(props.beforeStepValueArray.filter( function(value, key) { 
      return value.id !== data.id
    }));
  }

  const handleChangeIPR = (newChecked) => {
    props.handeChangeCheckedIPR(newChecked);
  };

  let imageType = "";
  const handleDropZoneDropIPRFile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFileIPR((fileIPR) => [...fileIPR, ...acceptedFiles]),
    []
  );
  const fileUploadIPRFile = !fileIPR.length && <DropZone.FileUpload />;
  const uploadedIPRFile = fileIPR.length > 0 && (
    <Stack vertical>
      {fileIPR.map((file, index) => (
        <Stack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={window.URL.createObjectURL(file)}
          />
          <div>
            {file.name} <Caption>{file.size} bytes</Caption>
          </div>
        </Stack>
      ))}
    </Stack>
  );
  //
  const sendIPRFile = useCallback(
    (fileIPR) => {
      setPopupActive(true);//
      imageType = fileIPR[fileIPR.length - 1].type;//
      let headersVar = null;//
      let url2 = "";//
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";//
      const form_data = new FormData();//
      form_data.append("content_type", imageType);//
      form_data.append("file_name", fileIPR[fileIPR.length - 1].name);//
      props.handeChangeIPRFileName(fileIPR[fileIPR.length - 1].name);//
      axios//
        .post("/admin/v1/images/s3", form_data, {//
          headers: {//
            Authorization: "Bearer " + Cookie.get("token"),//
          },//
        })//
        .then((res) => {//
          setIPRFileKey(res.data.key);//
          props.handeChangeIPRkeyFile(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, fileIPR[fileIPR.length - 1], {
              headers: {  
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [fileIPR]
  );
  const fetchIprdataBefore = async (arr) => {
    let procedure_id = [];
    Object.keys(arr).forEach((key) => {
      procedure_id.push(arr[key].id);
    });
   
    const requestBody = {
      procedures_ids: procedure_id,
    };
    try {
      setPopupActive(true);
      const response = await axios.get(`/admin/v1/get_ipr_data`, {
        params: requestBody,
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      });
      if (response?.data?.success==true) {
        setIprData(response?.data?.data)
      }
    } catch (err) {
      console.log("data err", err);
    } finally {
      setPopupActive(false);
    }
  };
  const handleChangeBeforeStepValue = useCallback(
    (newValue) => props.handleChangeBeforeStepNumber(newValue),
    []
  );

  useEffect(() => {
    if(props.beforeStepValueArray?.length){
      let arr = [];
      props.beforeStepValueArray.forEach((element, key) => {
        arr.push({ind: key, assignedClinic: element?.assigned_clinic});
      });
      setCheckedClinic(arr);
    }
  }, [props.beforeStepValueArray]);

  const handleCheckClinic = (index, ind) => {
    setIndex(ind);
    setCheckedClinic(prev => {
      const newCheckedArray = [...prev];
      newCheckedArray[ind].assignedClinic = index?.assigned_clinic;
      return newCheckedArray;
    });
    handleOpen();
  }
  const [handleLearnMore, setHandleLearnMore] = useState(false)
  const [value, setValue] = useState(null);
  return (
    <div style={{width: '100%'}}>
      <ToastContainer />
      <Card
        sectioned
        actions={
          [
            {
              content: "Learn more",
              onClick: () => setHandleLearnMore(true)
            },
            {
              content: !props.beforeStepValueArray.length ? 'Generate IPR Sheet' : 'Edit IPR Sheet',
              onClick: () => {
                let arr = props.beforeStepValueArray.filter((arr) => (arr.ipr == 1 || arr.attachments == 1 || arr.buttons == 1 || arr.buttonsRemoval == 1));
                setIprs([...arr])
                fetchIprdataBefore([...arr])
              },
              disabled: !props.beforeStepValueArray.length,
            }
          ]
        }
      >
        <FormLayout>
          {previousIPRSheet &&
              <Checkbox
                  label="Upload IPR Sheet"
                  checked={props.isIprChecked}
                  onChange={handleChangeIPR}
              />
          }
          {props.iPRFileName != "" &&
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <div style={{border: "1.5px solid #C9CCCF", borderRadius: "3px", background: "#FAFBFB", padding: "0 2px", margin: " 0 2px 2px 0"}}>
                  <span>
                    {props.iPRFileName}
                  </span>
                </div>
            </div>
          }
          {previousIPRSheet && (
            <DropZone
              onDrop={handleDropZoneDropIPRFile}
              onDropAccepted={sendIPRFile}
            >
              {uploadedIPRFile}
              {fileUploadIPRFile}
            </DropZone>
          )}
          <Modal open={handleLearnMore} loading={false}>
            <div style={{padding: 50, position:"relative"}}>
              <h2 style={{fontWeight:"bold", textAlign:"center", fontSize:"24px", margin:"10px 0", padding:10}}>How it works</h2>
              <Card>
             <Card.Section>
               <p style={{cursor:"pointer"}} onClick={() => setOpenInfo({...openInfo, senario1: !openInfo.senario1})}><strong>
                  <div style={{display:"flex", alignItems:"center", alignContent:"center", justifyContent:"flex-start", gap:"10px"}}>
                    <span><Icon source={openInfo.senario1 ? ChevronUpMinor : ChevronDownMinor} color="base" /></span> 
                    <span>New patient with TP but did not purchase AK</span>
                  </div>
                </strong></p>
                <Collapsible open={openInfo.senario1}id="basic-collapsible"
                    transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                    expandOnPrint>
                      <ul>
                        <li>Condition: Must have a TP</li>
                        <li>Assigned plan: Treatment plan 1</li>
                        <li>Assign step: Step 0</li>
                        <li>Shows on CP: Immediately</li>
                        <li>Schedule date: No date</li>
                      </ul>
                </Collapsible>
             </Card.Section>
             <Card.Section>
               <p style={{cursor:"pointer"}} onClick={() => setOpenInfo({...openInfo, senario2: !openInfo.senario2})}><strong>
               <div style={{display:"flex", alignItems:"center", alignContent:"center", justifyContent:"flex-start", gap:"10px"}}>
                    <span><Icon source={openInfo.senario2 ? ChevronUpMinor : ChevronDownMinor} color="base" /></span> 
                    <span>Patient with TP purchased AK</span>
                  </div>
                </strong></p>
                <Collapsible open={openInfo.senario2}id="basic-collapsible"
                    transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                    expandOnPrint>
                      <ul>
                        <li>Condition: Must have a treatment start date</li>
                        <li>Assigned plan: Assign on Treatment plan 1</li>
                        <li>Assigned step: Assign on corresponding step, Step 1, 2…99</li>
                        <li>Shows on CP: 2 weeks before the assigned step start date</li>
                        <li>Schedule date: The date of the assigned step</li>
                      </ul>
                </Collapsible>
             </Card.Section>
             <Card.Section>
               <p style={{cursor:"pointer"}} onClick={() => setOpenInfo({...openInfo, senario3: !openInfo.senario3})}><strong>
               <div style={{display:"flex", alignItems:"center", alignContent:"center", justifyContent:"flex-start", gap:"10px"}}>
                    <span><Icon source={openInfo.senario3 ? ChevronUpMinor : ChevronDownMinor} color="base" /></span> 
                    <span>Patient completed treatment</span>
                  </div>
                </strong></p>
                <Collapsible open={openInfo.senario3}id="basic-collapsible"
                    transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                    expandOnPrint>
                      <ul>
                        <li>Condition: treatment must be ‘Completed’ on CMS</li>
                        <li>Assigned plan: Assign on Treatment plan 1</li>
                        <li>Assigned step: Step 100, 101…199</li>
                        <li>Shows on CP: Immediately</li>
                        <li>Schedule date: Immediately</li>
                      </ul>
                </Collapsible>
             </Card.Section>
             <Card.Section>
               <p style={{cursor:"pointer"}} onClick={() => setOpenInfo({...openInfo, senario4: !openInfo.senario4})}><strong>
               <div style={{display:"flex", alignItems:"center", alignContent:"center", justifyContent:"flex-start", gap:"10px"}}>
                    <span><Icon source={openInfo.senario4 ? ChevronUpMinor : ChevronDownMinor} color="base" /></span> 
                    <span>Patient with Refinement plan</span>
                  </div>
                </strong></p>
                <Collapsible open={openInfo.senario4}id="basic-collapsible"
                    transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                    expandOnPrint>
                      <ul>
                        <li>Condition: Must have a treatment start date for the refinement plan</li>
                        <li>Assigned plan: Assign on Refinement plan</li>
                        <li>Assigned step: Assign on corresponding step, Step 1, 2…99</li>
                        <li>Shows on CP: 2 weeks before the assigned step start date</li>
                        <li>Schedule date: The date of the assigned step</li>
                      </ul>
                </Collapsible>
             </Card.Section>
             <Card.Section>
               <p style={{cursor:"pointer"}} onClick={() => setOpenInfo({...openInfo, senario5: !openInfo.senario5})}><strong>
               <div style={{display:"flex", alignItems:"center", alignContent:"center", justifyContent:"flex-start", gap:"10px"}}>
                    <span><Icon source={openInfo.senario5 ? ChevronUpMinor : ChevronDownMinor} color="base" /></span> 
                    <span>Patient completed refinement</span>
                  </div>
                </strong></p>
                <Collapsible open={openInfo.senario5}id="basic-collapsible"
                    transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                    expandOnPrint>
                      <ul>
                        <li>Condition: Refinement must be ‘Completed’ on CMS</li>
                        <li>Assigned plan: Assign on Refinement plan</li>
                        <li>Assigned step: Step 200, 201…299</li>
                        <li>Shows on CP: Immediately</li>
                        <li>Schedule date: Immediately</li>
                      </ul>
                </Collapsible>
             </Card.Section>
          </Card>
            <button style={{position:"absolute", top:10, right:10,height:"35px", width:"35px",padding: 10, backgroundColor:"transparent", border:"none", color:"gray", cursor:"pointer", fontWeight:"bold" }} onClick={() => setHandleLearnMore(false)}><Icon
                    source={MobileCancelMajor}
                  /></button>
            </div></Modal>
            <IPRSheetModal 
              Iprs={Iprs}
              fullName={props.fullName}
              setIprs={setIprs} 
              treatmentStage={treatmentStage} 
              userId={id} 
              iprData={iprData}
              setIPRFileKey={setIPRFileKey} 
              handeChangeIPRkeyFile={props.handeChangeIPRkeyFile}
            />
          <div className="tp-requirements-checkboxes-container">
            <Checkbox
              label="Requires Redo"
              checked={props.checkedRequireRedo}
              onChange={props.handleChangeRequireRedo}
            />
            <Checkbox
              label="Requires OPG"
              checked={props.checkedRequireOpg}
              onChange={props.handleChangeRequireOpg}
            />
          </div>
          {props?.checkedRequireRedo ? (
            <div className="tp-checkboxes-select">
              <Select
                options={optionsRequiredRedo}
                onChange={props.handleSelectChangeRequiredRedoSelection}
                value={props.selectedRequiredRedo}
                placeholder="Please Select"
              />
            </div>
          ) : (
            null
          )}
          {loadingProcedures ? <div style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              backgroundColor: "white",
              padding: "15px",
              paddingBottom: "50px",
              marginTop: "15px",
              marginBottom: "15px"
            }}>
              <Spinner accessibilityLabel="Loading Clinics Pricing" size="large"/>
            </div> :
            <Procedure
              createMode={props.createMode}
              procedures={procedures}
              handleProceduresUpdate={props.handleProceduresUpdate}
              setProcedures={setProcedures} optionsProceduresHashMap={optionsProceduresHashMap}
              appointmentsOptions={appointmentsOptions}
              beforeStepValueArray={props.beforeStepValueArray} optionsProcedures={optionsProcedures}
              handleChangeBeforeStepNumberArray={props.handleChangeBeforeStepNumberArray}
              handleCheckClinic={handleCheckClinic} handleUpdate={handleUpdate}
              handleSave={handleSave} handleRemoveStep={handleRemoveStep}
              handleDeleteStep={handleDeleteStep}
              firstAppointmentNumber={props.firstAppointmentNumber}
              value={value} setValue={setValue}
              disabled={props.disabled}
            />
          }
           
          <ClinicModal setOpenModal={setOpenModal} clinics={clinics} checkedClinic={checkedClinic} handleClose={handleClose} setCheckedClinic={setCheckedClinic} openModal={openModal} index={index} />
          {show && !loadingProcedures && (
              <>
                {proceduresIprSheet.length ? <PDFSheetPreview procedures={proceduresIprSheet}/> : null}
                <div style={{ width: 'max-content', display: 'flex', columnGap: '10px' }}>
                  <Button 
                    onClick={handleCounter}
                    disabled={props.disabled}
                    primary
                  >
                    Add
                  </Button>
                  <DownloadSelectIprSheet loading={loading} procedures={proceduresIprSheet}/>
                </div>
                <div style={{marginTop: '1rem', display: 'flex', justifyContent: 'flex-end'}}>
                  <Button
                    primary
                    onClick={props.autoSave}
                    disabled={props.disabled}
                  >
                    Save Section
                  </Button>
                </div>
              </>
          )}
          
        </FormLayout>
       </Card>
       {props.iprFileRequired}
      <Modal open={popupActive} loading={true}></Modal>
    </div>
  );
  function viewIPRsheet() {
    var win = window.open(props.IPRLink, "_blank");
    win.focus();
  }
};
export const ClinicModal = (props) => {
  const {handleClose, clinics, setCheckedClinic, openModal, setOpenModal, checkedClinic, index } = props;
  
  const handleClinicChange = () => {
    setOpenModal(false);
  }
  return (
    <Modal 
      title="List of clinics" 
      open={openModal} 
       primaryAction={{
         content: 'Done',
         onAction: handleClinicChange,
       }} 
      onClose={handleClose}>
      <div style={{
        height: "50vh",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}>
        <div>
          {clinics.map((val, key) => {
            return <div key={key}>
              <div style={{marginTop: "15px"}}>
                <div style={{
                  display: "flex",
                  gap: "5px",
                  paddingBottom: "20px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  alignItems: "center"
                }} key={key}>
                  <label style={{display: "flex", gap: "5px", alignItems: "center"}}>
                    <input style={{transform: "scale(1.3)"}} type="checkbox" name="clinics"
                       onChange={(e) => {
                         if (e.target.checked) {
                          setCheckedClinic(prev => {
                            const newCheckedArray = [...prev];
                            newCheckedArray[index]['assignedClinic'] = e.target.value;
                            return newCheckedArray;
                          });
                         } else {
                          setCheckedClinic(prev => {
                            const newCheckedArray = [...prev];
                            newCheckedArray[index]['assignedClinic'] = 0;
                            return newCheckedArray;
                          });
                         }
                       }}
                       value={val.id}
                       checked={parseInt(checkedClinic[index]?.assignedClinic) === parseInt(val.id)}
                    />
                    <p style={{fontWeight: "normal", fontSize: "20px"}}>
                      {val.name_identification.en}
                    </p>
                  </label>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </Modal>
  )
}