import { Card, Page } from "@shopify/polaris";
import AddOfferForm from "./add-offer-form.component";
import React, { useState } from "react";
import axios from "../axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function AddOffer() {
  const history = useHistory();

  const [formData, setFormData] = useState({
    countries: [],
    productId: "",
    title: "",
    quantity: "1",
    startDate: "",
    endDate: "",
    isFirst: true,
  });
  const [products, setProducts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const getOfferParameters = async (signal) => {
    try {
      setLoading(true);
      const response = await axios.get(`/admin/v1/offers/parameters`, {
        signal,
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      if (response.status === 200) {
        const { countries, products } = response?.data?.data;
        let countriesOptions = [];
        let productsOptions = [];
        if (countries && countries.length > 0) {
          countriesOptions = countries.map((country) => ({
            value: country.id,
            label: country.name.en,
          }));
        }
        if (products && products.length > 0) {
          productsOptions = products.map((product) => ({
            value: product.id,
            label: JSON.parse(product.title).en,
          }));
        }
        setProducts(productsOptions);
        setCountries(countriesOptions);
      }
    } catch (error) {
      console.log("Error in offers params api", error);
    } finally {
      setLoading(false);
    }
  };

  const handleErrors = () => {
    if (!formData.title) {
      toast.error("Title is required", 3000);
      return false;
    }
    if (!formData.productId) {
      toast.error("Product is required", 3000);
      return false;
    }
    if (formData.countries.length === 0) {
      toast.error("Countries are required", 3000);
      return false;
    }
    if (!formData.startDate) {
      toast.error("Start Date is required", 3000);
      return false;
    }
    if (!formData.endDate) {
      toast.error("End Date is required", 3000);
      return false;
    }
    if (formData.startDate > formData.endDate) {
      toast.error("Start Date should be before End Date", 3000);
      return false;
    }
    return true;
  };
  const handleSave = async () => {
    const check = handleErrors();
    if (!check) {
      return;
    }
    try {
      setLoadingSave(true);
      const response = await axios.post(`/admin/v1/offers`, formData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      if (response.status === 200) {
        if (response.data.success === true) {
          history.push("/admin/offers");
        } else {
          toast.error(response?.data?.message, 3000);
        }
      } else {
        toast.error("Something Wrong Happened", 3000);
      }
    } catch (error) {
      console.log("Error in saving offer", error);
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <Page
      fullWidth
      title="Create an Offer"
      breadcrumbs={[{ content: "List Of Offers", url: "/admin/offers" }]}
      primaryAction={{
        content: "Save",
        onClick: handleSave,
        loading: loadingSave,
      }}
    >
      <ToastContainer />
      <Card sectioned>
        <AddOfferForm
          formData={formData}
          setFormData={setFormData}
          products={products}
          countries={countries}
          loading={loading}
          getOfferParameters={getOfferParameters}
        />
      </Card>
    </Page>
  );
}

export default AddOffer;
