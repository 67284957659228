import React from "react";
import { TextStyle } from "@shopify/polaris";
import "@zendeskgarden/react-pagination/dist/styles.css";
import moment from "moment";
const MediaConsent = ({ consentResponse }) => {
  let isFilled = "Not Filled";
  if (consentResponse?.mediaConsent) {
    isFilled =
      "Customer consented to the Media Form agreement at:" +
      moment(consentResponse.mediaConsent.created_at)
        .add(2, "hours")
        .format("YYYY-MM-DD hh:mm:ss");
  }
  return <TextStyle>Media Consent: {isFilled}</TextStyle>;
};

export default React.memo(MediaConsent);
