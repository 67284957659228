import React, { useEffect, useState } from 'react'
import ApprovalActions from './ApprovalActions'
import ScreeningPhotoUpload from './ScreeningPhotoUpload'
import AlertDeleteDialog from './Popup/alert-delete-dialog.component';
import DisapprovalModal from './DisapprovalModal';
import axiosAws from "axios";
import axios from "../axios";
import { adminActions, caseRecordTypes, IssueTypes, ReviewStatus } from '../util/constants';
import Cookies from "js-cookie";
import {
    Box,
} from "@material-ui/core";

const ScreeningPhotos = ({
    photos,
    appointmentType,
    disable,
    appointmentId,
    handleDataFetched,
    userId,
    classes,
    setErrorMsg,
    loading,
    setLoading,
    delayedActionJob,
    updateReviewStatus,
    appointmentNumber,
    parentId,
    hasDelayedAction,
}) => {
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isReviewed, setIsReviewed] = useState(false);
    const [open, setOpen] = useState(false);
    const [photosArray, setPhotosArray] = useState([]);
    const [deletePhoto, setDeletePhoto] = useState({ photoId: null, type: '' });
    const [uploadProgress, setUploadProgress] = useState({});
    const [reviewData, setReviewData] = useState({
        comment: '',
        commentError: '',
        loading: false,
        modalOpened: false,
        status: ''
    });
    const [screeningIssues, setScreeningIssues] = useState([]);
    const [imagesIssues, setImagesIssues] = useState([]);
    const [fileImages, setFileImages] = useState([]);
    const [images, setImages] = useState([]);
    const [imageKeys, setImageKeys] = useState([]);
    const [options, setOptions] = useState([]);

    const handleReviewStatus = async (status) => {
        if (status != ReviewStatus.approved && !screeningIssues.length && !reviewData.comment) {
            setReviewData(prev => ({
                ...prev,
                commentError: 'Please enter a comment',
            }));
        } else {
            let data = {
                review_status: status,
                procedure: appointmentType?.procedure,
                appointment_id: appointmentId,
                record_id: photos?.id,
                record_type: caseRecordTypes.UserCaseRecordScreeningPhoto,
            }
            if (status != ReviewStatus.approved) {
                let comment = reviewData.comment;
                if (options.length && !screeningIssues.length) {
                    return;
                }
                if (screeningIssues.length) {
                    comment += '<br/>Screening issues: ';
                    comment += screeningIssues.map(issue => issue.label).join(', ');
                }
                if (imagesIssues.length) {
                    comment += '<br/>Selected images: ';
                    comment += imagesIssues.map(img => img.label).join(', ');
                }
                data = {
                    ...data,
                    comment,
                    images: imageKeys,
                    issues: screeningIssues.map(issue => issue.id),
                };
            }
            let body = {
                action: adminActions.caseRecordsStatus,
                data,
            }
            await updateReviewStatus(body, 'Status Updated', `${caseRecordTypes.UserCaseRecordScreeningPhoto}-${parentId}`);
            setReviewData(prev => ({
                ...prev,
                loading: false,
                modalOpened: false,
            }));
        }
    };

    const handleChangeComment = (newValue) => {
        setReviewData(prev => ({
            ...prev,
            comment: newValue,
        }));
    }

    const handleChangeModal = () => {
        setReviewData(prev => ({
            ...prev,
            modalOpened: false,
        }));
        setScreeningIssues([]);
        setImagesIssues([]);
    }

    const handleChangeReview = (status) => {
        setLoading(prev => ({
            ...prev,
            disableReviewBtn: status == ReviewStatus.approved,
        }));
        if (status == ReviewStatus.approved) {
            handleReviewStatus(status);
        } else {
            setReviewData(prev => ({
                ...prev,
                modalOpened: true,
                status,
            }));
        }
    }

    const handleChangeNotApplicable = (e, prop) => {
        let items = [{ name: prop, value: e ? 1 : 0 }];
        handleSave(items);
    };

    const handleUploadError = (type, err) => {
        setErrorMsg("Upload failed. Please try again.");
        console.log(err);
    };

    const uploadConfig = (progressEvent, name) => {
        if (progressEvent.loaded === progressEvent.total) {
            delete uploadProgress[name];
            setUploadProgress(uploadProgress);
        } else {
            const array = {
                ...uploadProgress,
                [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
            };
            setUploadProgress(array);
        }
    };

    const saveInAws = (value, type) => {
        let imageType = value.type;
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        form_data.append("file_name", value.name);
        axios.post("/admin/v1/images/s3", form_data, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((res) => {
            let items = [
                {
                    name: type,
                    value: res.data.key,
                },
            ];
            const url2 = res.data.url;
            axiosAws.put(url2, value, {
                headers: {
                    "x-amz-acl": "public-read-write",
                    "Content-Type": imageType,
                },
                onUploadProgress: (event) => uploadConfig(event, type),
            }).then(() => {
                handleSave(items);
            }).catch((err) => {
                setLoading(prev => ({
                    ...prev,
                    disabled: false,
                }));
                handleUploadError(type, err);
            });
        }).catch((err) => {
            setLoading(prev => ({
                ...prev,
                disabled: false,
            }));
            handleUploadError(type, err);
        });
    };

    const handleImages = (e, id) => {
        setErrorMsg("");
        setLoading(prev => ({
            ...prev,
            success: false,
            disabled: true,
        }));
        const imageId = id;
        const file = e.target.files[0];
        saveInAws(file, imageId);
    };

    const handleClickOpen = (photoIdData, typeData) => {
        setDeletePhoto({ photoId: photoIdData, type: typeData });
        setOpen(true);
    };

    const handleDelete = () => {
        const { photoId, type } = deletePhoto;
        setDeleteLoading(prev => ({ ...prev, [type]: true }));
        axios.delete(`admin/v2/users/case-record-screening-photos/delete/${photoId}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((res) => {
            if (res.data.success) {
                handleDataFetched();
            }
        }).catch((err) => {
            setErrorMsg("Delete failed. Please try again.");
            console.log(err);
        }).finally(() => setDeleteLoading(prev => ({ ...prev, [type]: false })));
    }

    const handleSave = (items) => {
        setLoading(prev => ({
            ...prev,
            success: false,
        }));
        setErrorMsg("");
        const form_data = new FormData();
        form_data.append("user_scan_info_id", appointmentId);
        form_data.append("procedure", appointmentType?.procedure);
        form_data.append("id", photos?.id);
        form_data.append("parent_id", photos?.parent_id);
        items.forEach((item) => {
            form_data.append(item.name, item.value);
        });
        axios.post(`admin/v2/users/${userId}/case-record-screening-photos`, form_data, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((res) => {
            handleDataFetched();
            if (res.data.success) {
                setLoading(prev => ({
                    ...prev,
                    success: false,
                }));
            }
        }).catch((err) => {
            setErrorMsg("Upload failed. Please try again.");
            console.log(err);
        }).finally(() => {
            setLoading(prev => ({
                ...prev,
                disabled: false,
            }));
        });
    };

    useEffect(() => {
        if (photos) {
            setIsReviewed(photos?.review_status ? true : false);

            let arr = [];
            photos?.screening_photo_files?.forEach(photo => {
                arr.push({ id: photo.name, url: photo.file_url });
            });
            setPhotosArray(arr);
        }
    }, [photos])

    return (
        <>
            <div>
                <Box className={`${classes.photoBox} ${classes.photoWidthSM}`}>
                    <ScreeningPhotoUpload
                        disabled={isReviewed || disable || loading.disabled}
                        value={photos?.screening_photo_files?.find(photo => photo.name === 'front_facing_big_smile')?.file_url}
                        handleImages={handleImages}
                        id='front_facing_big_smile'
                        title='Front Facing - Big Smile'
                        uploadProgress={uploadProgress?.front_facing_big_smile}
                        checked={photos?.front_facing_big_smile_na}
                        handleChangeNotApplicable={handleChangeNotApplicable}
                        type='front_facing_big_smile_na'
                        handleDelete={() => handleClickOpen(photos?.screening_photo_files?.find(photo => photo.name === 'front_facing_big_smile')?.id, 'frontFacingBigSmile')}
                        deleteDisabled={isReviewed || deleteLoading || !photos?.screening_photo_files?.find(photo => photo.name === 'front_facing_big_smile')?.id}
                        imagesArray={photosArray}
                        identifier={`screening-${appointmentType?.procedure}`}
                        recordId={photos?.id}
                    />
                    <ScreeningPhotoUpload
                        disabled={isReviewed || disable || loading.disabled}
                        value={photos?.screening_photo_files?.find(photo => photo.name === 'front_facing_no_smile')?.file_url}
                        handleImages={handleImages}
                        id='front_facing_no_smile'
                        title='Front Facing - No Smile'
                        uploadProgress={uploadProgress?.front_facing_no_smile}
                        checked={photos?.front_facing_no_smile_na}
                        handleChangeNotApplicable={handleChangeNotApplicable}
                        type='front_facing_no_smile_na'
                        handleDelete={() => handleClickOpen(photos?.screening_photo_files?.find(photo => photo.name === 'front_facing_no_smile')?.id, 'frontFacingNoSmile')}
                        deleteDisabled={isReviewed || deleteLoading || !photos?.screening_photo_files?.find(photo => photo.name === 'front_facing_no_smile')?.id}
                        imagesArray={photosArray}
                        identifier={`screening-${appointmentType?.procedure}`}
                        recordId={photos?.id}
                    />
                    <ScreeningPhotoUpload
                        disabled={isReviewed || disable || loading.disabled}
                        value={photos?.screening_photo_files?.find(photo => photo.name === 'right_side_profile_no_smile')?.file_url}
                        handleImages={handleImages}
                        id='right_side_profile_no_smile'
                        title='Profile - No Smile'
                        uploadProgress={uploadProgress?.right_side_profile_no_smile}
                        checked={photos?.right_side_profile_no_smile_na}
                        handleChangeNotApplicable={handleChangeNotApplicable}
                        type='right_side_profile_no_smile_na'
                        handleDelete={() => handleClickOpen(photos?.screening_photo_files?.find(photo => photo.name === 'right_side_profile_no_smile')?.id, 'rightSideProfileNoSmile')}
                        deleteDisabled={isReviewed || deleteLoading || !photos?.screening_photo_files?.find(photo => photo.name === 'right_side_profile_no_smile')?.id}
                        imagesArray={photosArray}
                        identifier={`screening-${appointmentType?.procedure}`}
                        recordId={photos?.id}
                    />
                </Box>
                <Box className={`${classes.photoBox} ${classes.photoWidth}`}>
                    <ScreeningPhotoUpload
                        disabled={isReviewed || disable || loading.disabled}
                        value={photos?.screening_photo_files?.find(photo => photo.name === 'center_bite')?.file_url}
                        handleImages={handleImages}
                        id='center_bite'
                        title='Center Bite'
                        uploadProgress={uploadProgress?.center_bite}
                        handleDelete={() => handleClickOpen(photos?.screening_photo_files?.find(photo => photo.name === 'center_bite')?.id, 'centerBite')}
                        deleteDisabled={isReviewed || deleteLoading || !photos?.screening_photo_files?.find(photo => photo.name === 'center_bite')?.id}
                        imagesArray={photosArray}
                        identifier={`screening-${appointmentType?.procedure}`}
                        recordId={photos?.id}
                    />
                    <ScreeningPhotoUpload
                        disabled={isReviewed || disable || loading.disabled}
                        value={photos?.screening_photo_files?.find(photo => photo.name === 'right_bite')?.file_url}
                        handleImages={handleImages}
                        id='right_bite'
                        title='Right Bite'
                        uploadProgress={uploadProgress?.right_bite}
                        handleDelete={() => handleClickOpen(photos?.screening_photo_files?.find(photo => photo.name === 'right_bite')?.id, 'rightBite')}
                        deleteDisabled={isReviewed || deleteLoading || !photos?.screening_photo_files?.find(photo => photo.name === 'right_bite')?.id}
                        imagesArray={photosArray}
                        identifier={`screening-${appointmentType?.procedure}`}
                        recordId={photos?.id}
                    />
                    <ScreeningPhotoUpload
                        disabled={isReviewed || disable || loading.disabled}
                        value={photos?.screening_photo_files?.find(photo => photo.name === 'left_bite')?.file_url}
                        handleImages={handleImages}
                        id='left_bite'
                        title='Left Bite'
                        uploadProgress={uploadProgress?.left_bite}
                        handleDelete={() => handleClickOpen(photos?.screening_photo_files?.find(photo => photo.name === 'left_bite')?.id, 'leftBite')}
                        deleteDisabled={isReviewed || deleteLoading || !photos?.screening_photo_files?.find(photo => photo.name === 'left_bite')?.id}
                        imagesArray={photosArray}
                        identifier={`screening-${appointmentType?.procedure}`}
                        recordId={photos?.id}
                    />
                    <ScreeningPhotoUpload
                        disabled={isReviewed || disable || loading.disabled}
                        value={photos?.screening_photo_files?.find(photo => photo.name === 'upper_teeth')?.file_url}
                        handleImages={handleImages}
                        id='upper_teeth'
                        title='Upper Occlusal'
                        uploadProgress={uploadProgress?.upper_teeth}
                        handleDelete={() => handleClickOpen(photos?.screening_photo_files?.find(photo => photo.name === 'upper_teeth')?.id, 'upperTeeth')}
                        deleteDisabled={isReviewed || deleteLoading || !photos?.screening_photo_files?.find(photo => photo.name === 'upper_teeth')?.id}
                        imagesArray={photosArray}
                        identifier={`screening-${appointmentType?.procedure}`}
                        recordId={photos?.id}
                    />
                    <ScreeningPhotoUpload
                        disabled={isReviewed || disable || loading.disabled}
                        value={photos?.screening_photo_files?.find(photo => photo.name === 'lower_teeth')?.file_url}
                        handleImages={handleImages}
                        id='lower_teeth'
                        title='Lower Occlusal'
                        uploadProgress={uploadProgress?.lower_teeth}
                        handleDelete={() => handleClickOpen(photos?.screening_photo_files?.find(photo => photo.name === 'lower_teeth')?.id, 'lowerTeeth')}
                        deleteDisabled={isReviewed || deleteLoading || !photos?.screening_photo_files?.find(photo => photo.name === 'lower_teeth')?.id}
                        imagesArray={photosArray}
                        identifier={`screening-${appointmentType?.procedure}`}
                        recordId={photos?.id}
                    />
                </Box>
                <ApprovalActions
                    disabled={hasDelayedAction || delayedActionJob.disabledRecord == `${caseRecordTypes.UserCaseRecordScreeningPhoto}-${parentId}` || !photos?.screening_photo_files?.length || loading.disableReviewBtn || photos?.review_status != null}
                    reviewStatus={photos?.review_status}
                    reviewer={photos?.reviewer}
                    reviewedAt={photos?.reviewed_at}
                    handleChangeReview={handleChangeReview}
                    withApprovedWithIssuesOption={true}
                />
                {reviewData.modalOpened && (
                    <DisapprovalModal
                        activeModal={reviewData.modalOpened}
                        handleChangeModal={handleChangeModal}
                        title={reviewData.status === ReviewStatus.approvedWithIssues ? 'Approve With Issues' : 'Disapprove Screening Photos'}
                        dropDownTitle='Screening Photos Issues'
                        handleSubmitDisapprove={() => handleReviewStatus(reviewData.status)}
                        loading={reviewData.loading}
                        commentValue={reviewData.comment}
                        handleChangeComment={handleChangeComment}
                        commentError={reviewData.commentError}
                        setFilesImage={setFileImages}
                        filesImage={fileImages}
                        images={images}
                        setImages={setImages}
                        imagesKey={imageKeys}
                        setImageKey={setImageKeys}
                        issueType={reviewData.status == ReviewStatus.approvedWithIssues ? IssueTypes.screeningApprovedWithIssues : IssueTypes.screeningNotApproved}
                        setIssues={setScreeningIssues}
                        issues={screeningIssues}
                        showImagesDropdown
                        setImagesIssues={setImagesIssues}
                        options={options}
                        setOptions={setOptions}
                    />
                )}
                <AlertDeleteDialog open={open} setOpen={setOpen} handleAction={handleDelete} />
            </div>
            <div className={classes.replacedPhotosWrapper}>
                {
                    photos?.replaced_file &&
                    <ScreeningPhotos
                        photos={photos.replaced_file}
                        appointmentType={appointmentType}
                        disable={disable}
                        appointmentId={appointmentId}
                        handleDataFetched={handleDataFetched}
                        userId={userId}
                        classes={classes}
                        setErrorMsg={setErrorMsg}
                        loading={loading}
                        setLoading={setLoading}
                        updateReviewStatus={updateReviewStatus}
                        delayedActionJob={delayedActionJob}
                        appointmentNumber={appointmentNumber}
                        parentId={parentId}
                        hasDelayedAction={hasDelayedAction}
                    />
                }
            </div>
        </>
    )
}

export default ScreeningPhotos