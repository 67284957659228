import React, { useState } from 'react';
import { Box, TableCell, TableRow, TextField } from '@mui/material';
import {
  AddCircle,
  DragIndicator,
  RemoveCircle,
  Info,
  Splitscreen,
} from '@mui/icons-material';
import IconButton from '@material-ui/core/IconButton';
import SubdirectoryArrowRightSharpIcon from '@mui/icons-material/SubdirectoryArrowRightSharp';
import Select from 'react-select';
import { Draggable } from 'react-beautiful-dnd';
import { useStyles } from './edit-delivery-batches-table.styles';
import {
  capitalizeWords,
  disableStepsAccordingToBatchType,
  filterSubTypesAccordingToType,
} from '../../util/helpers';
import AddNoteBatchDialog from './add-note-batch-dialog.componenet';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import {
  DispatchesSubTypes, DispatchProductionTypes,
  DispatchTypeCodes,
  GeneralFormats
} from '../../util/constants';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

const DeliveryBatchRow = ({
  item,
  index,
  setFormRows,
  handleClickOpen,
  handleClickSplitDialogOpen,
  handleRowInputChange,
  treatmentStages,
  cuttingTechnicians,
  laserCutlineTechnicians,
  subTypes,
  productTypes,
  validateSteps,
  validateToSteps,
  colorMapping,
  colors,
  setColorIndex,
  colorIndex,
  updateBatch,
}) => {
  const classes = useStyles();
  const dateFormat = GeneralFormats.dateFormat;
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const reactSelectCustomStyles = {
    control: (provided) => ({
      ...provided,
      width: 'max-content',
    }),
  };

  const handleNoteOpen = () => {
    setOpenNoteDialog(true);
  };

  const disableFirstBatch = parseInt(item.upperStepsFrom) === 1;
  const disableFieldsIfItHasAnActualDispatchDate =
    item.actualDispatchDate !== '-';

  const handleAddRow = (index) => {
    let delivery = index + 2;
    const newRow = {
      number: '-',
      delivery,
      treatmentStage:
        treatmentStagesList &&
        treatmentStagesList.length > 0 &&
        treatmentStagesList[0].value,
      type: '-',
      subType: '-',
      parentId: null,
      expectedReceiptDate: '-',
      actualReceiptDate: '-',
      expectedStartOfBatch: '-',
      expectedStartOfLastStepInBatch: '-',
      expectedLastPaymentDueDate: '-',
      actualLastPaymentDueDate: '-',
      upperStepsFrom: 0,
      upperStepsTo: 0,
      lowerStepsFrom: 0,
      lowerStepsTo: 0,
      upperSteps: 0,
      lowerSteps: 0,
      upperPlasticOptions: '-',
      lowerPlasticOptions: '-',
      cuttingTechnician: '-',
      laserCutlineTechnician: '-',
      productionType: DispatchProductionTypes[0].value,
      qualityControl: false,
      productionStartDate: null,
      productionEndDate: null,
      targetDispatchDate: '',
      actualDispatchDate: '-',
      note: '',
      upperProductQuantity: 0,
      lowerProductQuantity: 0,
      error: false,
      isDisabled: false,
      isPaused: false,
      isPickUpFromClinic: false,
      isIncentive: false,
      isShopProduct: false,
      oldParentId: null,
    };

    setFormRows((prevRows) => {
      let newRows = [...prevRows];
      const currentDate = moment().format('YYYY-MM-DD');
      // Combined batch case
      if (newRows[index]?.parentId) {
        newRow.delivery = newRows[index].delivery;
        newRow.parentId = newRows[index].parentId;
      }

      // Find the index to insert the new object
      let indexToInsert = newRows.findIndex((item) =>
        moment(item.targetDispatchDate).isAfter(currentDate)
      );

      // If no object has a date greater than today, insert at the end
      if (indexToInsert === -1) {
        indexToInsert = newRows.length;
      }

      newRows.splice(indexToInsert, 0, newRow);
      // Recalculate the delivery number and change it only for non combined batches
      let counter = 1;
      // Resetting the counters for the batches
      newRows = newRows.map((item) => {
        let calculateColor;
        if (colorMapping[counter] !== undefined) {
          calculateColor = colorMapping[counter];
        } else {
          calculateColor = colors[colorIndex];
          setColorIndex((prev) => (prev + 1) % colors.length);
        }
        if (item.parentId === null) {
          return { ...item, delivery: counter++, color: calculateColor };
        }
        return item;
      });

      // Update the child deliveries
      newRows = newRows.map((item) => {
        const findIndex = newRows.findIndex(
          (value) => value.batchId === item.parentId
        );
        if (findIndex !== -1 && item.parentId) {
          return {
            ...item,
            delivery: newRows[findIndex].delivery,
            color: newRows[findIndex].color,
          };
        }
        return item;
      });

      return newRows;
    });
  };

  let types = [];
  let typesData = [...productTypes];
  types = typesData.map((type) => ({
    value: type.code,
    label: capitalizeWords(type.name),
  }));

  let plasticOptionsList = [];
  let disablePlasticOptionsOnNull = false;

  if (item.type !== '-') {
    const chosenType = productTypes.find((type) => type.code === item.type);
    if (chosenType && chosenType?.plastic_types.length > 0) {
      plasticOptionsList = chosenType.plastic_types.map((plasticType) => ({
        value: plasticType.code,
        label: plasticType.name,
      }));
    } else {
      disablePlasticOptionsOnNull = true;
    }
  } else {
    disablePlasticOptionsOnNull = true;
  }

  const calculateStepsQuantity = (input, isAligner = false) => {
    if (isAligner) {
      input = `${input.from}-${input.to}`;
    }
    if (input == 0 || input == '0-0') {
      return 0;
    }
    const regex = /(\d+)(?:-(\d+))?/g;
    let match;
    let totalQuantity = 0;

    while ((match = regex.exec(input)) !== null) {
      const start = parseInt(match[1]);
      const end = match[2] ? parseInt(match[2]) : start;

      totalQuantity += end - start + 1;
    }
    return totalQuantity;
  };

  // Populate the cutting technician list
  let cuttingTechnicianList = [];
  cuttingTechnicianList = cuttingTechnicians.map((cuttingTechnician) => ({
    value: cuttingTechnician.full_name,
    label: capitalizeWords(cuttingTechnician.full_name),
  }));
  
  // Populate the laser cutline technician list
  let laserCutlineTechnicianList = [];
  laserCutlineTechnicianList = laserCutlineTechnicians.map((laserCutlineTechnician) => ({
    value: laserCutlineTechnician.full_name,
    label: capitalizeWords(laserCutlineTechnician.full_name),
  }));
  
  // Populate Production Type List
  const productionTypeList = DispatchProductionTypes;

  // Populate the subtypes list
  let subTypesList = filterSubTypesAccordingToType(item.type, subTypes);
  // Remove standard subtype if it is a new aligner
  if (item.type === DispatchTypeCodes.aligners && !item.isAligner) {
    const index = subTypesList.indexOf(DispatchesSubTypes.standard);
    subTypesList.splice(index, 1);
  }
  subTypesList = subTypesList.map((subType) => ({
    value: subType,
    label: capitalizeWords(subType),
  }));

  // Populate the treatment stage list
  let treatmentStagesList = [];
  treatmentStagesList = treatmentStages.map((plan) => ({
    value: plan.id,
    label: plan.name,
  }));

  // Hide Tp select if only one plan is available
  let checkIfOnlyOneTreatmentPlan = false;
  let treatmentStage = '';
  if (treatmentStagesList.length === 1) {
    checkIfOnlyOneTreatmentPlan = true;
    treatmentStage = treatmentStagesList[0].label;
  }

  let disableForProducts = disableStepsAccordingToBatchType(item.type);

  let selectedTypeFound = null;
  if (types) {
    if (item.isAligner) {
      selectedTypeFound =
        types.find(
          (option) => option.value === DispatchTypeCodes['aligners']
        ) || '';
    } else {
      selectedTypeFound =
        types.find((option) => option.value === item.type) || '';
    }

    if (selectedTypeFound) {
      selectedTypeFound = { ...selectedTypeFound };
    }
  }

  let disableDragging = item.actualDispatchDate !== '-';
  // Quality Control Flow
  let card = item?.card;
  const disabledQualityControl =
    item.subType !== DispatchesSubTypes.replacement ||
    item.actualDispatchDate !== "-" ||
    card?.card_id;
  
  // Disable Trello card manual creation
  const disableTrelloCardCreation =
    item.batchId == null ||
    item.isDisabled ||
    item.actualDispatchDate !== "-" ||
    item.isPaused ||
    item.parentId ||
    card?.card_id;

  return (
    <>
      <Draggable
        isDragDisabled={disableDragging}
        key={index}
        draggableId={index.toString()}
        index={index}
      >
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            key={index}
            className={classes.tableCell}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              backgroundColor: snapshot.combineTargetFor
                ? 'lightgreen !important'
                : snapshot.isDragging
                ? 'white !important'
                : `${item.color} !important`,
              borderLeft: item.error
                ? '10px solid red'
                : item.parentId
                ? `10px solid #ABC4FF`
                : 'none',
              transition: 'all 0.2s ease',
            }}
          >
            {item.parentId ? (
              <TableCell></TableCell>
            ) : (
              <TableCell {...provided.dragHandleProps}>
                <DragIndicator style={{ cursor: 'grab' }} />
              </TableCell>
            )}

            <TableCell>
              {item.parentId ? (
                <div
                  {...provided.dragHandleProps}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <SubdirectoryArrowRightSharpIcon
                    fontSize="large"
                    style={{ cursor: 'grab' }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '10px',
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleAddRow(index)}
                    >
                      <AddCircle />
                    </IconButton>
                    {(!item.isAligner ||
                      !disableFieldsIfItHasAnActualDispatchDate) && (
                      <IconButton
                        size="small"
                        onClick={() => handleClickOpen(index)}
                      >
                        <RemoveCircle />
                      </IconButton>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <IconButton size="small" onClick={() => handleAddRow(index)}>
                    <AddCircle />
                  </IconButton>
                  {item.isAligner &&
                    !disableFieldsIfItHasAnActualDispatchDate &&
                    !disableFirstBatch && (
                      <IconButton
                        size="small"
                        onClick={() => handleClickSplitDialogOpen(index)}
                      >
                        <Splitscreen />
                      </IconButton>
                    )}
                  {!item.isAligner &&
                    !disableFieldsIfItHasAnActualDispatchDate && (
                      <IconButton
                        size="small"
                        onClick={() => handleClickOpen(index)}
                      >
                        <RemoveCircle />
                      </IconButton>
                    )}
                </>
              )}
            </TableCell>
            <TableCell>
              <Select
                menuPosition="fixed"
                menuPlacement="auto"
                options={types}
                styles={reactSelectCustomStyles}
                value={selectedTypeFound}
                onChange={(selectedOption) =>
                  handleRowInputChange(index, 'type', selectedOption.value)
                }
                placeholder="Select an option"
                isDisabled={
                  item.isAligner ||
                  disableFirstBatch ||
                  disableFieldsIfItHasAnActualDispatchDate ||
                  item.isDisabled
                }
              />
            </TableCell>
            <TableCell>
              <Select
                menuPosition="fixed"
                menuPlacement="auto"
                options={subTypesList}
                styles={reactSelectCustomStyles}
                value={
                  subTypesList.find(
                    (option) => option.value === item.subType
                  ) || ''
                }
                onChange={(selectedOption) =>
                  handleRowInputChange(index, 'subType', selectedOption.value)
                }
                isDisabled={
                  item.isAligner ||
                  disableFieldsIfItHasAnActualDispatchDate ||
                  item.isDisabled ||
                  item.type === '-'
                }
                placeholder="Select an option"
              />
            </TableCell>
            <TableCell>
              {checkIfOnlyOneTreatmentPlan ? (
                treatmentStage
              ) : (
                <Select
                  menuPosition="fixed"
                  menuPlacement="auto"
                  options={treatmentStagesList}
                  styles={reactSelectCustomStyles}
                  value={treatmentStagesList.find(
                    (option) => option.value === item.treatmentStage
                  )}
                  onChange={(selectedOption) =>
                    handleRowInputChange(
                      index,
                      'treatmentStage',
                      selectedOption.value
                    )
                  }
                  isDisabled={
                    item.isAligner ||
                    disableFieldsIfItHasAnActualDispatchDate ||
                    item.isDisabled
                  }
                  placeholder="Select an option"
                />
              )}
            </TableCell>
            <TableCell>{item.number || '-'}</TableCell>
            <TableCell>{item.delivery}</TableCell>
            <TableCell>
              {item.isAligner &&
              item.subType === DispatchesSubTypes.standard ? (
                <div className={classes.stepsContainer}>
                  <TextField
                    variant="standard"
                    value={item.upperStepsFrom}
                    onChange={(e) =>
                      handleRowInputChange(
                        index,
                        'upperStepsFrom',
                        e.target.value
                      )
                    }
                    type="number"
                    placeholder="Upper Steps From"
                    disabled={true}
                    className={classes.disabledColor}
                  />
                  <span>-</span>
                  <TextField
                    variant="standard"
                    error={
                      !validateToSteps(
                        item.upperStepsTo,
                        index,
                        'upperStepsFrom',
                        item.treatmentStage
                      )
                    }
                    value={item.upperStepsTo}
                    onChange={(e) =>
                      handleRowInputChange(
                        index,
                        'upperStepsTo',
                        e.target.value
                      )
                    }
                    className={classes.disabledColor}
                    placeholder="Upper Steps To"
                    type="number"
                    disabled={
                      disableForProducts ||
                      disableFirstBatch ||
                      disableFieldsIfItHasAnActualDispatchDate ||
                      item.isDisabled
                    }
                  />
                </div>
              ) : (
                <TextField
                  variant="standard"
                  error={
                    !disableForProducts &&
                    !validateSteps(item.upperSteps, index)
                  }
                  value={item.upperSteps}
                  onChange={(e) =>
                    handleRowInputChange(index, 'upperSteps', e.target.value)
                  }
                  placeholder="Upper Steps"
                  disabled={
                    disableForProducts ||
                    disableFieldsIfItHasAnActualDispatchDate ||
                    item.isDisabled
                  }
                  className={classes.disabledColor}
                />
              )}
            </TableCell>
            <TableCell>
              {item.isAligner &&
              item.subType === DispatchesSubTypes.standard ? (
                <div className={classes.stepsContainer}>
                  <TextField
                    variant="standard"
                    value={item.lowerStepsFrom}
                    onChange={(e) =>
                      handleRowInputChange(
                        index,
                        'lowerStepsFrom',
                        e.target.value
                      )
                    }
                    type="number"
                    placeholder="Lower Steps From"
                    disabled={true}
                    className={classes.disabledColor}
                  />
                  <span>-</span>
                  <TextField
                    variant="standard"
                    error={
                      !validateToSteps(
                        item.lowerStepsTo,
                        index,
                        'lowerStepsFrom',
                        item.treatmentStage
                      )
                    }
                    value={item.lowerStepsTo}
                    onChange={(e) =>
                      handleRowInputChange(
                        index,
                        'lowerStepsTo',
                        e.target.value
                      )
                    }
                    className={classes.disabledColor}
                    type="number"
                    placeholder="Lower Steps To"
                    disabled={
                      disableForProducts ||
                      disableFirstBatch ||
                      disableFieldsIfItHasAnActualDispatchDate ||
                      item.isDisabled
                    }
                  />
                </div>
              ) : (
                <TextField
                  variant="standard"
                  error={
                    !disableForProducts &&
                    !validateSteps(item.lowerSteps, index)
                  }
                  value={item.lowerSteps}
                  onChange={(e) =>
                    handleRowInputChange(index, 'lowerSteps', e.target.value)
                  }
                  className={classes.disabledColor}
                  placeholder="Lower Steps"
                  disabled={
                    disableForProducts ||
                    disableFieldsIfItHasAnActualDispatchDate ||
                    item.isDisabled
                  }
                />
              )}
            </TableCell>
            <TableCell>
              {item.isAligner
                ? calculateStepsQuantity(
                    { from: item.upperStepsFrom, to: item.upperStepsTo },
                    item.isAligner
                  )
                : calculateStepsQuantity(item.upperSteps)}
            </TableCell>
            <TableCell>
              {item.isAligner
                ? calculateStepsQuantity(
                    { from: item.lowerStepsFrom, to: item.lowerStepsTo },
                    item.isAligner
                  )
                : calculateStepsQuantity(item.lowerSteps)}
            </TableCell>
            <TableCell>
              <Select
                menuPosition="fixed"
                menuPlacement="auto"
                options={plasticOptionsList}
                styles={reactSelectCustomStyles}
                value={
                  plasticOptionsList.find(
                    (option) => option.value === item.upperPlasticOptions
                  ) || ''
                }
                onChange={(selectedOption) =>
                  handleRowInputChange(
                    index,
                    'upperPlasticOptions',
                    selectedOption.value
                  )
                }
                isDisabled={
                  disablePlasticOptionsOnNull ||
                  disableFieldsIfItHasAnActualDispatchDate ||
                  item.isDisabled
                }
                placeholder="Select an option"
              />
            </TableCell>
            <TableCell>
              <Select
                menuPosition="fixed"
                menuPlacement="auto"
                options={plasticOptionsList}
                styles={reactSelectCustomStyles}
                value={
                  plasticOptionsList.find(
                    (option) => option.value === item.lowerPlasticOptions
                  ) || ''
                }
                onChange={(selectedOption) =>
                  handleRowInputChange(
                    index,
                    'lowerPlasticOptions',
                    selectedOption.value
                  )
                }
                isDisabled={
                  disablePlasticOptionsOnNull ||
                  disableFieldsIfItHasAnActualDispatchDate ||
                  item.isDisabled
                }
                placeholder="Select an option"
              />
            </TableCell>
            <TableCell>
              <Select
                menuPosition="fixed"
                menuPlacement="auto"
                options={cuttingTechnicianList}
                styles={reactSelectCustomStyles}
                value={
                  cuttingTechnicianList.find(
                    (option) => option.value === item.cuttingTechnician
                  ) || ''
                }
                onChange={(selectedOption) =>
                  handleRowInputChange(
                    index,
                    'cuttingTechnician',
                    selectedOption.value
                  )
                }
                isDisabled={
                  disablePlasticOptionsOnNull ||
                  disableFieldsIfItHasAnActualDispatchDate ||
                  item.isDisabled
                }
                placeholder="Select an option"
              />
            </TableCell>
            <TableCell>
              <Select
                menuPosition="fixed"
                menuPlacement="auto"
                options={laserCutlineTechnicianList}
                styles={reactSelectCustomStyles}
                value={
                  laserCutlineTechnicianList.find(
                    (option) => option.value === item.laserCutlineTechnician
                  ) || ''
                }
                onChange={(selectedOption) =>
                  handleRowInputChange(
                    index,
                    'laserCutlineTechnician',
                    selectedOption.value
                  )
                }
                isDisabled={
                  disablePlasticOptionsOnNull ||
                  disableFieldsIfItHasAnActualDispatchDate ||
                  item.isDisabled
                }
                placeholder="Select an option"
              />
            </TableCell>
            <TableCell>
              <Select
                menuPosition="fixed"
                menuPlacement="auto"
                options={productionTypeList}
                styles={reactSelectCustomStyles}
                value={
                  productionTypeList.find(
                    (option) => option.value === item.productionType
                  ) || productionTypeList[0]
                }
                onChange={(selectedOption) =>
                  handleRowInputChange(
                    index,
                    'productionType',
                    selectedOption.value
                  )
                }
                isDisabled={
                  disablePlasticOptionsOnNull ||
                  disableFieldsIfItHasAnActualDispatchDate ||
                  item.isDisabled
                }
                placeholder="Select an option"
              />
            </TableCell>
            <TableCell>
              <Checkbox
                checked={item.isPickUpFromClinic}
                disabled={
                  item.isDisabled ||
                  item.parentId ||
                  item.actualDispatchDate !== "-"
                }
                onChange={(e) =>
                  handleRowInputChange(index, "isPickUpFromClinic", e.target.checked)
                }
              />
            </TableCell>            
            <TableCell>
              <Checkbox
                checked={item.isPaused}
                disabled={
                  item.isDisabled ||
                  item.parentId ||
                  item.actualDispatchDate !== "-"
                }
                onChange={(e) =>
                  handleRowInputChange(index, "isPaused", e.target.checked)
                }
              />
            </TableCell>
            <TableCell>
              <Checkbox
                checked={item.isIncentive}
                disabled={true}
              />
            </TableCell>
            <TableCell>
              <Checkbox
                  checked={item.qualityControl}
                  disabled={disabledQualityControl}
                  onChange={(e) =>
                      handleRowInputChange(
                          index,
                          "qualityControl",
                          e.target.checked,
                      )
                  }
              />
            </TableCell>
            <TableCell>
              {item.productionStartDate ? (
                moment(item.productionStartDate).format(dateFormat)
              ) : (
                <Button
                  onClick={() => updateBatch('start', item.batchId)}
                  variant="contained"
                >
                  Start
                </Button>
              )}
            </TableCell>
            <TableCell>
              {item.productionEndDate ? (
                moment(item.productionEndDate).format(dateFormat)
              ) : (
                <Button
                  onClick={() => updateBatch('end', item.batchId)}
                  variant="contained"
                  disabled={!item.productionStartDate}
                >
                  End
                </Button>
              )}
            </TableCell>
            <TableCell>
              <Button
                disabled={disableTrelloCardCreation}
                onClick={() => updateBatch("trello", item.batchId)}
                variant="contained"
              >
                Create Card
              </Button>
            </TableCell>
            <TableCell>
              <TextField
                className={classes.disabledColor}
                variant="standard"
                value={item.targetDispatchDate}
                onChange={(e) =>
                  handleRowInputChange(
                    index,
                    'targetDispatchDate',
                    e.target.value
                  )
                }
                type="date"
                disabled={
                  disableFirstBatch ||
                  disableFieldsIfItHasAnActualDispatchDate ||
                  item.isDisabled
                }
              />
            </TableCell>
            <TableCell>
              <TextField
                className={classes.disabledColor}
                variant="standard"
                value={item.upperProductQuantity}
                onChange={(e) =>
                  handleRowInputChange(
                    index,
                    'upperProductQuantity',
                    e.target.value
                  )
                }
                disabled={
                  !disableForProducts ||
                  disableFieldsIfItHasAnActualDispatchDate ||
                  item.isDisabled
                }
                placeholder="Upper Product Quantity"
              />
            </TableCell>
            <TableCell>
              <TextField
                className={classes.disabledColor}
                variant="standard"
                value={item.lowerProductQuantity}
                onChange={(e) =>
                  handleRowInputChange(
                    index,
                    'lowerProductQuantity',
                    e.target.value
                  )
                }
                disabled={
                  !disableForProducts ||
                  disableFieldsIfItHasAnActualDispatchDate ||
                  item.isDisabled
                }
                placeholder="Lower Product Quantity"
              />
            </TableCell>
            <TableCell>
              <Box className={classes.iconsContainer}>
                <IconButton
                  disabled={item.isDisabled}
                  size="small"
                  onClick={handleNoteOpen}
                >
                  <AddCircle />
                </IconButton>
                {item.note && (
                  <Tooltip
                    classes={{ tooltip: classes.tootTip }}
                    title={item.note}
                    placement="top"
                  >
                    <Info color="info" />
                  </Tooltip>
                )}
              </Box>
            </TableCell>
            <TableCell>
              {item.actualDispatchDate && item.actualDispatchDate !== '-'
                ? moment(item.actualDispatchDate).format(dateFormat)
                : '-'}
            </TableCell>
            <TableCell>
              {item.expectedReceiptDate && item.expectedReceiptDate !== '-'
                ? moment(item.expectedReceiptDate).format(dateFormat)
                : '-'}
            </TableCell>
            <TableCell>
              {item.actualReceiptDate && item.actualReceiptDate !== '-'
                ? moment(item.actualReceiptDate).format(dateFormat)
                : '-'}
            </TableCell>
            <TableCell>
              {item.expectedStartOfBatch && item.expectedStartOfBatch !== '-'
                ? moment(item.expectedStartOfBatch).format(dateFormat)
                : '-'}
            </TableCell>
            <TableCell>
              {item.expectedStartOfLastStepInBatch &&
              item.expectedStartOfLastStepInBatch !== '-'
                ? moment(item.expectedStartOfLastStepInBatch).format(dateFormat)
                : '-'}
            </TableCell>
            <TableCell>
              {item.expectedLastPaymentDueDate &&
              item.expectedLastPaymentDueDate !== '-'
                ? moment(item.expectedLastPaymentDueDate).format(dateFormat)
                : '-'}
            </TableCell>
            <TableCell>
              {item.actualLastPaymentDueDate &&
              item.actualLastPaymentDueDate !== '-'
                ? moment(item.actualLastPaymentDueDate).format(dateFormat)
                : '-'}
            </TableCell>
          </TableRow>
        )}
      </Draggable>
      <AddNoteBatchDialog
        note={item.note}
        open={openNoteDialog}
        setOpen={setOpenNoteDialog}
        handleRowInputChange={handleRowInputChange}
        selectedIndex={index}
      />
    </>
  );
};

export default DeliveryBatchRow;
