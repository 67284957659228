import { Card, Layout, TextStyle } from "@shopify/polaris";
import "@zendeskgarden/react-pagination/dist/styles.css";
import moment from "moment";
import MediaConsent from "./MediaConsent";
const Consent = ({ consentResponse }) => {
  let isFilled = "Not Filled";
  if (consentResponse?.consent) {
    isFilled =
      "Approved by user at " +
      moment(consentResponse.consent.created_at)
        .add(2, "hours")
        .format("YYYY-MM-DD hh:mm:ss");
  }
  
  return (
    <Layout.Section>
      <Card title="CONSENTS" sectioned>
        <TextStyle>Informed Consent: {isFilled}</TextStyle>
        <br />
        <br />
        <MediaConsent consentResponse={consentResponse} />
      </Card>
    </Layout.Section>
  );
};

export default Consent;
