import React, { useCallback, useState, useEffect, useContext } from "react";
import {
  Card,
  Tabs,
  Filters,
  Loading,
  DisplayText,
  ChoiceList,
  TextStyle,
  Page,
  Toast,
  AppProvider,
  DataTable,
  Image,
} from "@shopify/polaris";
import history from "../history";
import Cookie from "js-cookie";

import "@zendeskgarden/react-pagination/dist/styles.css";
import { useParams } from "react-router-dom";
import { UserContext } from "../UserContext";
import emptyIcon from "../img/emptyList.svg";

export default function ListOfPayments() {
  const user = useContext(UserContext);

  let { id } = useParams();
  var json_str = user.paymentObject;
  console.log("json_str=", json_str);

  var cookieResult = JSON.parse(json_str);
  console.log("cookieResult=", cookieResult.upcoming_payments);

  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [perPage, setPerPage] = useState(100);
  const [selectedTab, setSelectedTab] = useState(0);
  const [availability, setAvailability] = useState("");
  function handleTabChange(selectedTab) {
    setIsListEmpty(false);
    setSelectedTab(selectedTab);
  }
  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState("User Impersonated");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;

  const tabs = [
    {
      content: "Overdue Payments",
      id: "0",
    },

    {
      content: "Upcoming Payments",
      id: "1",
    },
  ];
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortValue, setSortValue] = useState("DATE_MODIFIED_DESC");
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  // const handleQueryValueChange = useCallback(
  //     (value) => setQueryValue(value),
  //     [],getData(selectedTab)
  // );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  // const handleFiltersClearAll = useCallback(() => {
  //   handleAvailabilityRemove();
  //   handleQueryValueRemove();
  // }, [handleAvailabilityRemove, handleQueryValueRemove]);
  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }

  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);

  function handleQueryValueChange(queryValue) {
    setQueryValue(queryValue);
    setIsListEmpty(false);
  }
  let boolImpersonateClicked = false;
  const resourceName = {
    singular: "customer",
    plural: "customers",
  };

  const [items, setItems] = useState([]);

  const [dataArray, setDataArray] = useState([]);

  const promotedBulkActions = [
    {
      content: "Edit customers",
      onAction: () => console.log("Todo: implement bulk edit"),
    },
  ];

  const bulkActions = [
    {
      content: "Add tags",
      onAction: () => console.log("Todo: implement bulk add tags"),
    },
    {
      content: "Remove tags",
      onAction: () => {
        const testarr = { items };
        items.splice(0, 2);
        setItems({ items });
      },
    },
    {
      content: "Delete customers",
      onAction: () => console.log("Todo: implement bulk delete"),
    },
  ];
  const [selectedFilterOption, setSelectedFilterOption] = useState("today");

  //const handleSelectChangeFiterOption = useCallback((value) => selectedFilterOption(value), []);

  function handleSelectChangeFiterOption(selectedFilterOption) {
    setSelectedFilterOption(selectedFilterOption);
  }
  const options = [
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
    { label: "Status", value: "status" },
  ];

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "First Name", value: "first_name" },
            { label: "Last Name", value: "last_name" },
            { label: "Email", value: "email" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
          // allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const filterControl = (
    <Filters
      noSearchResults={<div>No results for your search criteria!</div>}
      queryValue={queryValue}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    ></Filters>
  );

  const isFirstPage = true;
  const isLastPage = true;

  let arrayOfObjects = [];
  // const [arrayOverdues, setArrayOverdues] = useState([]);
  // const [arrayUpcoming, setArrayUpcoming] = useState([]);
  const [resultArray, setResultArray] = useState([]);
  const [arrayOverdues, setArrayOverdues] = useState([]);
  const [arrayUpcomings, setArrayUpcomings] = useState([]);

  const numberOfNightsBetweenDates = (startDate, endDate) => {
    const start = new Date(startDate); //clone
    const end = new Date(endDate); //clone
    let dayCount = 0;

    while (end > start) {
      dayCount++;
      start.setDate(start.getDate() + 1);
    }

    return dayCount;
  };
  useEffect(() => {
    if (selectedTab === 0) {
      cookieResult.overdue_payments
        ? setItems(
            cookieResult.overdue_payments.map((item) => [
              // <Avatar source={item.profile_image_url}></Avatar>,
              item.id,
              "$ " + item.total,
              item.withdraw_date,
              numberOfNightsBetweenDates(item.withdraw_date, new Date()) + " Days",
            ])
          )
        : setItems([]);
    } else {
      cookieResult.upcoming_payments
        ? setItems(
            cookieResult.upcoming_payments.map((item) => [
              item.id,
              "$ " + item.total,
              item.withdraw_date,
              numberOfNightsBetweenDates(new Date(), item.withdraw_date) + " Days",
            ])
          )
        : setItems([]);
    }
  }, [selectedTab]);

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result">
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  return (
    <Page
      fullWidth
      title="List Of Overdues and Upcomings Payments"
      breadcrumbs={[{ content: "List Of Payments", url: "/admin/payments" }]}
      // subtitle={user.paymentUserName + " ( ID : " + user.paymentUserID + " )"}
      secondaryActions={[
        {
          content:
            user.paymentUserName + " ( ID : " + user.paymentUserID + " )",
          accessibilityLabel: "Secondary action label",
          onAction: () =>
            history.push("/admin/users/" + user.paymentUserID + "/dashboard"),
        },
      ]}
    >
      <Card>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
          <Card.Section>
            <Card>
              <DataTable
                columnContentTypes={["text", "text", "text", "text"]}
                headings={[
                  <TextStyle variation="strong">ID</TextStyle>,
                  <TextStyle variation="strong">Amount Due</TextStyle>,
                  <TextStyle variation="strong">Due Date</TextStyle>,
                  <TextStyle variation="strong">
                    {selectedTab === 0 ? "Days Overdue" : "Due In"}
                  </TextStyle>,
                ]}
                rows={items}
                sortable={[false, false, false, false, false, false, false]}
                defaultSortDirection="descending"
                verticalAlign="center"
              />

              {emtyState}
            </Card>
          </Card.Section>
        </Tabs>
      </Card>
      {toastMarkup}
      {/* {arrayOverdues && <h1>{arrayOverdues}</h1>} */}
    </Page>
  );

  // function handleImpersonateButtonClick(e) {
  //   boolImpersonateClicked = true;
  //   axios
  //     .get("/admin/v1/users/" + e.currentTarget.id + "/impersonate", {
  //       headers: {
  //         Authorization: "Bearer " + Cookie.get("token"),
  //       },
  //     })
  //     .then((res) => {
  //       boolImpersonateClicked = false;
  //       //toggleActive();
  //       openInNewTab("https://www.wearebasma.com/ar");
  //     })
  //     .catch((err) => console.log(err), (boolImpersonateClicked = false));
  // }
  // function openInNewTab(url) {
  //   var win = window.open(url, "_blank");
  //   win.focus();
  // }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  function handleOnItemClick(e) {
    if (!boolImpersonateClicked) {
      const currentID = e.currentTarget.id;

      Cookie.set("userID", currentID);
      //  Cookie.set('userName',findArrayElementById(arrayOfObjects, currentID)[1])
      history.push("/admin/users/" + currentID + "/dashboard");
    }
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element[0]) === parseInt(id);
    });
  }
}
