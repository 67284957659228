import { Button, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Save from "./Save";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import Scans from "./Scans";
import { caseRecordTypes } from "../util/constants";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: "2rem",
      boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    box: {
      display: "flex",
      flexWrap: "wrap",
      columnGap: 10
    },
    titleStyle: {
      height: "16px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2rem',
    },
    title: {
      fontSize: 14,
      marginBottom: 5,
    },
    date: {
      fontSize: 13,
      marginTop: 5,
    },
    saveWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      marginTop: 10,
      ["@media only screen and (max-width: 500px) "]: {
          flex: "33%",
      },
    },
    btn: {
      display: 'block',
      border: "1px solid #C4CDD5",
      borderRadius: "3px",
      background: "linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)",
      boxShadow: "0 1px 0 0 rgba(22,29,37,0.05)",
    },
    reviewBoldTxt: {
      fontWeight: 'bold',
    },
    size11: {
        fontSize: '11px',
    },
  };
});

export default function AppointmentScans({
  customStyle = {},
  userId,
  disable = false,
  handleTabsExpand = () => { },
  index = 0,
  appointmentId = null,
  appointmentNumber,
  appointmentType = "scans",
  openCloseTabs,
  scrollToExpandedTab,
  updateReviewStatus,
  delayedActionJob,
}) {
  const classes = useStyles();
  const [iconScan, setIconScan] = useState(null);
  const [scans, setScans] = useState(null);
  const [hasDelayedAction, setHasDelayedAction] = useState(false);
  const [scannerPlaceholders, setScannerPlaceHolders] = useState(4);
  const [segmentationTechnicians, setSegmentationTechnicians] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState({
    disabled: false,
    popupActive: false,
    success: false,
    disableReviewBtn: false,
    download: false,
  });

  const downloadScans = () => {
    setLoading(prev => ({
      ...prev,
      download: true,
    }));
    axios.get(`/admin/v2/users/${userId}/download-scans/${scans?.id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      var win = window.open(
        `${process.env.REACT_APP_BASE_URL}/storage${result.data.data}`,
        "_blank"
      );
      win.focus();
      if(!result.data.success){
        toast.error('No scans found to download', 3000);
      }
    }).catch((err) => {
      console.log('err', err);
    }).finally(f => {
      setLoading(prev => ({
        ...prev,
        download: false,
      }));
    });
  }

  const handleDataFetched = () => {
    setLoading(prev => ({
      ...prev,
      popupActive: true,
    }));
    axios.get(`admin/v2/users/case-record-scans/${appointmentId}?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      let empty = true;
      if (res.data.success) {
        if(!res.data.data.review_status && loading.disableReviewBtn){
          setLoading(prev => ({
            ...prev,
            disableReviewBtn: false,
          }));
        }
        setSegmentationTechnicians(res.data.segmentationTechnicians);
        setScans({
          ...res.data.data,
        });
        setHasDelayedAction(res.data.has_delayed_action);
        setScannerPlaceHolders(res.data.scanner_placeholders);
        setIconScan(res.data.isAllProvided);
        empty = res.data.isAllProvided === 0 || !res.data?.data?.scan_files?.length;
      } else {
        setIconScan(0);
      }
      if(empty){
        setLoading(prev => ({
          ...prev,
          download: true,
        }));
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setLoading(prev => ({
        ...prev,
        popupActive: false,
      }));
    })
  };

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if(delayedActionJob.refetchedRecord === `${caseRecordTypes.UserCaseRecordScan}-${scans?.id}`) {
      handleDataFetched();
    }
  }, [delayedActionJob]);

  useEffect(() => {
    scrollToExpandedTab(index, "scans");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='scans'
      headerTitle={<p><span className="procedure-header-title">Scans:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={iconScan}
    >
      {openCloseTabs[index]?.scans && (
        <Box
          style={customStyle}
          className={`${classes.wrapper} scans-${index}`}
        >
          {
            loading.popupActive ?
              <div style={{ textAlign: 'center' }}>
                <CircularProgress color='primary' />
              </div> :
              <>
                <ToastContainer/>
                <Box className={classes.titleBox}>
                  <Typography className={classes.titleStyle}>Upload Scans</Typography>
                  <Button disabled={loading.download} onClick={downloadScans}>Download Scans</Button>
                </Box>
                <Scans
                  scans={scans}
                  appointmentType={appointmentType}
                  disable={disable}
                  loading={loading}
                  setLoading={setLoading}
                  segmentationTechnicians={segmentationTechnicians}
                  appointmentId={appointmentId}
                  handleDataFetched={handleDataFetched}
                  userId={userId}
                  classes={classes}
                  setErrorMsg={setErrorMsg}
                  scannerPlaceholders={scannerPlaceholders}
                  updateReviewStatus={updateReviewStatus}
                  delayedActionJob={delayedActionJob}
                  appointmentNumber={appointmentNumber}
                  parentId={scans?.id}
                  hasDelayedAction={hasDelayedAction}
                />
                <Save uploadedBy={''} success={loading.success} errorMessage={errorMsg} />
              </>
          }
        </Box>
      )}
      
    </ProcedureHeader>
  );
}
