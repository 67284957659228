import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  makeStyles,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import { Card, FormLayout, TextStyle } from "@shopify/polaris";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Review from "./Review";
import Save from "./Save";
import { FormControl, MenuItem, Select } from "@mui/material";
import { adminActions, caseRecordTypes } from "../util/constants";

const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: '2rem',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    },
    procedureOptionsWrapper: {
      padding: '1rem',
      marginTop: 10,
    },
    saveBtn: {
      display: 'block',
      marginTop: '1rem',
      marginLeft: 'auto',
      borderRadius: '4.7px',
      color: '#fff',
      backgroundColor: '#FF2C46',
      '&:hover': {
        backgroundColor: '#FF2C46',
      },
    },
    btn: {
      marginLeft: 'auto',
      borderRadius: '4.7px',
      color: '#fff',
      backgroundColor: '#FF2C46',
      '&:hover': {
        backgroundColor: '#FF2C46',
      },
    },
    procWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    add: {
      backgroundColor: '#8EE2ED',
      color: '#fff',
    },
    errorMsg: {
      margin: '1rem 0',
      color: 'red',
    },
    loadingBox: {
      margin: '0 auto',
      textAlign: 'center',
      padding: 30,
      color: '#FF2C46',
    },
    loading: {
      textAlign: 'center',
      margin: '0 auto',
      color: '#ff2c46',
    },
    textarea: {
      width: '100%',
      border: 'none',
      resize: 'none',
      backgroundColor: 'rgba(211,215,219,0.25)',
      color: '#939393',
      padding: '0.8rem',
      '&:focus': {
        outline: 'none',
      },
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '0.5rem',
    },
    fullWidth: {
      width: '100%',
    },
    dateSelectoreWidth: {
      width: '100%',
    },
    flex: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      color: '#363636',
      marginBottom: 10,
      fontSize: '13px',
    },
    label: {
      color: '#363636',
      fontSize: '13px',
    },
    bigTitle: {
      fontSize: '16px'
    }
  };
});

export default function AppointmentInformationMallCollab({
  userId,
  appointmentId,
  appointmentType,
  handleTabsExpand,
  index,
  openCloseTabs,
  setDisable,
  scrollToExpandedTab,
  updateReviewStatus,
  delayedActionJob,
}) {
  const classes = useStyles();
  const [information, setInformation] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [viewProceduresOptions, setViewProcedureOptions] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    problem_question: '',
    unqualifiedReasonDetailsRequiredMsg: '',
  });
  const procedures = {
    CLEANING : 'Cleaning',
  };
  const options = [
    { label: 'Qualified Top', value: 2 },
    { label: 'Qualified Bottom', value: 3 },
    { label: 'Qualified Both', value: 1 },
    { label: 'Not Qualified', value: 0 },
  ];
  const reasonOptions = [
    {label: 'Lead Stage - Underage', value: 'Lead Stage - Underage'},
    {label: 'Lead Stage - Difficult Case', value: 'Lead Stage - Difficult Case' },
    {label: 'Lead Stage - Unhealthy Teeth/Gums', value: 'Lead Stage - Unhealthy Teeth/Gums' },
    {label: 'Lead Stage - Country Not Covered', value: 'Lead Stage - Country Not Covered' },
    {label: 'Data Stage - Difficult Case', value: 'Data Stage - Difficult Casee'},
    {label: 'Data Stage - Incomplete Info For TP', value: 'Data Stage - Incomplete Info For TP'},
    {label: 'TP Stage - Difficult Case', value: 'TP Stage - Difficult Case'},
    {label: 'TP Stage - Incomplete Info For TP', value: 'TP Stage - Incomplete Info For TP'},
  ];

  const handleChange = (value, type) => {
    if (type === 'problem_question') {
      setErrorMsg((prev) => ({
        ...prev,
        [type]: '',
      }));
    }
    setSuccess(false);
    setData((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [type]: value,
      }
    }));
  };

  const hanldeBookProceduresArray = (value) => {
    if(data?.procedures_array && data?.procedures_array.includes(value)){
      let array = data?.procedures_array.filter((item)=> item != value);
      setData(prev => ({
        ...prev,
        procedures_array: array,
      }));
    }else{
      setData((prev) => ({
        ...prev,
        procedures_array: [...prev?.procedures_array, value],
      }));
    }
  }

  const handleSave = () => {
    let errMsg = {
      problem_question: '',
      unqualifiedReasonDetailsRequiredMsg: '',
    };
    setErrorMsg(errMsg);
    if (data?.mandatory && !data?.data?.problem_question) {
      errMsg = {
        ...errMsg,
        problem_question: 'Required field',
      };
      setErrorMsg(errMsg);
    } else {
      setLoading(true);
      let body = {
        ...data.data,
        procedure: appointmentType?.procedure,
        user_scan_info_id: appointmentId,
        procedures_array: data?.procedures_array,
      };
      if (body) {
        axios.post(`admin/v2/users/${userId}/case-record-information`, body, {
          headers: {
            Authorization: 'Bearer ' + Cookies.get('token'),
          },
        }).then((res) => {
          if (res.data.success) {
            handleDataFetched();
            setSuccess(true);
          }
        }).catch((err) => {
          setSuccess(false);
          console.log(err);
        }).finally(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  };

  const handleReviewStatus = (status) => {
    if(status) {
      let data = {
        review_status: status,
        appointment_id: appointmentId,
        procedure: appointmentType?.procedure,
        record_id: information?.data?.id,
        record_type: caseRecordTypes.UserCaseRecordInformation,
      };
      let body = {
        action: adminActions.caseRecordsStatus,
        data,
      }
      updateReviewStatus(body, 'Status Updated', `${caseRecordTypes.UserCaseRecordInformation}-${information?.data?.id}`);
    }
  };

  const handleDataFetched = () => {
    setLoading(true);
    axios.get(`admin/v2/users/case-record-information/${appointmentId}?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        setInformation(res.data);
        setDisable(res.data.patient_data_locked);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setLoading(false);
    })
  };

  const handleChangeData = (value) => {
    setViewProcedureOptions(value?.procedures_array?.length > 0);
    if(value?.data){
      setData({
        ...value,
        data: {
          ...value.data,
          dental_history_na: value.data?.dental_history_na === 1,
        }
      });
    }
  };

  function handleSaveTreatmentQualificationStatus() {
    setLoading(true);
    setErrorMsg(prev => ({
      ...prev,
      unqualifiedReasonDetails: '',
    }));
    const bodyObj = {
      procedure: appointmentType?.procedure,
      user_scan_info_id: appointmentId,
      is_qualified: data?.data?.is_qualified,
      ...(data?.data?.unqualified_reason_details && {
        unqualified_reason_details: data?.data?.unqualified_reason_details,
      }),
      ...(data?.data?.unqualified_reason && { unqualified_reason: data?.data?.unqualified_reason }),
    };
    if (data?.data?.is_qualified !== null) {
      axios.post(`admin/v2/users/${userId}/case-record-information/qualified`, bodyObj, {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('token'),
        },
      }).then((res) => {
        setLoading(false);
        if (res.data?.success && res.data.success == 'false') {
          setErrorMsg(prev => ({
            ...prev,
            unqualifiedReasonDetails: 'Unqualified reason details are required',
          }));
        }
      }).catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if(delayedActionJob.refetchedRecord === `${caseRecordTypes.UserCaseRecordInformation}-${information?.data?.id}`) {
      handleDataFetched();
    }
  }, [delayedActionJob]);

  useEffect(() => {
    if(information){
      handleChangeData(information);
    }
  }, [information]);

  useEffect(() => {
    scrollToExpandedTab(index, 'information');
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='information'
      headerTitle={<p><span className='procedure-header-title'>Information:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={data?.isAllProvided}
    >
      {openCloseTabs[index]?.information && (
        <Box className={`${classes.photoWrapper} information-${index} info`}>
          {loading ? (
            <Box className={classes.loadingBox}>
              <CircularProgress className={classes.loading} />
            </Box>
          ) : (
            <>
              <Box style={{ marginBottom: '1rem' }}>
                <Typography className={classes.title}>Chief complaint</Typography>
                <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                  <TextareaAutosize
                    placeholder='Add comment...'
                    className={classes.textarea}
                    style={{ height: '6rem' }}
                    value={data?.data?.problem_question ? data?.data?.problem_question : ''}
                    onChange={(e) =>
                      handleChange(e.target.value, 'problem_question')
                    }
                  />
                </Box>
              </Box>
              {errorMsg?.problem_question && (
                <Typography className={classes.errorMsg}>
                  {errorMsg.problem_question}
                </Typography>
              )}

              <Review
                disabled={information?.has_delayed_action || delayedActionJob.disabledRecord == `${caseRecordTypes.UserCaseRecordInformation}-${information?.data?.id}` || data?.isAllProvided == 0 || data?.data?.review_status != null}
                reviewStatus={data?.data?.review_status}
                reviewer={data?.data?.reviewer}
                reviewedAt={data?.data?.reviewed_at}
                handleChange={() => handleReviewStatus('Reviewed')}
              />

              <Card>
                <div className={classes.procedureOptionsWrapper}>
                  <Button className={classes.btn} onClick={() => setViewProcedureOptions((prev) => !prev)}>
                    {viewProceduresOptions ? 'Hide' : 'Show'} Procedures Options
                  </Button>
                  {viewProceduresOptions &&
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox
                          checked={data?.procedures_array ? data?.procedures_array?.includes(procedures.CLEANING) : false}
                          onChange={() => hanldeBookProceduresArray(procedures.CLEANING)}
                        />
                      } label={procedures.CLEANING} />
                    </FormGroup>
                  }
                  </div>
              </Card>

              <Save uploadedBy={data?.isAllProvided != 0 ? data?.data?.owner_name : ''} handleSave={handleSave} success={success} />

              <Card sectioned>
                <FormLayout>
                  <Typography className={classes.bigTitle}>Treatment Qualification Status</Typography>
                  <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                    <Typography className={classes.title}>Please Choose</Typography>
                    <Select
                      onChange={(e) => handleChange(e.target.value, 'is_qualified')}
                      value={(data?.data?.is_qualified !== null && data?.data?.is_qualified !== undefined) ? data?.data?.is_qualified : ""}
                    >
                      {options.map((option, key) => (
                        <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormLayout>
                {data?.data?.is_qualified === 0 && 
                  <Card sectioned>
                    <FormLayout>
                      <Typography className={classes.bigTitle}>Discontinued reasons</Typography>
                      <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                        <Typography className={classes.title}>Please Choose</Typography>
                        <Select
                          onChange={(e) => handleChange(e.target.value, 'unqualified_reason')}
                          value={(data?.data?.unqualified_reason !== null && data?.data?.unqualified_reason !== undefined) ? data?.data?.unqualified_reason : ""}
                        >
                          {reasonOptions.map((option, key) => (
                            <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormLayout>
                    <br />
                    <Box style={{ marginBottom: '1rem' }}>
                      <Typography className={classes.title}>Unqualified reason details</Typography>
                      <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                        <TextareaAutosize
                          className={classes.textarea}
                          style={{ height: '6rem' }}
                          value={data?.data?.unqualified_reason_details ? data?.data?.unqualified_reason_details : ''}
                          onChange={(e) => handleChange(e.target.value, 'unqualified_reason_details')}
                        />
                        <TextStyle variation='negative'>{errorMsg.unqualifiedReasonDetailsRequiredMsg}</TextStyle>
                      </Box>
                    </Box>
                  </Card>
                }
                <Button
                  className={classes.saveBtn}
                  onClick={handleSaveTreatmentQualificationStatus}
                >
                  Save Treatment Qualification Status
                </Button>
              </Card>
            </>
          )}
        </Box>
      )}
    </ProcedureHeader>
  );
}
