import React, { useEffect, useState } from "react";
import ReplayIcon from "@mui/icons-material/Replay";
import axios from "../axios";
import Cookies from "js-cookie";

const UndoAction = ({ job, setJob }) => {
    const [counter, setCounter] = useState(30);

  const undo = () => {
    let body = {
      id: job.id,
    };
    axios.post(`admin/v2/users/undo-delayed-action`, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
    }).then(() => {
        setJob(prev => ({
          ...prev,
          id: null,
          disabledRecord: null,
          refetchedRecord: null,
          isRefreshEnabled: false,
        }));
    }).catch((err) => {
        console.log(err);
    });
  };

  useEffect(() => {
    let counter = 30;
    const interval = setInterval(() => {
      setCounter((prev) => prev - 1);
      counter--;
      if (counter === 0) {
        setJob(prev => ({
            ...prev,
            id: null,
            isRefreshEnabled: true,
        }));
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="undo-action-container">
      <p className="undo-action-title">{job.title}</p>
      <div className="undo-action-icon-container" onClick={undo}>
        <p>{counter}</p>
        <ReplayIcon color="#fff" />
        <p className="undo-action">Undo</p>
      </div>
    </div>
  );
};

export default UndoAction;
