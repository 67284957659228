import { Page } from "@shopify/polaris";
import React, { useState } from "react";
import ListOffersTable from "./list-offers-table.component";
import Cookies from "js-cookie";
import axios from "../axios";
import { capitalizeWords } from "../util/helpers";

function ListOffers() {
  const [offers, setOffers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [loading, setLoading] = useState(false);

  const getOffers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/admin/v1/offers`, {
        params: {
          page,
          perPage,
        },
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      if (response.status === 200) {
        const { data } = response?.data;
        if (data?.data) {
          setOffers(
            data?.data.map((offer) => {
              let productName = "";
              if (offer.product) {
                const title = JSON.parse(offer.product.title).en;
                productName = capitalizeWords(title);
              }
              let countries = "";
              if (offer.countries) {
                countries = offer.countries
                  .map((country) => capitalizeWords(country.name.en))
                  .join(", ");
              }
              return [
                offer.title,
                productName,
                offer.quantity,
                offer.start_date,
                offer.end_date,
                countries,
                offer.is_first ? "Yes" : "No",
              ];
            }),
          );
        }
        setTotal(data.total);
        setPerPage(data.per_page);
      }
    } catch (error) {
      console.log("Error in offers api", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      fullWidth
      title={"Offers List"}
      primaryAction={{ content: "Add Offer", url: "/admin/offers/add" }}
    >
      <ListOffersTable
        getOffers={getOffers}
        loading={loading}
        offers={offers}
        total={total}
        setPage={setPage}
        page={page}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </Page>
  );
}

export default ListOffers;
