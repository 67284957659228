import React from "react";

const RefreshComponent = ({ refresh }) => {
  return (
    <div className="refresh-component-container">
      The page was updated, kindly{" "}
      <span className="refresh-component-refresh-btn" onClick={refresh}>
        refresh
      </span>{" "}
      to see the changes.
    </div>
  );
};

export default RefreshComponent;
