import React, { useState } from "react";
import {
    Box,
    LinearProgress,
    makeStyles,
    Typography,
} from "@material-ui/core";
import SinglePhoto from "./SinglePhoto";
import { Checkbox } from "@shopify/polaris";
import DeleteBtn from "./DeleteBtn";

const useStyles = makeStyles(() => {
    return {
        photoWrapper: {
            padding: "1rem",
            boxShadow:
                "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
        },
        photoCheckWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        checkBoxWrapper: {
            transform: "scale(0.7)",
        },
        photoBox: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
        },
        photoWidthSM: {
            width: "60%",
            ["@media only screen and (max-width: 700px) "]: {
                width: "100%",
            },
        },
        photoWidth: {
            width: "70%",
            marginBottom: 15,
            ["@media only screen and (max-width: 700px) "]: {
                width: "100%",
            },
        },
        singlePhotoBox: {
            display: "flex",
            width: "70.19px",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "1rem",
            marginBottom: "10px",
            ["@media only screen and (max-width: 500px) "]: {
                flex: "33%",
            },
        },
        title: {
            height: "16px",
            width: "134px",
            color: "#303030",
            fontSize: "16px !important",
            letterSpacing: 0,
            lineHeight: "22px",
            marginBottom: "1rem",
        },
        imageTitle: {
            height: "33.6px",
            width: "73px",
            color: "#212B36",
            fontSize: "11px !important",
            letterSpacing: 0,
            lineHeight: "16.8px",
            textAlign: "center",
        },
        loading: {
            textAlign: "center",
            margin: "0 auto",
            color: "#ff2c46",
        },
    };
});

const ScreeningPhotoUpload = ({
    idx = null,
    disabled,
    value,
    fileType = '',
    handleImages,
    id,
    title,
    uploadProgress,
    checked = null,
    handleChangeNotApplicable = () => {},
    type = null,
    deleteDisabled,
    handleDelete,
    imagesArray,
    identifier,
    fileName = null,
    recordId = null,
}) => { const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const handleIndexChange = (id) => {
        let ind = imagesArray.findIndex(img => img?.id == id);
        setIndex(ind);
        setIsOpen(true);
    }

    return (
        <div className={classes.photoCheckWrapper}>
            <Box className={classes.singlePhotoBox}>
                <SinglePhoto
                    disable={disabled}
                    value={value}
                    fileType={fileType}
                    idx={idx}
                    handleImages={handleImages}
                    id={id}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    index={index}
                    handleIndexChange={handleIndexChange}
                    imagesArray={imagesArray}
                    identifier={identifier}
                    fileName={fileName}
                    recordId={recordId}
                />
                {title && <Typography className={classes.imageTitle}>{title}</Typography>}
                {uploadProgress && (
                    <Box>
                        <LinearProgress
                            variant="determinate"
                            value={uploadProgress}
                        />
                    </Box>
                )}
            </Box>
            {type && <div className={classes.checkBoxWrapper}>
                <Checkbox
                    label='Not available'
                    checked={checked}
                    disabled={disabled}
                    onChange={(e) => handleChangeNotApplicable(e, type)}
                />
            </div>}
            <DeleteBtn disabled={deleteDisabled} handleDelete={handleDelete} />
        </div>
    )
}

export default ScreeningPhotoUpload