import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useStyles } from './edit-delivery-batches-table.styles';
import AlertRemoveBatchDialog from './alert-remove-batch-dialog.component';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DeliveryBatchRow from './delivery-batch-row.component';
import moment from 'moment';
import { DisplayText, Image } from '@shopify/polaris';
import emptyIcon from '../../img/emptyList.svg';
import {
  getProductionDays,
  checkValidRange,
  disableStepsAccordingToBatchType,
  findNextBatch,
  sortBatches,
} from '../../util/helpers';
import { DispatchesSubTypes, DispatchTypeCodes } from '../../util/constants';
import AlertSplitBatchDialog from './alert-split-batch-dialog.component';
import { toast } from 'react-toastify';

const EditDeliveryBatchesTable = ({
  batches,
  treatmentStages,
  cuttingTechnicians,
  laserCutlineTechnicians,
  subTypes,
  productTypes,
  formRows,
  setFormRows,
  formatBatches,
  validateSteps,
  validateToSteps,
  colorMapping,
  colors,
  colorIndex,
  setColorIndex,
  setSplittingMode,
  updateBatch,
  setLinkingWarning,
  setUnLinkingWarning,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openSplitDialog, setOpenSplitDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleDragEnd = (result) => {
    let { destination, source, combine } = result;
    let updatedItems = Array.from(formRows);
    if (destination && destination.index === 0) return;
    if (!destination && combine === null) return;
    if (
      combine === null &&
      updatedItems[source.index].type === DispatchTypeCodes['aligners'] &&
      updatedItems[source.index].subType === DispatchesSubTypes['standard']
    )
      return;
    if (combine) {
      destination = {};
      destination.index = parseInt(combine.draggableId);
    }
    if (
      combine &&
      updatedItems[source.index].type === DispatchTypeCodes['aligners'] &&
      updatedItems[source.index].subType === DispatchesSubTypes['standard'] &&
      destination.index === 0
    )
      return;
    // if the dispatch has an actual dispatch date don't allow the dragging
    if (updatedItems[destination.index]?.actualDispatchDate !== '-') {
      toast.warning('Cannot move a dispatched batch', 3000);
      return;
    }
    const parentBatch = updatedItems[destination.index];
    const [movedItem] = updatedItems.splice(source.index, 1);
    // if it is a combine action, change the delivery value to the destination value and added it after the destination index
    if (combine) {
      if (
        movedItem.type === DispatchTypeCodes['aligners'] &&
        movedItem.subType === DispatchesSubTypes['standard']
      ) {
        // Only merge it if it is not the first batch of each delivery
        // and only is the precedent one
        // and the same treatment stage
        if (
          movedItem.number !== "-" &&
          (movedItem.treatmentStage !== parentBatch.treatmentStage ||
            parentBatch.number === 1 ||
            movedItem.number - 1 !== parentBatch.number)
        ) {
          let message = null;
          if (movedItem.treatmentStage !== parentBatch.treatmentStage) {
            message = "The treatment stages are different";
          } else if (parentBatch.number === 1) {
            message = "The batch is the first of the delivery";
          } else if (movedItem.number - 1 !== parentBatch.number) {
            message = "Cannot merge precedent batch with the next one";
          }
          if (message) {
            toast.warning(message, 3000);
          }
          return;
        }
        const lowerStepsFrom = Math.min(
          parentBatch.lowerStepsFrom,
          movedItem.lowerStepsFrom,
        );
        const lowerStepsTo = Math.max(
          parentBatch.lowerStepsTo,
          movedItem.lowerStepsTo,
        );
        const upperStepsFrom = Math.min(
          parentBatch.upperStepsFrom,
          movedItem.upperStepsFrom,
        );
        const upperStepsTo = Math.max(
          parentBatch.upperStepsTo,
          movedItem.upperStepsTo,
        );
        parentBatch.lowerStepsFrom = lowerStepsFrom;
        parentBatch.lowerStepsTo = lowerStepsTo;
        parentBatch.upperStepsFrom = upperStepsFrom;
        parentBatch.upperStepsTo = upperStepsTo;
        updatedItems.map((item) => {
          if (item.parentId === movedItem.batchId) {
            item.parentId = null;
          }
          return item;
        });
      } else {
        // Check if the movedItem is a shop product if yes check the link\unlink warning
        if (movedItem.isShopProduct && !parentBatch.isShopProduct) {
          if (movedItem.parentId == null) {
            setLinkingWarning(true);
            setUnLinkingWarning(false);
          } else {
            if (movedItem.parentId !== parentBatch.batchId && !parentBatch.isShopProduct) {
              setLinkingWarning(true);
              setUnLinkingWarning(false);
            } else {
              setUnLinkingWarning(false);
              setLinkingWarning(false);
            }
          }
        } else {
          setUnLinkingWarning(false);
          setLinkingWarning(false);
        }
        if (movedItem.oldParentId === null) {
          movedItem.oldParentId = movedItem.parentId;
        }
        // Attaching the moved item to the parent
        movedItem.parentId = parentBatch.batchId;
        movedItem.color = parentBatch.color;
        movedItem.targetDispatchDate = parentBatch.targetDispatchDate;
        // Update children if they exist to the new parent
        updatedItems = updatedItems.map((batch) => {
          if (batch.parentId === movedItem.batchId) {
            if (batch.oldParentId === null) {
              batch.oldParentId = movedItem.oldParentId;
            }
            batch.parentId = parentBatch.batchId;
            batch.color = parentBatch.color;
            batch.targetDispatchDate = parentBatch.targetDispatchDate;
          }
          return batch;
        });
        const newParentIndex = updatedItems.findIndex(
          (item) => item.batchId === movedItem.parentId
        );
        if (newParentIndex !== -1) {
          updatedItems.splice(newParentIndex + 1, 0, movedItem);
        }
      }
    } else {
      if (movedItem.parentId) {
        if (movedItem.isShopProduct) {
          const getParentBatch = updatedItems.find(
            (batch) => batch.batchId === movedItem.parentId,
          );
          if (getParentBatch && !getParentBatch.isShopProduct) {
            if (movedItem.oldParentId) {
              const getOriginalParent = updatedItems.find(
                (batch) => batch.batchId === movedItem.oldParentId,
              );
              if (getOriginalParent && !getOriginalParent.isShopProduct) {
                setUnLinkingWarning(true);
                setLinkingWarning(false);
              } else {
                setUnLinkingWarning(false);
                setLinkingWarning(false);
              }
            } else {
              setUnLinkingWarning(true);
              setLinkingWarning(false);
            }
          } else {
            setUnLinkingWarning(false);
            setLinkingWarning(false);
          }
        }
        movedItem.parentId = null;
      }
      // AutoCalculate date on disband
      if (disableStepsAccordingToBatchType(movedItem.type)) {
        const days = getProductionDays(movedItem.type, productTypes);
        movedItem.targetDispatchDate = moment(
          formRows[destination.index].targetDispatchDate
        )
          .add(
            days,
            'days'
          )
          .format('YYYY-MM-DD');
      }
      updatedItems.splice(destination.index, 0, movedItem);
    }
    // Recalculate the delivery number and change it only for non combined batches
    let counter = 1;
    updatedItems = updatedItems.map((item) => {
      if (item.parentId === null) {
        let calculateColor;
        if (colorMapping[counter] !== undefined) {
          calculateColor = colorMapping[counter];
        } else {
          calculateColor = colors[colorIndex];
          setColorIndex((prev) => (prev + 1) % colors.length);
        }
        return {
          ...item,
          color: calculateColor,
          delivery: counter++,
        };
      }
      return item;
    });

    updatedItems = updatedItems.map((item) => {
      if (item.parentId) {
        const parent = updatedItems.find(
          (value) => value.batchId === item.parentId,
        );
        if (parent) {
          return {
            ...item,
            color: parent.color,
            delivery: parent.delivery,
          };
        }
      }
      return item;
    });

    updatedItems.sort(sortBatches);
    setFormRows(updatedItems);
  };

  const handleClickOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleClickSplitDialogOpen = (index) => {
    setSelectedIndex(index);
    setOpenSplitDialog(true);
  };

  const handleRowInputChange = (index, field, value) => {
    if (field === 'targetDispatchDate') {
      // Don't allow the user to put the after the previous expected start of last step in batch
      const currentDate = moment(value);
      if (
        formRows[index - 1] &&
        currentDate.isAfter(
          moment(formRows[index - 1]?.expectedStartOfLastStepInBatch)
        ) &&
        formRows[index].type === DispatchTypeCodes['aligners']
      ) {
        return formRows;
      }
    } else if (field === 'type') {
      return setFormRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[index] = {
          ...newRows[index],
          [field]: value,
          subType: '-',
          targetDispatchDate: '',
        };
        return newRows.sort(sortBatches);
      });
    } else if (field === 'subType') {
      return setFormRows((prevRows) => {
        const newRows = [...prevRows];
        let calculatedDate = '';
        if (
          disableStepsAccordingToBatchType(newRows[index].type) ||
          (newRows[index].type === DispatchTypeCodes.aligners &&
            value !== DispatchesSubTypes.standard)
        ) {
          if (newRows[index].parentId) {
            const parentIndex = newRows.findIndex(
              (item) => item.batchId === newRows[index].parentId
            );
            if (parentIndex !== -1) {
              calculatedDate = moment(
                newRows[parentIndex].targetDispatchDate
              ).format('YYYY-MM-DD');
            }
          } else {
            const days = getProductionDays(newRows[index].type, productTypes);
            calculatedDate = moment()
              .add(
                days,
                'days'
              )
              .format('YYYY-MM-DD');
          }
        }
        newRows[index] = {
          ...newRows[index],
          [field]: value,
          lowerStepsFrom: 0,
          lowerStepsTo: 0,
          lowerSteps: 0,
          upperStepsFrom: 0,
          upperStepsTo: 0,
          upperSteps: 0,
          upperPlasticOptions: '-',
          lowerPlasticOptions: '-',
          targetDispatchDate: calculatedDate,
          upperProductQuantity: 0,
          lowerProductQuantity: 0,
        };
        return newRows.sort(sortBatches);
      });
    } else if (field === 'lowerStepsTo' || field === 'upperStepsTo') {
      return setFormRows((prevRows) => {
        const newRows = [...prevRows];
        const nextBatchIndex = findNextBatch(
          index,
          DispatchTypeCodes['aligners'],
          newRows
        );
        if (nextBatchIndex === -1) {
          return newRows;
        }
        if (
          field === 'lowerStepsTo' &&
          !(value > newRows[index].lowerMaxSteps)
        ) {
          if (typeof newRows[nextBatchIndex] !== 'undefined') {
            let newLowerStepsFrom = parseInt(value) + 1;
            if (
              checkValidRange(
                newLowerStepsFrom,
                newRows[nextBatchIndex].lowerStepsTo
              )
            ) {
              newRows[nextBatchIndex] = {
                ...newRows[nextBatchIndex],
                lowerStepsFrom: newLowerStepsFrom,
              };
              newRows[index] = {
                ...newRows[index],
                [field]: value,
              };
            }
          } else {
            newRows[index] = {
              ...newRows[index],
              [field]: value,
              upperStepsTo: value,
            };
          }
        } else if (
          field === 'upperStepsTo' &&
          !(value > newRows[index].upperMaxSteps)
        ) {
          if (typeof newRows[nextBatchIndex] !== 'undefined') {
            let newUpperStepsFrom = parseInt(value) + 1;
            if (
              checkValidRange(
                newUpperStepsFrom,
                newRows[nextBatchIndex].upperStepsTo
              )
            ) {
              newRows[nextBatchIndex] = {
                ...newRows[nextBatchIndex],
                upperStepsFrom: newUpperStepsFrom,
              };
              newRows[index] = {
                ...newRows[index],
                [field]: value,
              };
            }
          } else {
            newRows[index] = {
              ...newRows[index],
              [field]: value,
              lowerStepsTo: value,
            };
          }
        }
        return newRows;
      });
    } else if (
      field === 'lowerProductQuantity' ||
      field === 'upperProductQuantity'
    ) {
      return setFormRows((prevRows) => {
        const newRows = [...prevRows];
        if (
          field === 'lowerProductQuantity' &&
          newRows[index].lowerPlasticOptions === 1
        ) {
          newRows[index] = {
            ...newRows[index],
            lowerProductQuantity: 0,
          };
        } else if (
          field === 'upperProductQuantity' &&
          newRows[index].upperPlasticOptions === 1
        ) {
          newRows[index] = {
            ...newRows[index],
            upperProductQuantity: 0,
          };
        } else {
          newRows[index] = {
            ...newRows[index],
            [field]: value,
          };
        }
        return newRows;
      });
    } else if (
      field === 'lowerPlasticOptions' ||
      field === 'upperPlasticOptions'
    ) {
      return setFormRows((prevRows) => {
        const newRows = [...prevRows];
        if (field === 'lowerPlasticOptions' && value === 1) {
          newRows[index] = {
            ...newRows[index],
            lowerProductQuantity: 0,
            [field]: value,
          };
        } else if (field === 'upperPlasticOptions' && value === 1) {
          newRows[index] = {
            ...newRows[index],
            upperProductQuantity: 0,
            [field]: value,
          };
        } else {
          newRows[index] = {
            ...newRows[index],
            [field]: value,
          };
        }
        return newRows;
      });
    }
    return setFormRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[index] = {
        ...newRows[index],
        [field]: value,
      };
      return newRows;
    });
  };

  useEffect(() => {
    formatBatches(batches);
  }, []);

  const columnWidth = '100px';

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.header}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Sub Type</TableCell>
              <TableCell>Treatment Stages</TableCell>
              <TableCell>Batch</TableCell>
              <TableCell>Delivery</TableCell>
              <TableCell>Upper Steps</TableCell>
              <TableCell>Lower Steps</TableCell>
              <TableCell>Upper Quantity</TableCell>
              <TableCell>Lower Quantity</TableCell>
              <TableCell>Upper Plastic</TableCell>
              <TableCell>Lower Plastic</TableCell>
              <TableCell>Cutting Technicians</TableCell>
              <TableCell>Laser Cutline Technicians</TableCell>
              <TableCell>Production Type</TableCell>
              <TableCell>Pickup From Clinic</TableCell>
              <TableCell>Pause</TableCell>
              <TableCell>Is Extra</TableCell>
              <TableCell>Quality Control</TableCell>
              <TableCell>Production Start Date</TableCell>
              <TableCell>Production End Date</TableCell>
              <TableCell>Trello</TableCell>
              <TableCell>Target Dispatch Date</TableCell>
              <TableCell>Upper Product Quantity</TableCell>
              <TableCell>Lower Product Quantity</TableCell>
              <TableCell>Batch Notes</TableCell>
              <TableCell>Actual Dispatch Date</TableCell>
              <TableCell>Expected Receipt Date</TableCell>
              <TableCell>Actual Receipt Date</TableCell>
              <TableCell>Expected Start of Batch</TableCell>
              <TableCell>
                <Box width={columnWidth}>
                  Expected Start of Last Step in Batch
                </Box>
              </TableCell>
              <TableCell>
                <Box width={columnWidth}>Expected Last Payment Date</Box>
              </TableCell>
              <TableCell>
                <Box width={columnWidth}>Actual Last Payment Date</Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="table" type="ROW" isCombineEnabled>
            {(provided, snapshot) => (
              <TableBody
                style={{
                  backgroundColor: snapshot.isDraggingOver ? '#B6CCFE' : '#fff',
                  padding: 16,
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {formRows.map((item, index) => {
                  return (
                    <DeliveryBatchRow
                      key={index}
                      item={item}
                      index={index}
                      setFormRows={setFormRows}
                      handleClickOpen={handleClickOpen}
                      handleClickSplitDialogOpen={handleClickSplitDialogOpen}
                      handleRowInputChange={handleRowInputChange}
                      treatmentStages={treatmentStages}
                      cuttingTechnicians={cuttingTechnicians}
                      laserCutlineTechnicians={laserCutlineTechnicians}
                      subTypes={subTypes}
                      productTypes={productTypes}
                      validateToSteps={validateToSteps}
                      selectedIndex={selectedIndex}
                      validateSteps={validateSteps}
                      colorMapping={colorMapping}
                      colors={colors}
                      colorIndex={colorIndex}
                      setColorIndex={setColorIndex}
                      updateBatch={updateBatch}
                    />
                  );
                })}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
        {batches.length === 0 && (
          <div className="message-no-result">
            <div className="message-no-result-img">
              <Image src={emptyIcon} alt="empty" source=""></Image>
            </div>
            <DisplayText size="small">No Dispatches were found.</DisplayText>
            <br />
          </div>
        )}
        <AlertRemoveBatchDialog
          open={open}
          setOpen={setOpen}
          selectedIndex={selectedIndex}
          setFormRows={setFormRows}
        />
        <AlertSplitBatchDialog
          open={openSplitDialog}
          setOpen={setOpenSplitDialog}
          selectedIndex={selectedIndex}
          setFormRows={setFormRows}
          setSplittingMode={setSplittingMode}
        />
      </TableContainer>
    </DragDropContext>
  );
};

export default EditDeliveryBatchesTable;
