import React, { useCallback, useRef, useState, useEffect } from "react";

import {
  AppProvider,
  ActionList,
  Card,
  Frame,
  Navigation,
  TopBar,
} from "@shopify/polaris";
import {
  SettingsMajor,
  HomeMajor,
  ProductsMajor,
  ListMajor,
  DiscountsMajor,
  OrdersMajor,
  GiftCardMinor,
  CustomersMajor,
  CustomersMinor,
  StarFilledMinor,
  LogOutMinor,
  ProfileMinor,
  ReportsMajor,
  TransferOutMajor,
  PaymentsMajor,
  JobsMajor,
  BuyButtonMajor,
  FolderMajor,
  ChatMajor,
  MobileMajor,
  ToolsMajor,
  BlogMajor,
  StoreMajor,
  SmileyHappyMajor,
  AddProductMajor,
} from "@shopify/polaris-icons";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import history from "../history";
import Cookies from "js-cookie";
import Cookie from "js-cookie";
import logoWhite from "../logoWhite.png";
import Notification from "./Notification/Notification";
import { Box, Button, Modal } from "@material-ui/core";
import CreateTicket from "../CMSTicketing/CreateTicket/CreateTicket";

export const handledRolePages = () => {
  let array = [];
  const rolesNames = ['editor', 'writer', 'moderator', 'admin','super_admin', 'accounting', 'doctor', 'clinic', 'clinic_moderator', 'clinic_admin','oem','hr']
  const rolesPages = {
    'editor' : { actions:[], pages: ["Products", "Content", "Orders", "Payments", "Reports", "Coupons", "Plans", "Doctors", "Potential doctors", "Book A Scan", "Mobile App", "Settings", "Dispatches"]},
    'writer' : { actions:[], pages: ["Users", "Leads", "Products", "Orders", "Payments", "Reports", "Coupons", "Plans", "Doctors", "Potential doctors", "Book A Scan", "Mobile App", "Settings", "Dispatches"]},
    'moderator' : { actions:[], pages: ["Users", "Leads", "Orders", "Payments"]},
    'super_admin' : { actions:[], pages: ["Products", "Content", "Orders", "Payments", "Reports", "Coupons", "Plans", "Doctors", "Potential doctors", "Book A Scan", "Mobile App", "Settings", "Dispatches"]},
    'accounting' : { actions:[], pages: ["Orders", "Payments"]},
    'clinic_moderator' : { actions:[], pages: ["View Clinics"]},
    'clinic_admin' : { actions:[], pages: ["View Clinics"]},
    'doctor' : { actions:[], pages: ["Users", "Leads", "Payments"]},
    admin: {
      actions: [],
      pages: [
        "Careers",
        "Home",
        "Zoho",
        "Offers",
        "Users",
        "Leads",
        "Products",
        "Content",
        "Orders",
        "Payments",
        "Reports",
        "Coupons",
        "Plans",
        "Doctors",
        "Potential doctors",
        "View Clinics",
        "Book A Scan",
        "Mobile App",
        "Settings",
        "Dispatches",
      ],
    },
    hr: { actions: [], pages: ["Careers", "Home"] },
  }
  
  if(Cookie.get("roleId")){
    let roleId = JSON.parse(Cookie.get("roleId"));
    if(roleId.includes(4)){
      return {actions:[], pages : rolesPages['admin'].pages};
    } else{
      for(let i = 0; i < roleId.length; i++){
        if(rolesPages[rolesNames[roleId[i] - 1]]?.pages){
         
          array = [
            ...array,
            ...rolesPages[rolesNames[roleId[i] - 1]].pages
          ]
        }
      }
    }
    return { actions : [], pages : [...new Set(array)] }
  }
  return { actions : [], pages : [] };
}

export default function NavigationLayout(props) {
  let firstLetter = "";
  const [roleId, setRoleId] = useState([]);
  useEffect(()=>{
    if(Cookie.get("roleId")){
      setRoleId(JSON.parse(Cookie.get("roleId")));
    }
  }, [Cookie.get("roleId")])
  
  const IS_CLINIC_MODERATOR = roleId.includes(9);
  const IS_CLINIC_ADMIN = roleId.includes(10);
  useEffect(() => {
    if (
      Cookie.get("token") === "null" ||
      Cookie.get("token") === undefined ||
      Cookie.get("token") === null
    ) {
      if (history.location.pathname !== "/") {
        history.push("/", { returnUrl: history.location.pathname });
      } else {
        history.push("/");
      }
    }
    if (Cookie.get("name") !== undefined) {
      firstLetter = Cookie.get("name").charAt(0);
    }
  }, []);
  const cookies = new Cookies();
  const defaultState = useRef({
    emailFieldValue: "dharma@jadedpixel.com",
    nameFieldValue: "Jaded Pixel",
  });
  const skipToContentRef = useRef(null);

  const [toastActive, setToastActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [ticketingModalActive, setTicketingModalActive] = useState(false);
  const [newMedia, setNewMedia] = useState([]);
  const [nameFieldValue, setNameFieldValue] = useState(
    defaultState.current.nameFieldValue
  );
  const [emailFieldValue, setEmailFieldValue] = useState(
    defaultState.current.emailFieldValue
  );
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue("");
  }, []);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const userMenuActions = [
    {
      items: [
        {
          content: "Your profile",
          icon: ProfileMinor,
          url: "/admin/profile",
        },
      ],
    },
    {
      items: [
        { content: "Log out", icon: LogOutMinor, onAction: handleSignOut },
      ],
    },
  ];
  function handleSignOut() {
    Cookie.set("token", "null");
    history.push("/");
  }

  const userMenuMarkup = (
    <>
    <div style={{margin:"1.2em"}}>
    <Box onClick={()=>{setTicketingModalActive(true)}}>
            <ConfirmationNumberIcon
              sx={{transform: "scale(2.5)",color:"white"}}
            />
        </Box>
    </div>
    <Notification/>
    <TopBar.UserMenu
      actions={userMenuActions}
      name={Cookie.get("name")}
      initials={firstLetter}
      avatar={Cookie.get("profileImage")}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
     />   
    </>
  );

  const searchResultsMarkup = (
    <Card>
      <ActionList
        items={[
          { content: "Shopify help center" },
          { content: "Community forums" },
        ]}
      />
    </Card>
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchResultsVisible={searchActive}
      // searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );
  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            url: "/admin/home",
            label: "Home",
            icon: HomeMajor,
            disabled:
              // (String(Cookie.get("roleId")) !== "4" ||
              (!handledRolePages().pages.includes('Home') ||
                String(Cookie.get("userType")) === "50") &&
              true,
            selected: window.location.href.indexOf("/admin/home") > -1 && true,
          },

          // {
          //   url: "/admin/content",
          //   label: "Content",
          //   icon: VocabularyMajorMonotone,
          //   disabled:
          //     (!handledRolePages().pages.includes('Content') ||
          //       String(Cookie.get("userType")) === "50") &&
          //     true,
          //   selected:
          //     window.location.href.indexOf("/admin/content") > -1 && true,
          // },
          {
            url: "/admin/users",
            label: "Users",
            icon: CustomersMajor,
            disabled: (!handledRolePages().pages.includes('Users')) &&
              true,
            onClick: () => {
              Cookie.set("usersAvailability", "in_table");
              Cookie.set("usersQueryValue", "");
              Cookie.set("usersCurrentPage", 1);
              Cookie.set("usersSelectedTab", 0);
            },
            selected: window.location.href.indexOf("/admin/users") > -1 && window.location.href.indexOf("/admin/users/dashboard") ==-1 && true,
          },
          {
            url: "/admin/users/dashboard",
            label: "Discussions",
            icon: ChatMajor,
            selected: window.location.href.indexOf("/admin/users/dashboard") > -1 && true,
          },
          {
            url: "/admin/ticketing",
            label: "Ticketing",
            icon: BlogMajor,
            selected:
              window.location.href.indexOf("/admin/ticketing") > -1 && true,
          },
          {
            url: "/admin/oem",
            label: "OEM Cases",
            icon: SmileyHappyMajor,
            selected:
              window.location.href.indexOf("/admin/oem") > -1 && true,
          },
          
          {
            url: "/admin/leads",
            label: "Leads",
            icon: CustomersMinor,
            disabled:
              (!handledRolePages().pages.includes('Leads')) &&
              true,

            selected: window.location.href.indexOf("/admin/leads") > -1 && true,
          },
          {
            url: "/admin/voucher-careplus",
            label: "Care+",
            icon: GiftCardMinor,
            selected: window.location.href.indexOf("/admin/voucher-careplus") > -1 && true,
          },
          {
            url: "/admin/influencers",
            label: "Influencers",
            icon: StarFilledMinor,
            disabled: (roleId.includes(1) || (roleId.length == 1 && (IS_CLINIC_ADMIN || IS_CLINIC_MODERATOR)) || roleId.includes(6)) && true,
            selected: window.location.href.indexOf("/admin/influencers") > -1 && true,
          },
          {
            url: "/admin/shop/products",
            label: "Shop",
            icon: StoreMajor,
            selected: window.location.href.includes("/admin/shop"),
          },
          {
            url: "/admin/products",
            label: "Products",
            icon: ProductsMajor,
            disabled:
              // (String(Cookie.get("roleId")) === "3" ||
              (!handledRolePages().pages.includes('Products') ||
                String(Cookie.get("userType")) === "50") &&
              true,
            selected:
              window.location.href.indexOf("/admin/products") > -1 && true,
          },
          {
            url: "/admin/content",
            label: "Content",
            icon: FolderMajor,
            disabled: !handledRolePages().pages.includes('Content') && true,
            selected: window.location.href.indexOf("/admin/content") > -1 && true,
          },
          {
            url: "/admin/orders",
            label: "Orders",
            icon: OrdersMajor,
            disabled: (String(Cookie.get("userType")) === "50" || !handledRolePages().pages.includes('Orders')) && true,
            selected:
              window.location.href.indexOf("/admin/orders") > -1 && true,
          },
          {
            url: "/admin/dispatches",
            label: "Upcoming Dispatches",
            icon: TransferOutMajor,
            disabled:
                (!handledRolePages().pages.includes('Dispatches') ||
                  String(Cookie.get("userType")) === "50") &&
                true,
            selected:
              window.location.href.indexOf("/admin/dispatches") > -1 &&
              true,
          },
          {
            url: "/admin/payments",
            label: "Payments",
            icon: PaymentsMajor,
            disabled: !handledRolePages().pages.includes('Payments'),
            items: [
              {
                url: "/admin/home",
                label: "Home",
                icon: HomeMajor,
                disabled:(!handledRolePages().pages.includes('Home') ||
                    String(Cookie.get("userType")) === "50") &&
                  true,
              },
            ],
            selected:
              window.location.href.indexOf("/admin/payments") > -1 && true,
            // disabled: String(Cookie.get("roleId")) === "1" && true,
          },
          {
            url: "/admin/reports",
            label: "Reports",
            icon: ReportsMajor,
            disabled: (!handledRolePages().pages.includes('Reports') ||
                String(Cookie.get("userType")) === "50") &&
              true,
            selected:
              window.location.href.indexOf("/admin/reports") > -1 && true,
          },
          {
            url: "/admin/coupons",
            label: "Coupons",
            icon: DiscountsMajor,
            disabled: (!handledRolePages().pages.includes('Coupons') ||
                String(Cookie.get("userType")) === "50") &&
              true,
            selected:
              window.location.href.indexOf("/admin/coupons") > -1 && true,
          },
          {
            url: "/admin/plans",
            label: "Plans",
            icon: ListMajor,
            disabled: (!handledRolePages().pages.includes('Plans') ||
                String(Cookie.get("userType")) === "50") &&
              true,
            selected: window.location.href.indexOf("/admin/plans") > -1 && true,
          },

          // {
          //   url: "/admin/pos",
          //   label: "POS",
          //   icon: StoreMajorMonotone,
          //   disabled:
          //     (String(Cookie.get("roleId")) === "3" ||
          //       String(Cookie.get("roleId")) === "6" ||
          //       String(Cookie.get("userType")) === "50") &&
          //     true,
          // },
          {
            url: "/admin/doctors",
            label: "Doctors",
            icon: JobsMajor,
            disabled: (!handledRolePages().pages.includes('Doctors') ||
                String(Cookie.get("userType")) === "50") &&
              true,
            selected:
              window.location.href.indexOf("/admin/doctors") > -1 && true,
          },

          {
            url: "/admin/potential-doctors",
            label: "Potential Doctors",
            icon: JobsMajor,
            disabled:
                (!handledRolePages().pages.includes('Potential doctors') ||
                  String(Cookie.get("userType")) === "50") &&
                true,
            selected:
              window.location.href.indexOf("/admin/potential-doctors") > -1 &&
              true,
          },
          {
            url: "/admin/view-clinics",
            label: "View Clinics",
            icon: BuyButtonMajor,
            disabled: !handledRolePages().pages.includes('View Clinics') && true,
            selected:
              window.location.href.indexOf("/admin/view-clinics") >
                -1 && true,
          },
          {
            url: "/admin/book-a-scan-countries",
            label: "Book A Scan",
            icon: BuyButtonMajor,
            disabled:
              (!handledRolePages().pages.includes('Book A Scan') ||
                String(Cookie.get("userType")) === "50") &&
              true,
            selected:
              window.location.href.indexOf("/admin/book-a-scan-countries") >
                -1 && true,
          },
          {
          url: "/admin/mobile-app",
          label: "Mobile App",
          icon: MobileMajor,
          disabled:(!handledRolePages().pages.includes('Mobile App') ||
                String(Cookie.get("userType")) === "50") &&
              true,
          selected:
          window.location.href.indexOf("/admin/mobile-app") > -1 &&
          true,
          },
          {
            url: "/admin/zoho-freshsales",
            label: "Zoho Freshsales",
            icon: ToolsMajor,
            disabled: (!handledRolePages().pages.includes('Zoho') ||
                String(Cookie.get("userType")) === "50") &&
              true,
            selected:
              window.location.href.indexOf("/admin/zoho-freshsales") > -1 && true,
          },
          {
            url: "/admin/offers",
            label: "Offers",
            icon: AddProductMajor,
            disabled:
              (!handledRolePages().pages.includes("Offers") ||
                String(Cookie.get("userType")) === "50") &&
              true,
            selected:
              window.location.href.indexOf("/admin/offers") > -1 && true,
          },
          {
            url: "/admin/careers",
            label: "Careers",
            icon: CustomersMinor,
            disabled:
              (!handledRolePages().pages.includes('Careers')),
            selected: window.location.href.indexOf("/admin/careers") > -1 && true,
          },
        ]}
      ></Navigation.Section>

      <Navigation.Section
        separator
        items={[
          {
            icon: SettingsMajor,
            label: "Settings",
            url: "/admin/settings",
            disabled: (!handledRolePages().pages.includes('Settings') ||
                String(Cookie.get("userType")) === "50") &&
              true,
            selected:
              window.location.href.indexOf("/admin/settings") > -1 && true,
          },
        ]}
      />
    </Navigation>
  );

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );
  const overlayTicketingStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
  };

  const theme = {
    logo: {
      width: 124,
      topBarSource: logoWhite,
      url: "/admin/home",
    },
  };

  return (
    <div style={{ height: "500px" }}>
      <AppProvider theme={theme}>
        <Frame
          topBar={topBarMarkup}
          navigation={props.isTeleConsultationPage ? false : navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
        >
          {props.children}
        </Frame>
      <Modal
          open={ticketingModalActive}
          onClose={()=>{setTicketingModalActive(false)}}>
          <CreateTicket customStyle={overlayTicketingStyle} newMedia={newMedia} setNewMedia={setNewMedia} setTicketingModalActive={setTicketingModalActive}/>
        </Modal>
      </AppProvider>
    </div>
  );
}
