import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { useStyles } from './view-delivery-batches-table.styles';
import ViewDeliveryBatchesRow from './view-delivery-batches-row';
import { useEffect, useRef } from 'react';

const ViewDeliveryBatchesTable = ({
  batches,
  handleDelayDate,
  handleOpen,
  handlePaymentStatus,
  handleGetItemInfo,
  handleAddTracking,
}) => {
  const classes = useStyles();
  const columnWidth = '100px';
  const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef) {
      const stickyColumns = tableRef.current.querySelectorAll(
        'tr td:nth-child(n+1):nth-child(-n+5), tr th:nth-child(n+1):nth-child(-n+5)'
      );
      let left = 0;
      for (let i = 0; i < stickyColumns.length; i++) {
        if (i % 5 === 0) {
          left = 0;
        } else {
          left += stickyColumns[i - 1].offsetWidth;
        }
        stickyColumns[i].style.left = `${left}px`;
      }
    }
  }, []);

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '70vh' }} ref={tableRef}>
      <Table
        classes={{ root: classes.table }}
        stickyHeader
        aria-label="view delivery batches table"
      >
        <TableHead>
          <TableRow>
            <TableCell style={{zIndex: 30}}></TableCell>
            <TableCell style={{zIndex: 30}} sx={{ padding: '16px 0' }}>Tracking Actions</TableCell>
            <TableCell style={{zIndex: 30}}>Treatment Stage</TableCell>
            <TableCell style={{zIndex: 30}}>Type</TableCell>
            <TableCell style={{zIndex: 30}}>Batch</TableCell>
            <TableCell>Pickup From Clinic</TableCell>
            <TableCell>Sub Type</TableCell>
            <TableCell>Delivery</TableCell>
            <TableCell>Target Dispatch Date</TableCell>
            <TableCell>Actual Dispatch Date</TableCell>
            <TableCell>Expected Receipt Date</TableCell>
            <TableCell>Actual Receipt Date</TableCell>
            <TableCell>Expected Start of Batch</TableCell>
            <TableCell>
              <Box width={columnWidth}>
                Expected Start of Last Step in Batch
              </Box>
            </TableCell>
            <TableCell>
              <Box width={columnWidth}>Upper Steps</Box>
            </TableCell>
            <TableCell>
              <Box width={columnWidth}>Lower Steps</Box>
            </TableCell>
            <TableCell>Upper Product Quantity</TableCell>
            <TableCell>Lower Product Quantity</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>
              <Box width={'200px'}>Delays</Box>
            </TableCell>
            <TableCell>Paid/Due Payments</TableCell>
            <TableCell>Upper Remaining Steps to be Distributed</TableCell>
            <TableCell>Lower Remaining Steps to be Distributed</TableCell>
            <TableCell>Source</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {batches.map((batch, index) => (
            <ViewDeliveryBatchesRow
              key={index}
              batchItem={batch}
              handleDelayDate={handleDelayDate}
              handleOpen={handleOpen}
              handlePaymentStatus={handlePaymentStatus}
              handleGetItemInfo={handleGetItemInfo}
              handleAddTracking={handleAddTracking}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ViewDeliveryBatchesTable;
