import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import { collection, query, orderBy, onSnapshot, getDocs, doc, getDoc, where, addDoc, serverTimestamp, documentId, limit, startAfter, endBefore, endAt, updateDoc, } from "firebase/firestore"
import { auth, db } from '../../firebase';
import "react-chat-elements/dist/main.css"
import { convertReactMentionToString } from "../../util/helpers";
import "./CaseDiscussionChat.css"
import axios from "../../axios";
import { signInWithCustomToken } from "firebase/auth";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import CaseDiscussionInput from "../CaseDiscussionInput/CaseDiscussionInput";
import CaseDiscussionNoChat from "./CaseDiscussionNoChat";
import CaseDiscussionMessages from "../CaseDiscussionMessage/CaseDiscussionMessages";
import CaseDiscussionHeader from "../CaseDiscussionHeader/CaseDiscussionHeader";
import { useStyles } from "./CaseDiscussionChatStyles";
import { useDropzone } from "react-dropzone";

function CaseDiscussionChat({ setOpenChat, openChat, setPreviewImages }) {
  const [Chat, setChat] = useState();
  const [messages, setMessages] = useState([]);
  const [lastVisibleItem, setLastVisibleItem] = useState(null);
  const [taggedMessages, setTaggedMessages] = useState([]);
  const [mentionAvailable, setMentionAvailable] = useState(true);
  const itemsPerPage = 5;
  const [userName, setUserName] = useState('');
  const [newMessage, setNewMessage] = useState({ type: "text", reply_to: null, data: "", reply: null })
  const [newMedia, setNewMedia] = useState([])
  const [admins, setAdmins] = useState(new Map());
  const [agents, setAgents] = useState([]);
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true);
  const [noChatLoading, setNoChatLoading] = useState(false);
  const [makeNewChat, setMakeNewChat] = useState({ status: false, userFound: false });
  const classes = useStyles();
  let chatBottom = useRef(null);
  let { id } = useParams()
  let userId = parseInt(id);
  const [adminId, setAdminId] = useState(0);
  const videoTypes = ['video/', 'mp4', 'mov', 'wmv', 'avi'];
  const imageTypes = ['image/', 'jpg', 'jpeg', 'png', 'gif'];
  let chatTop = useRef(null);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {

      if(!acceptedFiles.length){
        return
      }
      if(acceptedFiles.length>1){
        let images = acceptedFiles.filter(file=>imageTypes.some(type=>file.type.includes(type)))
        setNewMedia((prev) => [...images])
        return;
      }
      setNewMedia((prev) => [...acceptedFiles])
    },
    noClick: true,
    noKeyboard:true
  });
  function removeTrailingBR(inputString) {
    const regex = /(<br>)+$/;
    return inputString.replace(regex, '');
  }
  const uploadToAWS = async(file,firebaseNewChat)=>{
    const mimeType = file.type;
    const extension = file.name.split('.').pop().toLowerCase();
    let fileType;
    if (mimeType.startsWith('image/') || extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
      fileType = 'photo';
    } else if (mimeType.startsWith('video/') || extension === 'mp4' || extension === 'mov' || extension === 'wmv' || extension === 'avi') {
      fileType = 'video';
    } else {
      fileType = 'file';
    }
    const randomString = Math.random().toString(36).substring(2, 8);
    const filename = `${randomString}-${file.name}`;
    let url2 = '';
    const form_data = new FormData();
    form_data.append("content_type", mimeType);
    form_data.append("file_name", filename);
    let res = await axios
      .post(`/admin/v1/firebase/${userId}/upload`, form_data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      url2 = res.data.url;
      const downloadKey = res.data.key;

      await axios.put(url2, file, {
        headers: {
          "x-amz-acl": "public-read-write",
          "Content-Type": mimeType,
        },
      });
      firebaseNewChat.data.push({'type':fileType,'data':downloadKey})
  }
  const handleSubmit = async (e) => {
    console.log('function run')
    e.preventDefault();
    setLoading(true)
    let collectionRef = collection(db, 'Messages')
    let firebaseNewChat = {
      'admin_id': adminId,
      'reply_to': newMessage.reply_to ? doc(db, 'Messages', newMessage.reply_to) : null,
      'chat_id': Chat,
      'created_at': serverTimestamp(),
      'data':[],
      'tags':[],
    }
    let ids=[];
    let convertedStr=''
    let repliedBody = null;
    if (newMessage.reply_to) {
      repliedBody = {
        repliedAgent: adminId,
        replied_to: newMessage.reply.replied_to,
        user: userId,
        userName: userName,
        type: newMedia.length?'file':'text',
        chatId: Chat,
        original_message: newMessage.reply.data
      }
    }
    
    if (newMessage.data) {
      [convertedStr, ids] = convertReactMentionToString(newMessage.data)
      convertedStr = removeTrailingBR(convertedStr)
      if(!convertedStr){
        setLoading(false);
        return;
      }
      if (newMessage.reply_to) {
        repliedBody['msg'] = convertedStr.replace(/<br>/g,'\n');
      }
      firebaseNewChat.tags = ids;
      firebaseNewChat.data.push({'type':'text','data':convertedStr});
    }
    if (newMedia.length) {
      if(repliedBody){
        repliedBody['msg'] = "Replied with Media"
      } 
      const promises = newMedia.map((file) => uploadToAWS(file,firebaseNewChat));
      await Promise.all(promises)
    }
    if (!firebaseNewChat.data.length) {
      setLoading(false);
      return;
    }
    addDoc(collectionRef, firebaseNewChat)
    const chatRef = doc(db, 'AdminChat', Chat);
    const chatData = await getDoc(chatRef);
    let newUserRefs = chatData.data().userRefs ?? [];
    console.log('new newUserRefs',newUserRefs)
    if (!newUserRefs.includes(adminId)) {
      newUserRefs.push(adminId);
    }
    await updateDoc(chatRef, {
      latestMessageTimestamp: serverTimestamp(),
      userRefs: newUserRefs,
    });
    setNewMedia([]);
    setNewMessage({ type: "text", reply_to: null, data: "", reply: null })
    setTimeout(() => {
      if(chatBottom && chatBottom.current){
        chatBottom.current.scrollIntoView({ 'behaviour': "smooth" });
      }
    }, 400)
    if (ids.length) {
      await axios.post(`/admin/v1/firebase/${userId}/tagged`, { taggedAgents: ids, taggingAgent: adminId, user: userId, userName: userName, msg: convertedStr.replace(/<br>/g,'\n'), chatId: Chat },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token")
        }
      });
    }
    if (repliedBody) {
      await axios.post(`/admin/v1/firebase/${userId}/reply`, repliedBody, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token")
        }
      });
    }
    setLoading(false)
  }
  const handleSubmit2 = async (e) => {
    console.log('function run')
    e.preventDefault();
    let collectionRef = collection(db, 'Messages')
    let firebaseNewChat = {
      'admin_id': adminId,
      'reply_to': newMessage.reply_to ? doc(db, 'Messages', newMessage.reply_to) : null,
      'chat_id': Chat,
      'created_at': serverTimestamp(),
      'data':[],
      'tags':[],
    }
    let ids=[];
    let convertedStr=''
    let repliedBody = null;
    if (newMessage.reply_to) {
      repliedBody = {
        repliedAgent: adminId,
        replied_to: newMessage.reply.replied_to,
        user: userId,
        userName: userName,
        type: newMedia.length?'file':'text',
        chatId: Chat,
        original_message: newMessage.reply.data
      }
    }
    
    if (newMessage.data) {
      [convertedStr, ids] = convertReactMentionToString(newMessage.data)
      if (newMessage.reply_to) {
        repliedBody['msg'] = convertedStr.replace(/<br>/g,'\n');
      }
      firebaseNewChat.tags = ids;
      firebaseNewChat.data.push({'type':'text','data':convertedStr});
    }
    if (newMedia.length) {
      setLoading(true)
      if(repliedBody){
        repliedBody['msg'] = "Replied with Media"
      } 
      const promises = newMedia.map((file) => uploadToAWS(file,firebaseNewChat));
      Promise.all(promises)
        .then(async() => {
          console.log(firebaseNewChat)
          addDoc(collectionRef, firebaseNewChat)
          //added code
          const chatRef = doc(db, 'AdminChat', Chat);
          await updateDoc(chatRef, {
            latestMessageTimestamp: serverTimestamp(),
          });
        })
        .then(async() => {
          setNewMedia([]);
          setNewMessage({ type: "text", reply_to: null, data: "", reply: null })
          setTimeout(() => {
            if(chatBottom && chatBottom.current){
              chatBottom.current.scrollIntoView({ 'behaviour': "smooth" });
            }
          }, 400)
          if (ids.length) {
            await axios.post(`/admin/v1/firebase/${userId}/tagged`, { taggedAgents: ids, taggingAgent: adminId, user: userId, userName: userName, msg: convertedStr.replace(/<br>/g,'\n'), chatId: Chat },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("token")
              }
            });
          }
          if (repliedBody) {
            await axios.post(`/admin/v1/firebase/${userId}/reply`, repliedBody, {
              headers: {
                Authorization: "Bearer " + Cookies.get("token")
              }
            });
          }
          setLoading(false)
        })
        .catch(error => {
          console.error("Error:", error);
        });
    }else{
      addDoc(collectionRef, firebaseNewChat)
      const chatRef = doc(db, 'AdminChat', Chat);
      await updateDoc(chatRef, {
        latestMessageTimestamp: serverTimestamp(),
      });
      setNewMedia([]);
      setNewMessage({ type: "text", reply_to: null, data: "", reply: null })
      setTimeout(() => {
        console.log('chatBottom',chatBottom)
        if(chatBottom && chatBottom.current){
          chatBottom.current.scrollIntoView({ 'behaviour': "smooth" });
        }
      }, 400)
      if (ids.length) {
        await axios.post(`/admin/v1/firebase/${userId}/tagged`, { taggedAgents: ids, taggingAgent: adminId, user: userId, userName: userName, msg: convertedStr.replace(/<br>/g,'\n'), chatId: Chat },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token")
          }
        });
      }
      if (repliedBody) {
        await axios.post(`/admin/v1/firebase/${userId}/reply`, repliedBody, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token")
          }
        });
      }
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!Chat && openChat.firstOpen && openChat.status) {
      try {
        let firebasePromise ;
        let firebaseSignIn = false;
        if(!auth.currentUser && process.env.REACT_APP_FIREBASE_V2){
          firebasePromise = axios.get(`/admin/v1/firebase/agentauth`,{
            headers:{
              Authorization: "Bearer " + Cookies.get("token")
            }
          })
          firebaseSignIn = true;
        }else{
          firebasePromise = Promise.resolve();
        }
        firebasePromise.then(async (res) => {
          let adminId;
          if(firebaseSignIn){
            let { token } = res.data
            adminId = res.data.adminId;
            let res2 = await signInWithCustomToken(auth, token);
            setToken(token)
          }else{
            adminId = parseInt(Cookies.get('admin_id'));
          }
          setAdminId(adminId)
          let adminColec = collection(db, 'Admins')
          let adminDocs = await getDocs(adminColec);
          let admins = new Map()
          let agents = [];
          adminDocs.forEach(admin => {
            let adminData = admin.data()
            admins.set(adminData.admin_id, adminData.name)
            agents.push({ id: adminData.admin_id, display: adminData.name.replaceAll(" ", ".") })
          })
          setAdmins(admins)
          setAgents(agents)
          let userColec = collection(db, 'Users')
          let userQ = query(userColec, where('user_id', '==', userId))
          let userDocs = await getDocs(userQ, { source: "server" })
          if (userDocs.docs.length == 0) {
            setMakeNewChat({ status: true, userFound: false });
            return;
          }
          let user = userDocs.docs[0].data();
          if (!user.chat_id || !user.chat_id.id) {
            setMakeNewChat({ status: true, userFound: true });
            return;
          }
          setChat(user.chat_id.id)
          setUserName(user.name)
          let messageBox = document.querySelector('#chatbox');
          const scrollToBottomBtn = document.querySelector('#scroll-button');
          if (messageBox && scrollToBottomBtn) {
            messageBox.addEventListener('scroll', () => {
              if (messageBox.scrollTop * 1.15 < messageBox.scrollHeight - messageBox.clientHeight) {
                scrollToBottomBtn.style.display = 'flex';
                scrollToBottomBtn.style.alignItems = 'center';
                scrollToBottomBtn.style.flexDirection = 'column';
              } else {
                scrollToBottomBtn.style.display = 'none';
                scrollToBottomBtn.style.flexDirection = 'unset';
                scrollToBottomBtn.style.alignItems = 'unset';
              }
            });
          }
        });
      } catch (err) {

      }
    }

    return () => {
      console.log('close')
      let messageBox = document.querySelector('#chatbox');
      messageBox?.removeEventListener('scroll', {})
    };
    

  }, [openChat])
  const handleBeforeUnload = () => {
    console.log('test','serviceWorker' in navigator && navigator.serviceWorker.controller)
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
      };
      navigator.serviceWorker.controller.postMessage({ adminId ,chatId:Chat,eventType:"readChat",token,firebaseConfig});
    }
  };
  const createChat = async () => {
    if (!makeNewChat.status) {
      return;
    }
    setNoChatLoading(true)
    let res = await axios.post(`admin/v1/firebase/createChat`, { userFound: makeNewChat.userFound, user_id: userId }, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      }
    }
    );
    if(res.data.success){
      setChat(res.data.chatId);
      setUserName(res.data.userName);
      setMakeNewChat({ status: false, userFound: true })
    }
    setNoChatLoading(false)
  }
  const handleReplies = async (snapshot) => {
    const replies = new Set();
    let updatedData = [];
    snapshot.docs.forEach((doc) => {
      let msg = doc.data()
      updatedData.push({ id: doc.id, ...msg, reply: null,data:msg.data })
      if (msg?.reply_to?.id) {
        replies.add(msg.reply_to?.id)
      }
    });
    updatedData = updatedData.reverse()
    let val = 1;
    if (val == 1 && replies.size > 0) {
      const replyCollection = collection(db, 'Messages');
      const replyQuery = query(replyCollection, where(documentId(), 'in', Array.from(replies)));
      let replySnapshots = await getDocs(replyQuery)
      const repliesRes = replySnapshots.docs.map((doc) =>
      {
        let obj = {...doc.data()};
        return { id: doc.id, type:obj.data[0].type, ...obj, data:obj.data[0].data}
      });
      updatedData.forEach((msg) => {
        if (msg.reply_to) {
          msg.reply = repliesRes.find((reply) => reply.id === msg.reply_to.id) || null;
        }
        return msg;
      });
    }
    return updatedData;
  }
  useEffect(() => {
    if (Chat) {
      setLoading(true)
      let collectionRef = collection(db, 'Messages')
      let q = query(collectionRef, where('chat_id', '==', Chat), orderBy('created_at', 'desc'), limit(itemsPerPage))
      let unsubscribe =()=>{};
      getDocs(q, { source: "server" }).then(async (snapshot) => {
        let arr = await handleReplies(snapshot)
        setMessages(arr)
        let taggedMessages = snapshot.docs.filter((doc)=> doc.data().tags && doc.data().tags?.findIndex((tag)=>tag==adminId) != -1)
        setTaggedMessages(taggedMessages)
        if (!snapshot.empty) {
          setTimeout(() => {
            chatBottom.current.scrollIntoView({ 'behaviour': "smooth" });
          }, 700)
        }
        let snapq;
        if (snapshot.docs.length) {
          let firstMessage = snapshot.docs[0];
          if(snapshot.docs.length<itemsPerPage){
            setLastVisibleItem(null)
          }else{
            setLastVisibleItem(snapshot.docs[snapshot.docs.length - 1])
          }
          setLoading(false)
          snapq = query(collectionRef, where('chat_id', '==', Chat), orderBy('created_at', 'desc'), endBefore(firstMessage))
        } else {
          setLastVisibleItem(null)
          setLoading(false)
          snapq = query(collectionRef, where('chat_id', '==', Chat), orderBy('created_at', 'desc'))
        }
        unsubscribe = onSnapshot(snapq, async (snapshot) => {
          let newMsg = await handleReplies(snapshot)
          if (newMsg.length) {
            setMessages(prev => {
              let arr = [...prev]
              for (let i = 0; i < newMsg.length; i++) {
                const newObj = newMsg[i];
                let found = false;
                for (let j = 0; j < arr.length; j++) {
                  const oldObj = arr[j];
                  if (newObj.id === oldObj.id) {
                    arr[j] = newObj;
                    found = true;
                    break;
                  }
                }
                if (!found) {
                  arr.push(newObj);
                }
              }
              return [...arr];
            })
          }
        });
      })
      handleBeforeUnload()
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        handleBeforeUnload()
        console.log('clean')
        unsubscribe()
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [Chat]);
  const getLastMention = () =>{
    if(taggedMessages.length){
      let messageBox = document.getElementById(taggedMessages[0].id);
      if(messageBox){
        messageBox.style.backgroundColor = "#c7c7c778";
        messageBox.scrollIntoView({ 'behaviour': "smooth" });
        setTimeout(function() {
          messageBox.style.backgroundColor = "initial"
        }, 1000); 
      }
      setTaggedMessages(prev => prev.slice(1));
      return
    }
    let collectionRef = collection(db, 'Messages')
    let q = query(collectionRef, where('chat_id', '==', Chat), orderBy('created_at', 'desc'), startAfter(lastVisibleItem), where("tags", "array-contains", adminId),  limit(1))
    getDocs(q).then(async (snapshot) => {
      if (snapshot.size) {
        let lastIndex = snapshot.docs[snapshot.docs.length - 1]
        let newq = query(collectionRef, where('chat_id', '==', Chat), orderBy('created_at', 'desc'), startAfter(lastVisibleItem), endAt(lastIndex))
        let res = await  getDocs(newq);
        let replies = await handleReplies(res);
        setMessages(prev => [...replies, ...prev])
        setLastVisibleItem(lastIndex)
        setTimeout(() => {
          if(chatTop && chatTop.current){
            chatTop.current.scrollIntoView({ 'behaviour': "smooth" });
            let messageBox = document.getElementById(lastIndex.id);
            if(messageBox){
              messageBox.style.backgroundColor = "#c7c7c778"
              setTimeout(function() {
                messageBox.style.backgroundColor = "initial"
              }, 1000); 
            }
          }
        }, 500)
      }else{
        let revMessages = [...messages].reverse();
        let taggedMessages = revMessages.filter((doc)=> doc.tags && doc.tags?.findIndex((tag)=>tag==adminId) != -1)
        let messageBox = document.getElementById(taggedMessages[0].id);
        if(messageBox){
          messageBox.style.backgroundColor = "#c7c7c778";
          messageBox.scrollIntoView({ 'behaviour': "smooth" });
          setTimeout(function() {
            messageBox.style.backgroundColor = "initial"
          }, 1000); 
        }
        setTaggedMessages(taggedMessages.slice(1));
      }
    });
  }
  const handleLoadMore = () => {
    if (!lastVisibleItem) {
      return null;
    }
    let collectionRef = collection(db, 'Messages')
    let q = query(collectionRef, where('chat_id', '==', Chat), orderBy('created_at', 'desc'), startAfter(lastVisibleItem), limit(itemsPerPage))
    getDocs(q).then(async (snapshot) => {
      if (snapshot.size) {
        let replies = await handleReplies(snapshot);
        setMessages(prev => [...replies, ...prev])
        if(snapshot.size<itemsPerPage){
          setLastVisibleItem(null)
        }else{
          setLastVisibleItem(snapshot.docs[snapshot.docs.length - 1])
        }
      } else {
        setLastVisibleItem(null)
      }
    });
  }

  if (makeNewChat.status) {
    return (
      <CaseDiscussionNoChat setOpenChat={setOpenChat} createChat={createChat} loading={noChatLoading} />)
  } else
    return (
      <Grid container className={classes.root}>
        {loading ? <Box className={classes.loadingBox}><CircularProgress className={classes.loading} /></Box> : <></>}
        <CaseDiscussionHeader userId={userId} userName={userName} setOpenChat={setOpenChat} />
        <Grid item xs={12} className={classes.bodyRow}  {...getRootProps()}>
          <Grid container className={classes.messageContainer} >
            <CaseDiscussionMessages messages={messages} setNewMessage={setNewMessage} admins={admins}
             adminId={adminId} chatBottom={chatBottom} chatTop={chatTop} mentionAvailable={mentionAvailable} setPreviewImages={setPreviewImages} handleLoadMore={handleLoadMore} lastVisibleItem={lastVisibleItem} getLastMention={getLastMention}/>
            <CaseDiscussionInput newMessage={newMessage} setNewMessage={setNewMessage} newMedia={newMedia} setNewMedia={setNewMedia} handleSubmit={handleSubmit} agents={agents} formDisabled={loading}/>
          </Grid>
        </Grid>
      </Grid>
    );
};

export default CaseDiscussionChat;
