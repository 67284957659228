import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Save from "./Save";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScreeningPhotos from "./ScreeningPhotos";
import { caseRecordTypes } from "../util/constants";
  
const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: "1rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    photoBox: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    photoWidthSM: {
      width: "60%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    photoWidth: {
      width: "70%",
      marginBottom: 15,
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    titleStyle: {
      height: "16px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2rem',
    },
    replacedPhotosWrapper: {
      marginTop: '3rem',
    },
  };
});

export default function AppointmentScreeningPhotos({
  disable,
  appointmentId,
  appointmentNumber,
  appointmentType,
  handleTabsExpand,
  userId,
  index,
  openCloseTabs,
  scrollToExpandedTab,
  updateReviewStatus,
  delayedActionJob,
}) {
  const classes = useStyles();
  const [photos, setPhotos] = useState([]);
  const [hasDelayedAction, setHasDelayedAction] = useState(false);
  const [iconPhotos, setIconPhotos] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState({
    disabled: false,
    popupActive: false,
    success: false,
    disableReviewBtn: false,
    download: false,
  });

  const handleIconPhotosView = (value) => {
    setIconPhotos(value);
  };

  const downloadPhotos = () => {
    setLoading(prev => ({
      ...prev,
      download: true,
    }));
    axios.get(`/admin/v2/users/${userId}/download-screening-photos/${photos?.id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      var win = window.open(
        `${process.env.REACT_APP_BASE_URL}/storage${result.data.data}`,
        "_blank"
      );
      win.focus();
      if(!result.data.success){
        toast.error('No photos found to download', 3000);
      }
    }).catch((err) => {
      console.log('err', err);
    }).finally(f => {
      setLoading(prev => ({
        ...prev,
        download: false,
      }));
    });
  }

  const handleDataFetched = () => {
    setLoading(prev => ({
      ...prev,
      popupActive: true,
    }));
    axios.get(`admin/v2/users/case-record-screening-photos/${appointmentId}?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        if(!res.data.data.review_status && loading.disableReviewBtn){
          setLoading(prev => ({
            ...prev,
            disableReviewBtn: false,
          }));
        }
        setPhotos(res.data.data);
        setHasDelayedAction(res.data.has_delayed_action);
        handleIconPhotosView(res.data.isAllProvided);
      } else {
        handleIconPhotosView(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setLoading(prev => ({
        ...prev,
        popupActive: false,
      }));
    })
  };

  useEffect(() => {
    handleDataFetched();
  }, []);


  useEffect(() => {
    if(delayedActionJob.refetchedRecord === `${caseRecordTypes.UserCaseRecordScreeningPhoto}-${photos?.id}`) {
      handleDataFetched();
    }
}, [delayedActionJob]);

  useEffect(() => {
    scrollToExpandedTab(index, "photos");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="photos"
      headerTitle={<p><span className="procedure-header-title">Photos:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={iconPhotos}
    >
      {openCloseTabs[index]?.photos && (
        <Box className={`${classes.photoWrapper} photos-${index}`}>
          {loading.popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <>
              <ToastContainer />
              <Box className={classes.titleBox}>
                <Typography className={classes.titleStyle}>Screening Photos</Typography>
                <Button disabled={loading.download} onClick={downloadPhotos}>Download Photos</Button>
              </Box>
              <ScreeningPhotos
                photos={photos}
                appointmentType={appointmentType}
                disable={disable}
                appointmentId={appointmentId}
                handleDataFetched={handleDataFetched}
                userId={userId}
                classes={classes}
                setErrorMsg={setErrorMsg}
                loading={loading}
                setLoading={setLoading}
                updateReviewStatus={updateReviewStatus}
                delayedActionJob={delayedActionJob}
                appointmentNumber={appointmentNumber}
                parentId={photos?.id}
                hasDelayedAction={hasDelayedAction}
              />
              <Save uploadedBy={iconPhotos != 0 ? photos?.uploaded_by_user : ''} success={loading.success} errorMessage={errorMsg} />
            </>
          }
        </Box>
      )}

    </ProcedureHeader>
  );
}
  