import NavigationLayout from "../components/NavigationLayout";
import { Route, Router, Switch } from "react-router-dom";
import history from "../history";
import React from "react";
import ListOffers from "./list-offers.component";
import AddOffer from "./add-offer.component";

function Offers() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/offers" component={ListOffers} />
          <Route path="/admin/offers/add" component={AddOffer} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}

export default Offers;
