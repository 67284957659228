import { FormLayout, TextField, TextStyle } from "@shopify/polaris";
import Select from "react-select";
import React, { useEffect } from "react";
import { Radio, RadioGroup } from "react-radio-group";

function AddOfferForm({
  formData,
  setFormData,
  countries,
  products,
  loading,
  getOfferParameters,
}) {
  const { title, quantity, startDate, endDate, isFirst } = formData;

  const handleChange = (field, value) => {
    if (field === "countries") {
      value = value.map((country) => country.value);
    }
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    void getOfferParameters(signal);
    return () => {
      abortController.abort();
    };
  }, []);

  const labelClass = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  return (
    <form>
      <FormLayout>
        <FormLayout.Group>
          <div>
            <TextStyle variation="strong">Offer Title</TextStyle>
            <TextField
              value={title}
              label="title"
              labelHidden
              autoComplete="off"
              onChange={(value) => handleChange("title", value)}
            />
          </div>
          <div>
            <TextStyle variation="strong">Product</TextStyle>
            <Select
              id="product"
              cacheOptions
              menuPosition="fixed"
              menuPlacement="auto"
              options={products}
              isLoading={loading}
              onChange={(selectedOption) =>
                handleChange("productId", selectedOption.value)
              }
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            />
          </div>
          <div>
            <TextStyle variation="strong">Countries</TextStyle>
            <Select
              id="countries"
              cacheOptions
              menuPosition="fixed"
              menuPlacement="auto"
              isMulti
              options={countries}
              isLoading={loading}
              onChange={(selectedOptions) =>
                handleChange("countries", selectedOptions)
              }
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            />
          </div>
        </FormLayout.Group>
        <FormLayout.Group>
          <div>
            <TextStyle variation="strong">Quantity</TextStyle>
            <TextField
              type="number"
              value={quantity}
              label="quantity"
              labelHidden
              autoComplete="off"
              onChange={(value) => handleChange("quantity", value)}
            />
          </div>
          <div>
            <TextStyle variation="strong">Start Date</TextStyle>
            <TextField
              value={startDate}
              type="datetime-local"
              label="start date"
              labelHidden
              autoComplete="off"
              onChange={(value) => handleChange("startDate", value)}
            />
          </div>
          <div>
            <TextStyle variation="strong">End Date</TextStyle>
            <TextField
              value={endDate}
              label="end date"
              type="datetime-local"
              labelHidden
              autoComplete="off"
              onChange={(value) => handleChange("endDate", value)}
            />
          </div>
        </FormLayout.Group>
        <div>
          <RadioGroup
            name="batch"
            selectedValue={isFirst}
            onChange={(value) => handleChange("isFirst", value)}
            style={{ display: "flex", alignItems: "center", columnGap: "15px" }}
          >
            <label style={labelClass}>
              <Radio value={true} />
              <span>First Batch</span>
            </label>
            <label style={labelClass}>
              <Radio value={false} />
              <span>Last Batch</span>
            </label>
          </RadioGroup>
        </div>
      </FormLayout>
    </form>
  );
}

export default AddOfferForm;
