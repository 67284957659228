import { Badge, Button, Card, DataTable, Filters, Icon, Page, Tabs, TextStyle } from '@shopify/polaris';
import Cookies from 'js-cookie';
import Pagination from "@material-ui/lab/Pagination";
import React, { useCallback, useEffect, useState } from 'react'
import NavigationLayout from '../components/NavigationLayout'
import axios from '../axios'
import { Radio, RadioGroup } from 'react-radio-group';
import history from '../history';
import SatisfactionSurvey from './SatisfactionSurvey';
import moment from 'moment';
import { GeneralFormats } from '../util/constants';
import { getUserStatus, switchUserStatusColors } from '../util/helpers';
import ThinLoading from '../components/Loading/thin-loading.component';
import { ExportMinor, SearchMinor, ResetMinor } from '@shopify/polaris-icons';
import Axios from "axios";

const ListOfCarePlus = () => {
  const dateFormat = GeneralFormats.dateFormat;
  const [loading, setLoading] = useState(false);
  const [sortState, setSortState] = useState('&id=ASC');
  const [selected, setSelected] = useState(0);
  const [items, setItems] = useState({});
  const [loadingExportReport, setLoadingExportReport] = useState(false);
  const [isDateSearch, setIsDateSearch] = useState(false);
  const [radioGroupValue, setRadioGroupValue] = useState({
    testimonials: "email",
    referral: "email",
    beforeAfter: "email",
  });
  const [queryValue, setQueryValue] = useState({
    testimonials: 1,
    referral: '',
    beforeAfter: 1,
    startDate: '',
    endDate: '',
  });
  const [page, setPage] = useState({
    testimonials: 1,
    referral: 1,
    beforeAfter: 1,
  });
  const perPage = 20;
  const [totalPages, setTotalPages] = useState({
    testimonials: 1,
    referral: 1,
    beforeAfter: 1,
  });
  function handleRowClick(e) {
    history.push({
      pathname: `/admin/user/${e.currentTarget.id}/careplus`,
      state: {
        backUrl: '/admin/careplus',
      },
    })
  }
  const getCarePlusTestimonials = (ourRequest) => {
    setLoading(true);
    axios
      .get(`/admin/v1/users/careplus/testimonials?page=${page.testimonials}&per_page=${perPage}&search=${queryValue.testimonials}&search_by=${radioGroupValue.testimonials}`, {
        cancelToken: ourRequest.token,
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        if (res) {
          setTotalPages({
            testimonials: Math.ceil(parseInt(res.data.testimonials.total) / perPage),
          })
          setItems({
            ...items,
            testimonials: res.data.testimonials.data.map((item, key) => [
              <div
                className="itemClick"
                id={String(item.user_id)}
                onClick={(e) => handleRowClick(e)}>
                {key + 1}
              </div>,
              <div className="itemClick"
                id={String(item.user_id)}
                onClick={(e) => handleRowClick(e)}>
                {item.full_name}
              </div>
              , <div className="itemClick"
                id={String(item.user_id)}
                onClick={(e) => handleRowClick(e)}>
                {item.email}
              </div>,
              <div className="itemClick"
                id={String(item.user_id)}
                onClick={(e) => handleRowClick(e)}>
                {item.phone_number}
              </div>
            ]),
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const exportReportReferral = async () => {
    try {
      setLoadingExportReport(true);
      const response = await axios.get(
        `/admin/v1/users/careplus/referral/report?start_date=${queryValue.startDate}&end_date=${queryValue.endDate}&search=${queryValue.referral}&search_by=${radioGroupValue.referral}`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        },
      );
      if (response) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Referrals-${new Date().toLocaleDateString("en-GB").split("/").join("-")}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log("Error in exportReportReferral", error);
    } finally {
      setLoadingExportReport(false);
    }
  };

  const resetDates = () => {
    setQueryValue(prev => ({
      ...prev,
      startDate: '',
      endDate: '',
    }))
    setIsDateSearch(prev => !prev);
  }  

  const searchReferralsByDate = () => {
    setIsDateSearch(prev => !prev);
  }

  const getCarePlusReferrals = (ourRequest) => {
    setLoading(true);
    axios
      .get(`/admin/v1/users/careplus/referral?page=${page.referral}&per_page=${perPage}&start_date=${queryValue.startDate}&end_date=${queryValue.endDate}&search=${queryValue.referral}&search_by=${radioGroupValue.referral}`, {
        cancelToken: ourRequest.token,
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        if (res) {
          setTotalPages({
            referral: Math.ceil(parseInt(res.data.referral.total) / perPage),
          })
          setItems({
            ...items,
            referral: res.data.referral.data.map((item) => {
              let friendStatus = getUserStatus(item.referred?.status);
              let voucherType = item.user_voucher?.type == 'manual' ? '(Manual)' : '';
              return (
                [
                  <div className="itemClick"
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.id}
                  </div>,
                  <div className="itemClick"
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.email ? item.email : item.referee.email}
                  </div>
                  , <div className="itemClick"
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.number ? item.number : item.referee?.phone}
                  </div>,
                  <div className="itemClick"
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.friend_email ? item.friend_email : item.referred.email}
                  </div>,
                  <div className="itemClick"
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.friend_number ? item.friend_number : item.referred?.phone}
                  </div>,
                  <div className="itemClick"
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {
                      friendStatus &&
                      <Badge status={switchUserStatusColors(friendStatus)}>
                        {friendStatus}
                      </Badge>
                    }
                  </div>,
                  <div
                    className="itemClick"
                    style={{ color: item.status == 'Approved' ? 'green' : '#bf0711' }}
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item?.status}
                  </div>,
                  <div
                    className="itemClick"
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.action_done_by?.full_name}
                  </div>,
                  <div className="itemClick"
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {moment(item.created_at).format(dateFormat)}
                  </div>,
                  <div
                    className="itemClick"
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.user_voucher?.voucher ? `${item.user_voucher?.voucher?.currency} ${item.user_voucher?.voucher?.value} ${voucherType}` : null}
                  </div>,
                  <div
                    className="itemClick"
                    id={String(item.user_id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.status == 'Approved' && item.action_done_at ? moment(item.action_done_at).format(dateFormat) : ''}
                  </div>,
                ]
              )
            }),
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const getCarePlusBeforeAFters = (ourRequest) => {
    setLoading(true);
    axios
      .get(`/admin/v1/users/careplus/beforeAfters?page=${page.beforeAfter}&per_page=${perPage}&search=${queryValue.beforeAfter}&search_by=${radioGroupValue.beforeAfter}`, {
        cancelToken: ourRequest.token,
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        if (res) {
          setTotalPages({
            beforeAfters: Math.ceil(parseInt(res.data.beforeAfters.total) / perPage),
          })
          setItems({
            ...items,
            beforeAfters: res.data.beforeAfters.data.map((item, key) => [
              <div className="itemClick"
                id={String(item.user_id)}
                onClick={(e) => handleRowClick(e)}>
                {key + 1}
              </div>,
              <div className="itemClick"
                id={String(item.user_id)}
                onClick={(e) => handleRowClick(e)}>
                {item.full_name}
              </div>
              , <div className="itemClick"
                id={String(item.user_id)}
                onClick={(e) => handleRowClick(e)}>
                {item.email}
              </div>,
              <div className="itemClick"
                id={String(item.user_id)}
                onClick={(e) => handleRowClick(e)}>
                {item.phone_number}
              </div>
            ]),
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    if (selected === 0) {
      getCarePlusReferrals(ourRequest);
    } else if (selected === 1) {
      getCarePlusBeforeAFters(ourRequest);
    } else if (selected === 2) {
      getCarePlusTestimonials(ourRequest);
    }
    return () => {
      ourRequest.cancel();
    }
  }, [queryValue.testimonials, queryValue.referral, queryValue.beforeAfter, page, radioGroupValue, selected, isDateSearch])

  function handleSort(index) {
    if (index === 0) {
      if (sortState === "&id=DESC") {
        setSortState("&id=ASC");
      } else {
        setSortState("&id=DESC");
      }
    }
  }

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      setRadioGroupValue({
        testimonials: 'email',
        referral: 'email',
        beforeAfter: 'email'
      })
      setQueryValue({
        testimonials: '',
        referral: '',
        beforeAfter: ''
      })
    },
    [],
  );

  const TabsContent = [
    {
      content: <ReferralContent
        queryValue={queryValue}
        setQueryValue={setQueryValue}
        setRadioGroupValue={setRadioGroupValue}
        setPage={setPage}
        radioGroupValue={radioGroupValue}
        setItems={setItems}
        setSelected={setSelected}
        perPage={perPage}
        items={items}
        handleSort={handleSort}
        page={page}
        selected={selected}
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        loading={loading}
        loadingExportReport={loadingExportReport}
        exportReportReferral={exportReportReferral}
        searchReferralsByDate={searchReferralsByDate}
        resetDates={resetDates}
      />
    },
    {
      content: <BeforeAndAftersContent
        queryValue={queryValue}
        setQueryValue={setQueryValue}
        setRadioGroupValue={setRadioGroupValue}
        setPage={setPage}
        radioGroupValue={radioGroupValue}
        setItems={setItems}
        setSelected={setSelected}
        perPage={perPage}
        items={items}
        handleSort={handleSort}
        page={page}
        selected={selected}
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        loading={loading}
      />
    },
    {
      content: <TestimonialsContent
        queryValue={queryValue}
        setQueryValue={setQueryValue}
        setPage={setPage}
        setItems={setItems}
        setSelected={setSelected}
        radioGroupValue={radioGroupValue}
        setRadioGroupValue={setRadioGroupValue}
        perPage={perPage}
        items={items}
        handleSort={handleSort}
        page={page}
        selected={selected}
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        loading={loading}
      />
    },
    {
      content: <SatisfactionSurvey />
    },
  ]
  const tabs = [
    {
      id: 'referral',
      content: 'Referrals',
      panelID: 'referral',
    },
    {
      id: 'before-and-after',
      content: 'Before and Afters',
      panelID: 'before-and-after',
    },
    {
      id: 'testimonials',
      content: 'Testimonials',
      panelID: 'testimonials',
    },
    {
      id: 'satisfaction-survey',
      content: 'Satisfaction Surveys and NPS',
      panelID: 'satisfaction-survey',
    },
  ];

  return (
    <NavigationLayout>
      <Page
        fullWidth
        title="Care+ List"
        breadcrumbs={[{ content: "List of care+", url: "/admin/voucher-careplus" }]}
      >
        <Card>
          <Card.Section>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <Card.Section>
                {TabsContent[selected].content}
              </Card.Section>
            </Tabs>
          </Card.Section>
        </Card>
      </Page>
    </NavigationLayout>
  )
}

const TestimonialsContent = ({
  queryValue,
  items,
  handleSort,
  page,
  perPage,
  selected,
  setItems,
  setPage,
  setQueryValue,
  totalPages,
  setRadioGroupValue,
  radioGroupValue,
  setTotalPages,
  loading,
}) => {
  const handleChangeFilter = (selectedValue) => {
    setRadioGroupValue({ testimonials: selectedValue });
  };
  function handleQueryValueChange(queryValue) {
    setQueryValue({ ...queryValue, testimonials: queryValue });
  }
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue({ ...queryValue, testimonials: "" });
  }, []);
  const filters = [];
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);
  const handleChange = (event, value) => {
    setPage({
      ...page,
      testimonials: value
    });
  };

  return (
    <div>
      <Filters
        queryValue={queryValue.testimonials}
        filters={filters}
        onQueryChange={handleQueryValueChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleClearAll}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: '15px 0'
        }}
      >
        <TextStyle variation="strong">Search By : </TextStyle>
        <RadioGroup
          name="fruit"
          selectedValue={radioGroupValue.testimonials}
          onChange={handleChangeFilter}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <>
              <label style={{ marginLeft: "20px" }}>
                <Radio value="full_name" />
                Full name
              </label>
            </>
            <label style={{ marginLeft: "20px" }}>
              <Radio value="email" />
              Email
            </label>
          </div>
        </RadioGroup>
      </div>
      {
        loading ?
        <ThinLoading /> :
        <>
          {items.testimonials && <DataTable
            hideScrollIndicator
            columnContentTypes={["text", "text", "text", "text"]}
            headings={["ID", "Full Name", "Email", "Phone Number"]}
            rows={items.testimonials}
            sortable={[false, false, false, false]}
            initialSortColumnIndex={0}
            defaultSortDirection="ascending"
            onSort={handleSort}
            verticalAlign="middle"
          />}
          <div style={{ textAlign: "center", margin: "20px auto" }}>
            <Pagination
              count={totalPages.testimonials}
              page={page.testimonials}
              onChange={handleChange}
              size="large"
            />
          </div>
        </>
      }
    </div>
  )
}

const ReferralContent = ({
  queryValue,
  items,
  handleSort,
  page,
  perPage,
  selected,
  setItems,
  setPage,
  setQueryValue,
  setRadioGroupValue,
  radioGroupValue,
  totalPages,
  setTotalPages,
  loading,
  loadingExportReport,
  exportReportReferral,
  searchReferralsByDate,
  resetDates,
}) => {
  const {startDate, endDate} = queryValue;
  const handleChangeFilter = (selectedValue) => {
    setRadioGroupValue({ referral: selectedValue });
  };
  function handleQueryValueChange(queryValue) {
    setQueryValue(prev => ({
      ...prev,
      referral: queryValue
    }));
  }
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue({ ...queryValue, referral: "" });
  }, []);
  const filters = [];
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);
  const handleChange = (event, value) => {
    setPage({
      ...page,
      referral: value
    });
  };

  const handleFilterOnChange = (e) => {
    const { name, value } = e.target;
    setQueryValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const exportReferralHandler = async () => {
    await exportReportReferral();
  };
  
  return (
    <div>
      <Filters
        queryValue={queryValue.referral}
        filters={filters}
        onQueryChange={handleQueryValueChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleClearAll}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end"
        }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: '15px 0'
        }}
      >
        <TextStyle variation="strong">Search By : </TextStyle>
        <RadioGroup
          name="fruit"
          selectedValue={radioGroupValue.referral}
          onChange={handleChangeFilter}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <>
              <label style={{ marginLeft: "20px" }}>
                <Radio value="email" />
                email
              </label>
            </>
            <>
              <label style={{ marginLeft: "20px" }}>
                <Radio value="friend_email" />
                Friend's Email
              </label>
            </>
          </div>
        </RadioGroup>
      </div>
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            columnGap: "10px",
            margin: "15px 0"
          }}
        >
          <div style={{ display: "grid" }}>
            <label htmlFor="start-date">Start Date:</label>
            <input
              id="start-date"
              style={{ padding: "8px", borderRadius: "5px" }}
              type="date"
              value={startDate}
              onChange={handleFilterOnChange}
              name="startDate"
            />
          </div>
          <div style={{ display: "grid" }}>
            <label htmlFor="end-date">End Date:</label>
            <input
              id="end-date"
              style={{ padding: "8px", borderRadius: "5px" }}
              type="date"
              value={endDate}
              onChange={handleFilterOnChange}
              name="endDate"
            />
          </div>
          <Button loading={loadingExportReport} onClick={exportReferralHandler}>
            <Icon source={ExportMinor} color="base" />
          </Button>
          <Button onClick={searchReferralsByDate}>
            <Icon source={SearchMinor} color="base" />
          </Button>
          <Button onClick={resetDates}>
            <Icon source={ResetMinor} color="base" />
          </Button>
        </div>
      </div>
      {
        loading ? 
        <ThinLoading /> :
        <>
          {items.referral && <DataTable
            hideScrollIndicator
            columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "text"]}
            headings={["ID", "Email", "Phone Number", "Friend's Email", "Friend's Phone Number", "Friend's status", "Approval Status", "Action done by", "Date of Referral", "Reward Granted", "Date of Reward Granted"]}
            rows={items.referral}
            initialSortColumnIndex={0}
            defaultSortDirection="descending"
            onSort={handleSort}
            verticalAlign="middle"
          />}
          <div style={{ textAlign: "center", margin: "20px auto" }}>
            <Pagination
              count={totalPages.referral}
              page={page.referral}
              onChange={handleChange}
              size="large"
            />
          </div>
        </>
      }
    </div>
  )
}

const BeforeAndAftersContent = ({
  queryValue,
  items,
  handleSort,
  page,
  perPage,
  selected,
  setItems,
  setPage,
  setQueryValue,
  setRadioGroupValue,
  radioGroupValue,
  totalPages,
  setTotalPages,
  loading,
}) => {


  const handleChangeFilter = (selectedValue) => {
    setRadioGroupValue({ beforeAfter: selectedValue });
  };
  function handleQueryValueChange(queryValue) {
    setQueryValue({ ...queryValue, beforeAfter: queryValue });
  }
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue({ ...queryValue, beforeAfter: "" });
  }, []);
  const filters = [];
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);
  const handleChange = (event, value) => {
    setPage({
      ...page,
      beforeAfter: value
    });
  };

  return (
    <div>
      <Filters
        queryValue={queryValue.beforeAfter}
        filters={filters}
        onQueryChange={handleQueryValueChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleClearAll}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: '15px 0'
        }}
      >
        <TextStyle variation="strong">Search By : </TextStyle>
        <RadioGroup
          name="fruit"
          selectedValue={radioGroupValue.beforeAfter}
          onChange={handleChangeFilter}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <>
              <label style={{ marginLeft: "20px" }}>
                <Radio value="full_name" />
                Full name
              </label>
            </>
            <>
              <label style={{ marginLeft: "20px" }}>
                <Radio value="email" />
                Email
              </label>
            </>
          </div>
        </RadioGroup>
      </div>
      {
        loading ?
        <ThinLoading /> :
        <>
          {items.beforeAfters && <DataTable
            hideScrollIndicator
            columnContentTypes={["text", "text", "text", "text"]}
            headings={["ID", "Full Name", "Email", "Phone Number"]}
            rows={items.beforeAfters}
            sortable={[false, false, false, false]}
            initialSortColumnIndex={0}
            defaultSortDirection="descending"
            onSort={handleSort}
            verticalAlign="middle"
          />}
          <div style={{ textAlign: "center", margin: "20px auto" }}>
            <Pagination
              count={totalPages.beforeAfter}
              page={page.beforeAfter}
              onChange={handleChange}
              size="large"
            />
          </div>
        </>
      }
    </div>
  )
}
export default ListOfCarePlus