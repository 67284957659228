import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import stl from "../../assets/images/stl-thumbnail.png";
import {
  Button,
  Card,
  FormLayout,
  Layout,
  Page,
  TextField,
  TextStyle,
  Thumbnail,
  Icon,
} from "@shopify/polaris";
import axios from "../../axios";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Box, Grid, IconButton, Input } from "@material-ui/core";
import SendIcon from "@mui/icons-material/Send";
import Select2 from "react-dropdown-select";
import moment from "moment";
import { ClipboardMinor } from '@shopify/polaris-icons';
import Lightbox from "react-awesome-lightbox";

function OEMOverview() {
  let { id } = useParams();
  const [patientId, setPatientId] = useState(null);
  const [caseId, setCaseId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [modalImages, setModalImages] = useState({index:-1, images:[]})
  const [extraInformation, setExtraInformation] = useState("");
  const handleCopy = () => {
    if(!caseId){
      return;
    }
    const textToCopy = "https://clearalignerportal.com/#/case/detail/" + caseId;
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const OpenModal = (ind) =>{
    setModalImages((prev)=>({...prev,index:ind}))
  }
  const [data, setData] = useState({
    patientData: {},
    caseData: {},
    history: [],
    actions: {can_start_production:false, can_approve_tp:false}
  });
  const [caseFields, setCaseFields] = useState({
    arches: "both",
    complaints: "",
    findings: "",
    clinical_findings_v2: [],
    pretreament_procedures: [],
    list_of_procedures: [],
    dental_conditions: [],
    pain_symptopms: '',
    treatment_approach: '',
    avoid_attachments: null,
  });
  const [comments, setComments] = useState([]);
  const [message, setMessage] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientNameError , setClientNameError] = useState("");
  const optionsType = [
    { label: "Both Arches", value: "both" },
    { label: "Upper Only", value: "upper_only" },
    { label: "Lower Only", value: "lower_only" },
  ];
  const [archesValue, setArchesValue] = useState([
    { label: "Both Arches", value: "both" },
  ]);
  const handleArches = useCallback((value) => setArchesValue(value));
  function containsArabicLetters(str) {
    var arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(str);
  }
  function removeHtmlTags(input) {
    return input.replace(/<\/?[^>]+(>|$)/g, "");
}

  const ConfirmTP = async() =>{
    setLoading(true)
    let res = await axios.get(
      `/admin/v1/oem/desginer/approve_3d/${id}`,{headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      }})
    setLoading(false)
    window.location.reload();
  }
  const StartProduction = async() =>{
    setLoading(true)
    let res = await axios.get(
      `/admin/v1/oem/desginer/start_production/${id}`,{headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      }})
    setLoading(false)
    window.location.reload();
  }
  const ModifyTP = async(action_type) =>{
    setLoading(true)
    let res = await axios.get(
      `/admin/v1/oem/desginer/case/${id}/${action_type}`,{headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      }})
    setLoading(false)
    window.location.reload();
  }

  const sendComment = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!message) {
      return;
    }
    let body = {
      message,
      caseId,
    };
    let res = await axios.post(
      `/admin/v1/oem/desginer/case/${id}/sendComment`,
      body,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    if (res.data.success) {
      setComments([...res.data.comments]);
    }
    setMessage("");
    setLoading(false);
  };

  const createCase = async () => {
    setLoading(true);
    setClientNameError(false)
    let body = {
      arches: archesValue[0].value,
      extra_information: extraInformation,
      clientName,
    };
    let res = await axios.post(`/admin/v1/oem/desginer/case/${id}`, body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });

    if (res.data.success) {
      window.location.reload();
    }else{
      if(res.data.reason =="missing_clientName"){
        setClientNameError(true)
      }
    }
    setLoading(false);
  };


  useEffect(() => {
    axios
      .get(`/admin/v1/oem/desginer/case/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then(async (res) => {
        let history = [...res.data.history]
        let patientData = {
          ID: res.data.id,
          FIRSTNAME: res.data.first_name,
          LASTNAME: res.data.last_name,
          EMAIL: res.data.email,
          COUNTRY: res.data.country,
          screening: res.data.screening,
          xray: res.data.xray,
          scan: res.data.scan,
        };
        let caseData = {};
        if (res.data.case) {
          caseData = {
            STATUS_NAME: res.data.case?.STATUS_NAME?.replace("_", " "),
            ATTACHEMENTS_NEEDED: res.data.case?.ATTACHEMENTS_NEEDED?.replace(
              "_",
              " "
            ),
            IPR_COMMENT: res.data.case?.IPR_COMMENT?.replace("_", " "),
            IPR_LEVEL: res.data.case?.IPR_LEVEL?.replace("_", " "),
            IPR_REQUESTED: res.data.case?.IPR_REQUESTED?.replace("_", " "),
            TREATMENT_STATE: res.data.case?.TREATMENT_STATE?.replace("_", " "),
            TREATMENT_TYPE: res.data.case?.TREATMENT_TYPE?.replace("_", " "),
            WHICH_ARCHES: res.data.case?.WHICH_ARCHES?.replace("_", " "),
            SIMULATION: res.data.case?.SIMULATION,
          };
          setExtraInformation(res.data.case?.EXTRA_INFORMATION)
        }
        let actions = {can_start_production:res.data.can_start_production, can_approve_tp:res.data.can_approve_tp,}
        setData((prev) => ({ ...prev, patientData, caseData, history, actions }));
        let preTreatmentProcedures = [];
        let clinicalFindingsV2 = [];
        if(res.data.information.clinical_findings_v2) {
          res.data.information.clinical_findings_v2.map((item) => {
            let desc = `Condition: ${item?.condition?.replace('_', ' ')}`;
            if(item?.tooth_number) {
              desc += ` - Tooth Number: ${item?.tooth_number}`;
            }
            if(item?.procedures) {
              preTreatmentProcedures.push(`${item?.condition?.replace('_', ' ')}: ${item?.procedures}`);
            }
            return desc;
          }).join(', ');
        }
        let avoidAttachments = res.data.information?.details?.avoid_attachments;
        let avoidAttachmentsVal = '';
        if(avoidAttachments) {
          if(avoidAttachments.no_anterior_attachments) {
            avoidAttachmentsVal = "Patient doesn't want attachments on anterior teeth (limited movements and will affect final teeth position)";
          } else if (avoidAttachments.no_attachments) {
            avoidAttachmentsVal = "Patient doesn't want attachments on any teeth";
          } else {
            if(avoidAttachments.upper) {
              avoidAttachmentsVal = `Avoid attachments on upper teeth: ${avoidAttachments.upper.join(', ')} - `;
            }
            if(avoidAttachments.lower) {
              avoidAttachmentsVal += `Avoid attachments on lower teeth: ${avoidAttachments.lower.join(', ')}`;
            }
          }
        }
        setCaseFields((prev) => ({
          ...prev,
          complaints: res.data.information.problem_question,
          findings: res.data.information.dental_history,
          list_of_procedures: res.data.information.dental_history_na && res.data.information.list_of_procedures ? res.data.information.list_of_procedures.map((item) => {
            return item;
          }).join(', ') : [],
          clinical_findings_v2: clinicalFindingsV2,
          pretreament_procedures: preTreatmentProcedures.join(', '),
          dental_conditions: res.data.information?.details?.dental_conditions ? res.data.information.details.dental_conditions.map((item) => {
            return item.replace('_', ' ');
          }).join(', ') : [],
          pain_symptopms: res.data.information.pain_symptoms,
          treatment_approach: res.data.information.details?.treatment_approach?.replace('-', ' '),
          avoid_attachments: avoidAttachmentsVal,
        }));
        if (res.data.patient_case) {
          setCaseId(res.data.patient_case.case_id);
          setPatientId(res.data.patient_case.patient_id);
        }
        setComments([...res.data.comments]);
        let screeningPhotos = [];
        if(patientData?.screening?.length){
          screeningPhotos = patientData.screening.map(img => ({ id: img.name, url: img.file_url }));
        }
        if(patientData?.xray?.x_ray_url){
          screeningPhotos.push({id: 'x-ray', url: patientData?.xray?.x_ray_url})
        }
        setModalImages({index:-1,images:screeningPhotos})
        setLoading(false);
      });
  }, []);

  return (
    <Page fullWidth>
      <FormLayout>
        <Layout>
          <Layout.Section oneHalf>
            <Card title="User Details" sectioned>
              <Card.Section>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    minHeight:"20vh"
                  }}
                >
                  <FormLayout>
                    <TextStyle>
                      Full Name: {data.patientData.FIRSTNAME}{" "}
                      {data.patientData.LASTNAME}
                      <div style={{ width: "50%" }}>
                        {!caseId &&
                          containsArabicLetters(
                            data.patientData.FIRSTNAME +
                              " " +
                              data.patientData.LASTNAME
                          ) && (
                            <>
                              <TextField
                                placeholder="Enter Client Name in English"
                                value={clientName}
                                onChange={setClientName}
                                error={clientNameError}
                              />
                            </>
                          )}
                      </div>
                    </TextStyle>
                    <TextStyle>{data.patientData.EMAIL}</TextStyle>
                    <TextStyle>{data.patientData.COUNTRY}</TextStyle>
                    <TextStyle>BASMA User ID: {data.patientData.ID}</TextStyle>
                    <TextStyle>OEM Case ID: {caseId}</TextStyle>
                  </FormLayout>
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section oneHalf>
            <Card title="Summary" sectioned>
              <Card.Section>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    minHeight:"20vh"
                  }}
                >
                  <TextStyle>OEM Company: Kline</TextStyle>
                  <TextStyle>Status: {data.caseData.STATUS_NAME}</TextStyle>
                  <TextStyle>
                  {caseId
                  ? <div style={{display:"flex", gap:"20px"}}>
                    
                       <a href={"https://clearalignerportal.com/#/case/detail/" + caseId} target="_blank">Link to OEM Portal</a>
                        <Button plain monochrome onClick={handleCopy}>
                          <Icon source={ClipboardMinor} />
                        </Button>
                      {copied && <TextStyle variation="positive">Text copied!</TextStyle>}
                      </div>
                      : ""}
                  </TextStyle>
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section oneHalf>
            <Card
              title="Request Treatment Plan Design"
              primaryFooterAction={{
                disabled: loading || caseId,
                content: "Submit",
                onClick: createCase,
              }}
            >
              <Card.Section>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    gap: "20px",
                    minHeight:"20vh"
                  }}
                >
                  <FormLayout>
                    <TextStyle>
                      Chief Complaints: {caseFields.complaints}
                    </TextStyle>
                    <TextStyle>
                      Clinical Findings: {
                        caseFields.findings ? caseFields.findings :
                        caseFields.clinical_findings_v2
                      }
                    </TextStyle>
                    {
                      caseFields.dental_conditions.length > 0 &&
                      <TextStyle>
                        Dental Conditions: {caseFields.dental_conditions}
                      </TextStyle>
                    }
                    {
                      caseFields.pain_symptopms &&
                      <TextStyle>
                        Pain/Symptoms: {caseFields.pain_symptopms}
                      </TextStyle>
                    }
                    {
                      caseFields.list_of_procedures.length > 0 &&
                      <TextStyle>
                        Patient needs to do the following pre-treatment procedures before starting aligner treatment: {caseFields.list_of_procedures}
                      </TextStyle>
                    }
                    {
                      caseFields.pretreament_procedures.length > 0 &&
                      <TextStyle>
                        Pre-treatment Procedures: {caseFields.pretreament_procedures}
                      </TextStyle>
                    }
                    {caseId ? (
                      <TextStyle>
                        Arches: {data.caseData.WHICH_ARCHES}
                      </TextStyle>
                    ) : (
                      <Select2
                        onChange={(newValue) => handleArches(newValue)}
                        values={archesValue}
                        options={optionsType}
                        disabled={loading || caseId}
                      />
                    )}
                    {
                      caseFields.treatment_approach &&
                      <TextStyle>
                        Treatment Approach: {caseFields.treatment_approach}
                      </TextStyle>
                    }
                    {
                      caseFields.avoid_attachments &&
                      <TextStyle>
                        {caseFields.avoid_attachments}
                      </TextStyle>
                    }
                    <TextStyle>
                      Treatment Type: {data.caseData.TREATMENT_TYPE ?? "Full"}
                    </TextStyle>
                    <TextStyle>
                      IPR:{" "}
                      {data.caseData.IPR_REQUESTED ?? "KLine Recommendation"}
                    </TextStyle>
                    <TextStyle>
                      IPR level:{" "}
                      {data.caseData.IPR_LEVEL ?? "KLine Recommendation"}
                    </TextStyle>
                    <TextStyle>
                      Attachments:{" "}
                      {data.caseData.ATTACHEMENTS_NEEDED ??
                        "KLine Recommendation"}
                    </TextStyle>
                    <Input
                      variant="outlined"
                      id="input-comment"
                      multiline
                      disabled={loading || caseId}
                      value={extraInformation}
                      style={{ fontSize: "14px", width: "100%" }}
                      placeholder="BASMA Treatment Plan Details"
                      onChange={(e) => {
                        setExtraInformation(e.target.value);
                      }}
                    ></Input>
                  </FormLayout>
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section oneHalf>
            <Card title="Received Treatment Plan">
              <Card.Section>
                <div
                  style={{
                    width: "100%",
                    gap: "20px",
                    minHeight:"20vh"
                  }}
                >
                  <FormLayout>
                    {data.caseData.SIMULATION ? 
                    <a href={data.caseData.SIMULATION}>3D Viewer</a> : <p>3D Viewer: </p>}
                    <TextStyle>IPR</TextStyle>
                    <TextStyle>Attachments:</TextStyle>
                    <TextStyle>Steps Upper:</TextStyle>
                    <TextStyle>Steps Lower:</TextStyle>
                    <TextStyle>Months:</TextStyle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "80%",
                        margin: "auto",
                      }}
                    >
                      <button
                        className="treatment-actions"
                        type="confirm"
                        style={{
                          opacity: loading || !data.actions.can_approve_tp ? "0.5" : "initial",
                        }}
                        disabled={loading || !data.actions.can_approve_tp}
                        onClick={ConfirmTP}
                      >
                        Approve TP
                      </button>
                      <button
                        className="treatment-actions"
                        type="modify"
                        style={{
                          opacity: loading || !data.actions.can_approve_tp ? "0.5" : "initial",
                        }}
                        disabled={loading || !data.actions.can_approve_tp}
                        onClick={()=>{ModifyTP('requestModifications')}}
                      >
                        Request Modification
                      </button>
                      <button
                        className="treatment-actions"
                        type="reject"
                        style={{
                          opacity: loading || !data.actions.can_approve_tp ? "0.5" : "initial",
                        }}
                        disabled={loading || !data.actions.can_approve_tp}
                        onClick={()=>{ModifyTP('decline')}}
                      >
                        Decline
                      </button>
                    </div>
                  </FormLayout>
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section oneHalf>
            <Card title="Case Records">
              <Card.Section>
                <div style={{ display: "flex" }}>
                  <b>Photos</b>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "40px",
                      marginBottom: "40px",
                      overflowX: "auto",
                      gap: "20px",
                      minHeight:"20vh"
                    }}
                  >
                    {data?.patientData?.screening?.map((img, k) => {
                          let imageUrl = img.name;
                          let imageNameArray = imageUrl.split("_");
                          imageNameArray.pop();
                          let imageName = imageNameArray.join(" ");
                          return (
                            <div
                              key={k}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div
                                id="step3a"
                                style={{
                                  marginBottom: 10,
                                }}
                                onClick={(e) => OpenModal(k)}
                              >
                                <Thumbnail
                                  size="large"
                                  source={img.file_url}
                                />
                              </div>
                              <TextStyle>{imageName}</TextStyle>
                            </div>
                          );
                        })}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <b>Scans</b>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "40px",
                      marginBottom: "40px",
                      overflowX: "auto",
                      gap: "20px",
                    }}
                  >
                    {data?.patientData?.scan?.map((img, k) => {
                          let imageUrl = img.name;
                          let imageNameArray = imageUrl.split("_");
                          let imageName = imageNameArray.join(" ");
                          return (
                            <div
                              key={k}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div
                                id="step3a"
                                style={{
                                  marginBottom: 10,
                                }}
                              >
                                <Thumbnail size="large" source={stl} />
                              </div>
                              <TextStyle>{imageName}</TextStyle>
                            </div>
                          );
                        })}
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <b>X-RAY</b>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "40px",
                      marginBottom: "40px",
                      overflowX: "auto",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // marginInlineStart: 20,
                        alignItems: "center",
                      }}
                    >
                      <div
                        id="step3a"
                        style={{
                          marginBottom: 10,
                        }}
                        onClick={(e) => OpenModal(modalImages.images.length -1)}
                      >
                        <Thumbnail
                          size="large"
                          source={
                            data.patientData?.xray?.x_ray_url
                              ? data.patientData?.xray?.x_ray_url
                              : ""
                          }
                        />
                      </div>
                      <TextStyle>X-RAY</TextStyle>
                    </div>
                  </div>
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section oneHalf>
            <Card title="Comments">
              <div style={{ padding: "20px 0px 20px 2%" , }}>
                <Grid
                  style={{
                    height:"40vh",
                    overflowY: "auto",
                    margin: "10px 0px 10px 0px",
                    
                  }}
                >
                  {comments.map((comment, ind) => {
                    let messageDate = moment
                      .utc(comment.LAST_UPDATE)
                      .add(1, "hour")
                      .format("DD-MM-yy hh:mm A");
                    return (
                      <div
                        key={ind}
                        style={{
                          width: "fit-content",
                          minWidth: "20%",
                          backgroundColor: "white",
                          padding: "5px",
                          boxShadow: "rgba(0, 0, 0, 0.2) 1px 1px 1px 1px",
                          borderRadius: "0px 5px 5px",
                          position: "relative",
                          marginBottom: "2%",
                          marginLeft: "unset",
                          marginRight: "20px",
                          maxHeight: "35%",
                          overflowY: "auto",
                        }}
                      >
                        <div
                          className="case-title"
                          style={{
                            alignItems: "center",
                            color: "#4f81a1",
                            cursor: "pointer",
                            display: "flex",
                            fontSize: "13px",
                            fontWeight: 500,
                            margin: " 0 0 8px",
                          }}
                        >
                          {comment.USER}
                        </div>
                        {removeHtmlTags(comment.COMMENT)}
                        <div
                          className="time"
                          style={{
                            fontSize: "12px",
                            color: "#00000073",
                            textAlign: "end",
                          }}
                        >
                          {messageDate}
                        </div>
                      </div>
                    );
                  })}
                </Grid>
                <form onSubmit={sendComment}>
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <Input
                      variant="outlined"
                      id="input-comment"
                      multiline
                      disabled={loading || !caseId}
                      value={message}
                      style={{ fontSize: "12px", width: "80%" }}
                      placeholder="Enter your Comment"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    ></Input>
                    <IconButton type="submit" disabled={loading}>
                      <SendIcon
                        style={{
                          position: "relative",
                          width: "1.5em",
                          height: "1.5em",
                        }}
                      />
                    </IconButton>
                  </div>
                </form>
              </div>
            </Card>
          </Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section oneHalf>
            <Card
              title="Aligner Production"
              primaryFooterAction={{
                content: "Start Aligner Production",
                onClick: StartProduction,
                disabled: loading || !data.actions.can_start_production
              }}
              footerActionAlignment="left"
            >
              <Card.Section>
                <div style={{ minHeight:"20vh" }}></div>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section oneHalf>
            <Card title="Activity Log">
              <Card.Section>
                <div style={{ overflowY: "auto", minHeight:"30vh" }}>
                  {data.history.map((workflow) => {
                    let workflowDate = moment.utc(workflow.created_at).local()
                    .format("DD-MM-yy hh:mm A");
                    return(
                    <div
                      style={{
                        padding: "16px",
                        borderBottom: "1px solid #efe9e9",
                        fontSize: "14px",
                      }}
                    >
                      <div>Action: {workflow.action}</div>
                      <div>Actor: {workflow.admin.full_name}</div>
                      <div style={{ textAlign: "end" }}>
                        {workflowDate}
                      </div>
                    </div>
                  )})}
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </FormLayout>
      {modalImages.index>=0 &&<Lightbox 
                startIndex={modalImages.index}
                onClose={() => OpenModal(-1)}
                images={modalImages.images}
              />
      }
    </Page>
  );
}

export default OEMOverview;
