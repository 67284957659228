import React, { useState } from 'react'
import CaseDiscussionChat from './CaseDiscussionChat/CaseDiscussionChat'
import ChatIcon from '@mui/icons-material/Chat';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const CaseDiscussion = ({ status = false }) => {
    const [openChat, setOpenChat] = useState({ firstOpen: true, status})
    const [previewImages, setPreviewImages] = useState({ images: [], startIndex: 0 });

    function handleChatOpen() {
        setOpenChat((prev) => {
            if (prev.status == false) {
                return { ...prev, status: true }
            } else {
                return { firstOpen: false, status: false }
            }
        });
    }

    return (
        <div>
            <div className={"basma-sidebar-chat" + (openChat.status ? " expanded" : "")}>
                <CaseDiscussionChat setOpenChat={setOpenChat} openChat={openChat} setPreviewImages={setPreviewImages} />
            </div>
            <div className={"basma-chat-button" + (openChat.status ? " expanded" : "")} onClick={handleChatOpen}>
                <ChatIcon />
            </div>
            {
                previewImages.images.length ?
                <div>
                    <Lightbox startIndex={previewImages.startIndex} onClose={() => setPreviewImages({ images: [], startIndex: 0 })} images={previewImages.images.map((val) => val.data !== null && process.env.REACT_APP_AWS_URL + val.data)} />
                </div> :
                null
            }
        </div>
    )
}

export default CaseDiscussion