import React, { useEffect, useRef, useState } from "react";
import ScansDropZone from "./ScansDropZone";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { Typography } from "@material-ui/core";
import { adminActions, caseRecordTypes, GeneralFormats, IssueTypes, ReviewStatus } from "../util/constants";
import ApprovalActions from "./ApprovalActions";
import moment from "moment";
import stl from "../assets/images/stl-thumbnail.png";
import DisapprovalModal from "./DisapprovalModal";
import axios from "../axios";
import Cookies from "js-cookie";
import AlertDeleteDialog from "./Popup/alert-delete-dialog.component";
import axiosAws from "axios";
import JSZip from "jszip";

const Scans = ({
  scans,
  appointmentType,
  disable,
  segmentationTechnicians,
  appointmentId,
  handleDataFetched,
  userId,
  classes,
  setErrorMsg,
  loading,
  setLoading,
  scannerPlaceholders,
  delayedActionJob,
  updateReviewStatus,
  appointmentNumber,
  parentId,
  hasDelayedAction,
}) => {
  const dateFormat = GeneralFormats.dateFormat;
  const substituteFiles = useRef(null);
  const [deletePhoto, setDeletePhoto] = useState({ id: null });
  const [isReviewed, setIsReviewed] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [substituteScan, setSubstituteScan] = useState({
    document: false,
    lower_scan: false,
    bite_scan_1: false,
    bite_scan_2: false,
    scans_folder: false,
  });
  const [reviewData, setReviewData] = useState({
    comment: "",
    commentError: "",
    loading: false,
    modalOpened: false,
    tpOptionError: "",
    status: "",
  });
  const [uploadedFile, setUploadedFile] = useState({
    document: 0,
    lower_scan: 0,
    bite_scan_1: 0,
    bite_scan_2: 0,
    substitute_document: 0,
    substitute_lower_scan: 0,
    substitute_bite_scan_1: 0,
    substitute_bite_scan_2: 0,
    scans_folder: 0,
    substitute_scans_folder: 0,
  });
  const [open, setOpen] = useState(false);
  const [fileImages, setFileImages] = useState([]);
  const [images, setImages] = useState([]);
  const [scanIssues, setScanIssues] = useState([]);
  const [options, setOptions] = useState([]);
  const [tpOption, setTpOption] = useState(null);
  const [imageKeys, setImageKeys] = useState([]);

  const handleFileUpload = (type, value) => {
    setUploadedFile(prev => ({
        ...prev,
        [type]: value,
    }));
}

  const handleSave = (items) => {
    if (items.length) {
      setErrorMsg("");
      setLoading((prev) => ({
        ...prev,
        disabled: true,
        success: false,
      }));
      const form_data = new FormData();
      items.forEach((item) => {
        form_data.append(item.key, item.value);
      });
      form_data.append("procedure", appointmentType?.procedure);
      form_data.append("user_scan_info_id", appointmentId);
      form_data.append("id", scans?.id);
      form_data.append("parent_id", scans?.parent_id);
      axios
        .post(`/admin/v2/users/${userId}/case-record-scans`, form_data, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((res) => {
          handleDataFetched();
          if (res.data.success) {
            setLoading((prev) => ({
              ...prev,
              success: true,
              disabled: false,
            }));
          }
        })
        .catch((err) => {
          setErrorMsg("Upload failed. Please try again.");
          setLoading((prev) => ({
            ...prev,
            success: false,
            disabled: false,
          }));
          console.log(err);
        });
    } else {
      setErrorMsg("Please upload scan files");
    }
  };

  const handleSaveSubstituteFiles = (items) => {
    if (items.length) {
      setErrorMsg("");
      setLoading((prev) => ({
        ...prev,
        disabled: true,
        success: false,
      }));
      const form_data = new FormData();
      form_data.append("original_scan_id", scans?.id);
      items.forEach((item) => {
        form_data.append(item.key, item.value);
      });
      axios
        .post(
          `/admin/v2/users/${userId}/case-record-substitute-scans`,
          form_data,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          }
        )
        .then((res) => {
          handleDataFetched();
          if (res.data.success) {
            setLoading((prev) => ({
              ...prev,
              success: true,
              disabled: false,
            }));
          }
        })
        .catch((err) => {
          setErrorMsg("Upload failed. Please try again.");
          setLoading((prev) => ({
            ...prev,
            success: false,
            disabled: false,
          }));
          console.log(err);
        });
    } else {
      setErrorMsg("Please upload scan files");
    }
  };

  const handleChangeSubstitutionScan = (type) => {
    setSubstituteScan((prev) => ({
      ...prev,
      [type]: !substituteScan?.[type],
    }));
    if (!substituteScan?.[type]) {
      substituteFiles.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleUploadError = (type, err) => {
    handleFileUpload(type, 0);
    setErrorMsg("Upload failed. Please try again.");
    setLoading((prev) => ({
      ...prev,
      disabled: false,
    }));
    console.log(err);
  };

  const saveInAws = (value, type) => {
    handleFileUpload(type, 1);
    setErrorMsg("");
    let imageType = value.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", value.name);
    axios
      .post("/admin/v1/images/s3", form_data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        let key = res.data.key;
        const url2 = res.data.url;
        axiosAws
          .put(url2, value, {
            headers: {
              "x-amz-acl": "public-read-write",
              "Content-Type": imageType,
            },
            onUploadProgress: (event) => uploadConfig(event, type),
          })
          .then(() => {
            let items = [
              {
                key: type,
                value: key,
              },
              {
                key: type === "document" ? "name" : `${type}_name`,
                value: value.name,
              }
            ];
            if (
              type === "document" ||
              type === "lower_scan" ||
              type === "bite_scan_1" ||
              type === "bite_scan_2"
            ) {
              handleSave(items);
            } else {
              handleSaveSubstituteFiles(items);
            }
          })
          .catch((err) => {
            handleUploadError(type, err);
          });
      })
      .catch((err) => {
        handleUploadError(type, err);
      });
  };

  const handleChange = (files, id) => {
    setLoading((prev) => ({
      ...prev,
      disabled: true,
      success: false,
    }));
    setErrorMsg("");
    const file = files[0];
    saveInAws(file, id);
  };

  const handleReviewStatus = async (status) => {
    if (
      status != ReviewStatus.approved &&
      !scanIssues.length &&
      !reviewData.comment
    ) {
      setReviewData((prev) => ({
        ...prev,
        commentError: "Please enter a comment",
      }));
    } else {
      let data = {
        review_status: status,
        procedure: appointmentType?.procedure,
        appointment_id: appointmentId,
        record_id: scans?.id,
        record_type: caseRecordTypes.UserCaseRecordScan,
      };
      if (status != ReviewStatus.approved) {
        let comment = `
          Appointment ${appointmentNumber} - ${appointmentType?.procedure}<br/>
          Status: Scans ${status}
        `;
        if (options.length && !scanIssues.length) {
          return;
        }
        if (!tpOption && status === ReviewStatus.approvedWithIssues) {
          setReviewData((prev) => ({
            ...prev,
            tpOptionError: "Please select an option",
          }));
          return;
        }
        if (scanIssues.length) {
          comment += "<br/>Scan issues: ";
          comment += scanIssues.map((issue) => issue.label).join(", ");
        }
        if (tpOption) {
          comment += `<br/>TP option: ${tpOption.label}`;
        }
        if(reviewData.comment) {
          comment += `<br/>Notes: ${reviewData.comment}`;
        }
        data = {
          ...data,
          comment,
          images: imageKeys,
          issues: scanIssues.map((issue) => issue.id),
        };
      }
      let body = {
        action: adminActions.caseRecordsStatus,
        data,
      }
      await updateReviewStatus(body, 'Status Updated', `${caseRecordTypes.UserCaseRecordScan}-${parentId}`);
      setReviewData((prev) => ({
        ...prev,
        loading: false,
        modalOpened: false,
      }));
    }
  };

  const handleChangeReview = (status) => {
    setLoading((prev) => ({
      ...prev,
      disableReviewBtn: status === ReviewStatus.approved,
    }));
    if (status === ReviewStatus.approved) {
      handleReviewStatus(status);
    } else {
      setReviewData((prev) => ({
        ...prev,
        modalOpened: true,
        status,
      }));
    }
  };

  const handleChangeModal = () => {
    setReviewData((prev) => ({
      ...prev,
      modalOpened: false,
    }));
    setScanIssues([]);
  };

  const handleChangeComment = (newValue) => {
    setReviewData((prev) => ({
      ...prev,
      comment: newValue,
    }));
  };

  const handleChangeSegmentationTechnician = (type, id) => {
    setScanIssues((prev) => ({
      ...prev,
      segmentation_technician_id: id,
    }));
    let items = [
      {
        key: type,
        value: id,
      },
    ];
    if (type === "segmentation_technician_id") {
      handleSave(items);
    } else {
      handleSaveSubstituteFiles(items);
    }
  };

  const handleClickOpen = (idData) => {
    setDeletePhoto({ id: idData });
    setOpen(true);
  };

  const handleDelete = () => {
    const { id } = deletePhoto;
    setDeleteLoading(true);
    axios
      .delete(`/admin/v2/case-record-scans/delete/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then(() => {
        handleDataFetched();
      })
      .catch((err) => {
        console.log("handleDelete err", err);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const zipScanFiles = async (arrayFiles, type) => {
    const zip = new JSZip();
    // Function to read a File and add it to the zip.
    const addFileToZip = (file) => {
      console.log("file", file);
      return new Promise(async (resolve) => {
        // Read the file as an ArrayBuffer.
        const fileData = await new Promise((resolveFile, rejectFile) => {
          const reader = new FileReader();
          reader.onload = () => resolveFile(reader.result);
          reader.onerror = rejectFile;
          reader.readAsArrayBuffer(file);
        });

        // Add the file to the zip archive with its original name.
        zip.file(file.name, fileData, {
          compression: "DEFLATE",
          compressionOptions: { level: 9 },
        });
        resolve();
      });
    };
    const promises = Array.from(arrayFiles).map(addFileToZip);
    try {
      await Promise.all(promises);
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const form_data = new FormData();
      form_data.append("content_type", "text/plain");
      form_data.append("file_name", `user_${userId}_Scans-Folder.zip`);
      try {
        const response = await axios.post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        });
        if (response.status === 200) {
          const { key, url } = response.data;
          let items = [
            {
              key: type,
              value: key,
            },
            {
              key: `${type}_name`,
              value: `user_${userId}_Scans-Folder.zip`,
            },
          ];
          await axios
            .put(url, zipBlob, {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": "text/plain",
              },
              onUploadProgress: (event) => uploadConfig(event, type),
            })
            .then(() => {
              if (type === "scans_folder") {
                handleSave(items);
              } else {
                handleSaveSubstituteFiles(items);
              }
            });
        }
      } catch (error) {
        handleFileUpload(type, 0);
        console.log("Error in saving zip file on aws", error);
      }
    } catch (error) {
      handleFileUpload(type, 0);
      console.error("Error zipping files:", error);
    }
  };

  const handleFolderUpload = (event, type) => {
    handleFileUpload(type, 1);
    const files = event?.target?.files;
    zipScanFiles(files, type);
  };

  useEffect(() => {
    setIsReviewed(scans?.review_status ? true : false);
    setSubstituteScan({
      document: scans?.substitute_file?.scan_files?.find(
        (file) => file.name === "document"
      )
        ? true
        : false,
      lower_scan: scans?.substitute_file?.scan_files?.find(
        (file) => file.name === "lower_scan"
      )
        ? true
        : false,
      bite_scan_1: scans?.substitute_file?.scan_files?.find(
        (file) => file.name === "bite_scan_1"
      )
        ? true
        : false,
      bite_scan_2: scans?.substitute_file?.scan_files?.find(
        (file) => file.name === "bite_scan_2"
      )
        ? true
        : false,
      scans_folder: scans?.substitute_file?.scan_files?.find(
        (file) => file.name === "scans_folder"
      )
        ? true
        : false,
    });
  }, [scans]);

  return (
    <div>
      {scans?.id && (
        <div>
          {scannerPlaceholders === 1 ? (
            <Box className={classes.box}>
              <ScansDropZone
                uploadProgress={uploadProgress?.scans_folder}
                disable={isReviewed || disable || loading.disabled}
                value={
                  uploadedFile.scans_folder ||
                  scans.scan_files.find(
                    (scanFile) => scanFile.name === "scans_folder"
                  )?.file_url
                    ? stl
                    : null
                }
                id="scans_folder"
                handleChange={handleFolderUpload}
                message="Scans Folder"
                name={
                  scans.scan_files.find(
                    (scanFile) => scanFile.name === "scans_folder"
                  )?.file_name
                }
                createdAt={
                  scans.scan_files.find(
                    (scanFile) => scanFile.name === "scans_folder"
                  )?.created_at
                }
                updatedAt={
                  scans.scan_files.find(
                    (scanFile) => scanFile.name === "scans_folder"
                  )?.updated_at
                }
                disabled={
                  isReviewed ||
                  deleteLoading ||
                  (!scans.scan_files.find(
                    (scanFile) => scanFile.name === "scans_folder"
                  )?.id ? true : false)
                }
                handleDelete={() =>
                  handleClickOpen(
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "scans_folder"
                    )?.id
                  )
                }
                oemReplaced={
                  appointmentType?.procedure === "First Visit" ||
                    appointmentType?.procedure === "Walk-in"
                    ? substituteScan.scans_folder
                    : null
                }
                handleOemReplaced={() =>
                  handleChangeSubstitutionScan("scans_folder")
                }
                oemDisabled={isReviewed}
                folderUpload
              />
            </Box>
          ) : (
            <>
              <Box className={classes.box}>
                <ScansDropZone
                  uploadProgress={uploadProgress?.document}
                  disable={isReviewed || disable || loading.disabled}
                  value={
                    uploadedFile.document ||
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "document"
                    )?.file_url
                      ? stl
                      : null
                  }
                  id="document"
                  handleChange={handleChange}
                  message="Upload upper scan"
                  name={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "document"
                    )?.file_name
                  }
                  createdAt={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "document"
                    )?.created_at
                  }
                  updatedAt={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "document"
                    )?.updated_at
                  }
                  disabled={
                    isReviewed ||
                    deleteLoading ||
                    (!scans.scan_files.find(
                      (scanFile) => scanFile.name === "document"
                    )?.id ? true : false)
                  }
                  handleDelete={() =>
                    handleClickOpen(
                      scans.scan_files.find(
                        (scanFile) => scanFile.name === "document"
                      )?.id
                    )
                  }
                  oemReplaced={
                    appointmentType?.procedure === "First Visit" ||
                      appointmentType?.procedure === "Walk-in"
                      ? substituteScan.document
                      : null
                  }
                  handleOemReplaced={() =>
                    handleChangeSubstitutionScan("document")
                  }
                  oemDisabled={isReviewed}
                />
                <ScansDropZone
                  uploadProgress={uploadProgress?.lower_scan}
                  disable={isReviewed || disable || loading.disabled}
                  value={
                    uploadedFile.lower_scan ||
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "lower_scan"
                    )?.file_url
                      ? stl
                      : null
                  }
                  id="lower_scan"
                  handleChange={handleChange}
                  message="Upload lower scan"
                  name={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "lower_scan"
                    )?.file_name
                  }
                  createdAt={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "lower_scan"
                    )?.created_at
                  }
                  updatedAt={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "lower_scan"
                    )?.updated_at
                  }
                  scanFileId={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "lower_scan"
                    )?.id
                  }
                  disabled={
                    isReviewed ||
                    deleteLoading ||
                    (!scans.scan_files.find(
                      (scanFile) => scanFile.name === "lower_scan"
                    )?.id ? true : false)
                  }
                  handleDelete={() =>
                    handleClickOpen(
                      scans.scan_files.find(
                        (scanFile) => scanFile.name === "lower_scan"
                      )?.id
                    )
                  }
                  oemReplaced={
                    appointmentType?.procedure === "First Visit" ||
                      appointmentType?.procedure === "Walk-in"
                      ? substituteScan.lower_scan
                      : null
                  }
                  handleOemReplaced={() =>
                    handleChangeSubstitutionScan("lower_scan")
                  }
                  oemDisabled={isReviewed}
                />
              </Box>
              <Box className={classes.box}>
                <ScansDropZone
                  uploadProgress={uploadProgress?.bite_scan_1}
                  disable={isReviewed || disable || loading.disabled}
                  value={
                    uploadedFile.bite_scan_1 ||
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "bite_scan_1"
                    )?.file_url
                      ? stl
                      : null
                  }
                  id="bite_scan_1"
                  handleChange={handleChange}
                  message="Bite scan 1"
                  name={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "bite_scan_1"
                    )?.file_name
                  }
                  createdAt={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "bite_scan_1"
                    )?.created_at
                  }
                  updatedAt={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "bite_scan_1"
                    )?.updated_at
                  }
                  disabled={
                    isReviewed ||
                    deleteLoading ||
                    (!scans.scan_files.find(
                      (scanFile) => scanFile.name === "bite_scan_1"
                    )?.id ? true : false)
                  }
                  handleDelete={() =>
                    handleClickOpen(
                      scans.scan_files.find(
                        (scanFile) => scanFile.name === "bite_scan_1"
                      )?.id
                    )
                  }
                  oemReplaced={
                    appointmentType?.procedure === "First Visit" ||
                      appointmentType?.procedure === "Walk-in"
                      ? substituteScan.bite_scan_1
                      : null
                  }
                  handleOemReplaced={() =>
                    handleChangeSubstitutionScan("bite_scan_1")
                  }
                  oemDisabled={isReviewed}
                />
                <ScansDropZone
                  uploadProgress={uploadProgress?.bite_scan_2}
                  disable={isReviewed || disable || loading.disabled}
                  value={
                    uploadedFile.bite_scan_2 ||
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "bite_scan_2"
                    )?.file_url
                      ? stl
                      : null
                  }
                  id="bite_scan_2"
                  handleChange={handleChange}
                  message="Bite scan 2"
                  name={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "bite_scan_2"
                    )?.file_name
                  }
                  createdAt={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "bite_scan_2"
                    )?.created_at
                  }
                  updatedAt={
                    scans.scan_files.find(
                      (scanFile) => scanFile.name === "bite_scan_2"
                    )?.updated_at
                  }
                  disabled={
                    isReviewed ||
                    deleteLoading ||
                    (!scans.scan_files.find(
                      (scanFile) => scanFile.name === "bite_scan_2"
                    )?.id ? true : false)
                  }
                  handleDelete={() =>
                    handleClickOpen(
                      scans.scan_files.find(
                        (scanFile) => scanFile.name === "bite_scan_2"
                      )?.id
                    )
                  }
                  oemReplaced={
                    appointmentType?.procedure === "First Visit" ||
                      appointmentType?.procedure === "Walk-in"
                      ? substituteScan.bite_scan_2
                      : null
                  }
                  handleOemReplaced={() =>
                    handleChangeSubstitutionScan("bite_scan_2")
                  }
                  oemDisabled={isReviewed}
                />
              </Box>
            </>
          )}
          <br />
          <FormControl
            sx={{ marginBottom: 2, width: "fit-content" }}
            size="small"
          >
            <Typography className={classes.title}>
              Segmentation Technician
            </Typography>
            <Select
              disabled={isReviewed}
              value={
                scans?.segmentation_technician_id
                  ? scans?.segmentation_technician_id
                  : ""
              }
              onChange={(e) =>
                handleChangeSegmentationTechnician(
                  "segmentation_technician_id",
                  e.target.value
                )
              }
              displayEmpty
            >
              {segmentationTechnicians.map((option, key) => (
                <MenuItem key={key} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {scans?.segmentation_date && (
              <Typography
                className={classes.date}
              >{`Segmentation Date: ${moment(scans.segmentation_date).format(
                dateFormat
              )}`}</Typography>
            )}
          </FormControl>
          {scans?.scan_files?.length > 0 && (
            <>
              <br />
              <Typography className={classes.size11}>
                <span className={classes.reviewBoldTxt}>Uploaded By:</span>{" "}
                <span>{scans?.owner_name}</span>
              </Typography>
              <br />
            </>
          )}
          {reviewData.modalOpened && (
            <DisapprovalModal
              activeModal={reviewData.modalOpened}
              handleChangeModal={handleChangeModal}
              title={
                reviewData.status === ReviewStatus.approvedWithIssues
                  ? "Approve With Issues"
                  : "Disapprove Scans"
              }
              dropDownTitle="Scan Issues"
              handleSubmitDisapprove={() =>
                handleReviewStatus(reviewData.status)
              }
              loading={reviewData.loading}
              commentValue={reviewData.comment}
              handleChangeComment={handleChangeComment}
              commentError={reviewData.commentError}
              setFilesImage={setFileImages}
              filesImage={fileImages}
              images={images}
              setImages={setImages}
              imagesKey={imageKeys}
              setImageKey={setImageKeys}
              issueType={
                reviewData.status === ReviewStatus.approvedWithIssues
                  ? IssueTypes.scansApprovedWithIssues
                  : IssueTypes.scansNotApproved
              }
              setIssues={setScanIssues}
              issues={scanIssues}
              options={options}
              setOptions={setOptions}
              showTpDropdown={
                reviewData.status === ReviewStatus.approvedWithIssues
              }
              setTpOption={setTpOption}
              tpOptionError={reviewData.tpOptionError}
            />
          )}
          <AlertDeleteDialog
            open={open}
            setOpen={setOpen}
            handleAction={handleDelete}
          />
          <Box ref={substituteFiles} className={classes.box}>
            {substituteScan.document && (
              <ScansDropZone
                uploadProgress={uploadProgress?.substitute_document}
                disable={isReviewed || disable || loading.disabled}
                value={
                  uploadedFile.substitute_document ||
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "document"
                  )?.file_url
                    ? stl
                    : null
                }
                id="substitute_document"
                handleChange={handleChange}
                message="Upload upper scan"
                name={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "document"
                  )?.file_name
                }
                createdAt={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "document"
                  )?.created_at
                }
                updatedAt={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "document"
                  )?.updated_at
                }
                disabled={
                  isReviewed ||
                  deleteLoading ||
                  (!scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "document"
                  )?.id ? true : false)
                }
                handleDelete={() =>
                  handleClickOpen(
                    scans.substitute_file?.scan_files?.find(
                      (scanFile) => scanFile.name === "document"
                    )?.id
                  )
                }
              />
            )}
            {substituteScan.lower_scan && (
              <ScansDropZone
                uploadProgress={uploadProgress?.substitute_lower_scan}
                disable={isReviewed || disable || loading.disabled}
                value={
                  uploadedFile.substitute_lower_scan ||
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "lower_scan"
                  )?.file_url
                    ? stl
                    : null
                }
                id="substitute_lower_scan"
                handleChange={handleChange}
                message="Upload lower scan"
                name={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "lower_scan"
                  )?.file_name
                }
                createdAt={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "lower_scan"
                  )?.created_at
                }
                updatedAt={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "lower_scan"
                  )?.updated_at
                }
                scanFileId={scans?.lower_scan_id}
                disabled={
                  isReviewed ||
                  deleteLoading ||
                  (!scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "lower_scan"
                  )?.id ? true : false)
                }
                handleDelete={() =>
                  handleClickOpen(
                    scans.substitute_file?.scan_files?.find(
                      (scanFile) => scanFile.name === "lower_scan"
                    )?.id
                  )
                }
              />
            )}
            {substituteScan.bite_scan_1 && (
              <ScansDropZone
                uploadProgress={uploadProgress?.substitute_bite_scan_1}
                disable={isReviewed || disable || loading.disabled}
                value={
                  uploadedFile.substitute_bite_scan_1 ||
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "bite_scan_1"
                  )?.file_url
                    ? stl
                    : null
                }
                id="substitute_bite_scan_1"
                handleChange={handleChange}
                message="Bite scan 1"
                name={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "bite_scan_1"
                  )?.file_name
                }
                createdAt={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "bite_scan_1"
                  )?.created_at
                }
                updatedAt={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "bite_scan_1"
                  )?.updated_at
                }
                disabled={
                  isReviewed ||
                  deleteLoading ||
                  (!scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "bite_scan_1"
                  )?.id ? true : false)
                }
                handleDelete={() =>
                  handleClickOpen(
                    scans.substitute_file?.scan_files?.find(
                      (scanFile) => scanFile.name === "bite_scan_1"
                    )?.id
                  )
                }
              />
            )}
            {substituteScan.bite_scan_2 && (
              <ScansDropZone
                uploadProgress={uploadProgress?.substitute_bite_scan_2}
                disable={isReviewed || disable || loading.disabled}
                value={
                  uploadedFile.substitute_bite_scan_2 ||
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "bite_scan_2"
                  )?.file_url
                    ? stl
                    : null
                }
                id="substitute_bite_scan_2"
                handleChange={handleChange}
                message="Bite scan 2"
                name={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "bite_scan_2"
                  )?.file_name
                }
                createdAt={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "bite_scan_2"
                  )?.created_at
                }
                updatedAt={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "bite_scan_2"
                  )?.updated_at
                }
                disabled={
                  isReviewed ||
                  deleteLoading ||
                  (!scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "bite_scan_2"
                  )?.id ? true : false)
                }
                handleDelete={() =>
                  handleClickOpen(
                    scans.substitute_file?.scan_files?.find(
                      (scanFile) => scanFile.name === "bite_scan_2"
                    )?.id
                  )
                }
              />
            )}
            {substituteScan.scans_folder && (
              <ScansDropZone
                uploadProgress={uploadProgress?.substitute_scans_folder}
                disable={isReviewed || disable || loading.disabled}
                value={
                  uploadedFile.substitute_scans_folder ||
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "scans_folder"
                  )?.file_url
                    ? stl
                    : null
                }
                id="substitute_scans_folder"
                handleChange={handleFolderUpload}
                message="Scans Folder"
                name={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "scans_folder"
                  )?.name
                }
                createdAt={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "scans_folder"
                  )?.created_at
                }
                updatedAt={
                  scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "scans_folder"
                  )?.updated_at
                }
                disabled={
                  isReviewed ||
                  deleteLoading ||
                  (!scans.substitute_file?.scan_files?.find(
                    (scanFile) => scanFile.name === "scans_folder"
                  )?.id ? true : false)
                }
                handleDelete={() =>
                  handleClickOpen(
                    scans.substitute_file?.scan_files?.find(
                      (scanFile) => scanFile.name === "scans_folder"
                    )?.id
                  )
                }
                folderUpload
              />
            )}
          </Box>
          <br />
          {scans.substitute_file?.scan_files?.length && (
            <FormControl
              sx={{ marginBottom: 2, width: "fit-content" }}
              size="small"
            >
              <Typography className={classes.title}>
                Segmentation Technician
              </Typography>
              <Select
                disabled={isReviewed}
                value={
                  scans.substitute_file?.segmentation_technician_id
                    ? scans.substitute_file?.segmentation_technician_id
                    : ""
                }
                onChange={(e) =>
                  handleChangeSegmentationTechnician(
                    "substitute_segmentation_technician_id",
                    e.target.value
                  )
                }
                displayEmpty
              >
                {segmentationTechnicians.map((option, key) => (
                  <MenuItem key={key} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {scans.substitute_file?.segmentation_date && (
                <Typography
                  className={classes.date}
                >{`Segmentation Date: ${moment(
                  scans.substitute_file?.segmentation_date
                ).format(dateFormat)}`}</Typography>
              )}
            </FormControl>
          )}
          <br />
          {scans.substitute_file?.owner_name && (
            <>
              <br />
              <Typography className={classes.size11}>
                <span className={classes.reviewBoldTxt}>Uploaded By:</span>{" "}
                <span>{scans.substitute_file.owner_name}</span>
              </Typography>
              <br />
            </>
          )}
          <ApprovalActions
            disabled={
              hasDelayedAction ||
              delayedActionJob.disabledRecord == `${caseRecordTypes.UserCaseRecordScan}-${parentId}` || 
              !scans?.scan_files?.length ||
              loading.disableReviewBtn ||
              scans?.review_status != null
            }
            reviewStatus={scans?.review_status}
            reviewer={scans?.reviewer}
            reviewedAt={scans?.reviewed_at}
            handleChangeReview={handleChangeReview}
            withApprovedWithIssuesOption={true}
          />
          <br />
        </div>
      )}
      {scans?.replaced_file && (
        <Scans
          scans={scans.replaced_file}
          appointmentType={appointmentType}
          disable={disable}
          segmentationTechnicians={segmentationTechnicians}
          appointmentId={appointmentId}
          handleDataFetched={handleDataFetched}
          userId={userId}
          classes={classes}
          setErrorMsg={setErrorMsg}
          loading={loading}
          setLoading={setLoading}
          scannerPlaceholders={scannerPlaceholders}
          updateReviewStatus={updateReviewStatus}
          delayedActionJob={delayedActionJob}
          appointmentNumber={appointmentNumber}
          parentId={parentId}
          hasDelayedAction={hasDelayedAction}
        />
      )}
    </div>
  );
};

export default Scans;
