import AppointmentScreeningPhotos from "./AppointmentScreeningPhotos";
import AppointmentInformation from "./AppointmentInformation";
import AppointmentScans from "./AppointmentScans";
import AppointmentXray from "./AppointmentXray";
import AppointmentRedoPhotos from "./AppointmentRedoPhotos";
import AppointmentCheckup from "./AppointmentCheckup";
import AppointmentPostIprPhotos from "./AppointmentPostIprPhotos";
import AppointmentPostTreatmentPhotos from "./AppointmentPostTreatmentPhotos";
import AppointmentMedia from "./AppointmentMedia";
import AppointmentInformationMallCollab from "./AppointmentInformationMallCollab";
import AppointmentAttachment from "./AppointmentAttachment";
import AppointmentButtons from "./AppointmentButtons";


const AppointmentProcedureFiles = ({
  caseRecord,
  index,
  handleTabsExpand,
  appointmentId,
  appointmentNumber,
  procedure,
  openCloseTabs,
  setDisable,
  disable,
  userId,
  scrollToExpandedTab,
  delayedActionJob,
  updateReviewStatus,
}) => {
  let procedureFiles = <></>;
  const { data, type } = caseRecord;

  switch (type) {
    case "user_case_record_screening_photos":
      procedureFiles = (
        <AppointmentScreeningPhotos
          index={index}
          userId={userId}
          handleTabsExpand={handleTabsExpand}
          appointmentId={appointmentId}
          appointmentNumber={appointmentNumber}
          appointmentType={procedure}
          openCloseTabs={openCloseTabs}
          disable={disable}
          scrollToExpandedTab={scrollToExpandedTab}
          delayedActionJob={delayedActionJob}
          updateReviewStatus={updateReviewStatus}
        />
      );
      break;
    case "user_case_record_information":
      procedureFiles = (
          data?.is_mall_collaboration ?
          <AppointmentInformationMallCollab
            index={index}
            userId={userId}
            setDisable={setDisable}
            handleTabsExpand={handleTabsExpand}
            appointmentId={appointmentId}
            appointmentType={procedure}
            openCloseTabs={openCloseTabs}
            scrollToExpandedTab={scrollToExpandedTab}
            delayedActionJob={delayedActionJob}
            updateReviewStatus={updateReviewStatus}
          /> :
          <AppointmentInformation
            index={index}
            userId={userId}
            setDisable={setDisable}
            handleTabsExpand={handleTabsExpand}
            appointmentId={appointmentId}
            appointmentType={procedure}
            openCloseTabs={openCloseTabs}
            scrollToExpandedTab={scrollToExpandedTab}
            delayedActionJob={delayedActionJob}
            updateReviewStatus={updateReviewStatus}
          />
      );
      break;
    case "user_case_record_scans":
      procedureFiles = (
        <AppointmentScans
          index={index}
          disable={disable}
          handleTabsExpand={handleTabsExpand}
          userId={userId}
          appointmentId={appointmentId}
          appointmentNumber={appointmentNumber}
          appointmentType={procedure}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
          delayedActionJob={delayedActionJob}
          updateReviewStatus={updateReviewStatus}
        />
      );
      break;
    case "user_case_record_xrays":
    case "user_case_record_xrays_mandatory":
      procedureFiles = (
        <AppointmentXray
          userId={userId}
          appointmentId={appointmentId}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
          delayedActionJob={delayedActionJob}
          updateReviewStatus={updateReviewStatus}
        />
      );
      break;
    case "user_case_record_post_redo_photos":
      procedureFiles = (
        <AppointmentRedoPhotos
          userId={userId}
          appointmentId={appointmentId}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
          delayedActionJob={delayedActionJob}
          updateReviewStatus={updateReviewStatus}
        />
      );
      break;
    case "checkup_information":
    case "checkup_information_optional":
      procedureFiles = (
        <AppointmentCheckup
          userId={userId}
          appointmentId={appointmentId}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
          delayedActionJob={delayedActionJob}
          updateReviewStatus={updateReviewStatus}
        />
      );
      break;
    case "user_case_record_post_ipr_photos":
      procedureFiles = (
        <AppointmentPostIprPhotos
          userId={userId}
          appointmentId={appointmentId}
          appointmentNumber={appointmentNumber}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
          delayedActionJob={delayedActionJob}
          updateReviewStatus={updateReviewStatus}
        />
      );
      break;
    case "user_case_record_post_treatment_photos":
      procedureFiles = (
        <AppointmentPostTreatmentPhotos
          userId={userId}
          appointmentId={appointmentId}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
          delayedActionJob={delayedActionJob}
          updateReviewStatus={updateReviewStatus}
        />
      );
      break;
    case "user_case_record_media":
    case "user_case_record_media_optional":
      procedureFiles = (
        <AppointmentMedia
          userId={userId}
          appointmentId={appointmentId}
          appointmentNumber={appointmentNumber}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
          delayedActionJob={delayedActionJob}
          updateReviewStatus={updateReviewStatus}
        />
      );
      break;
    case "user_case_record_attachments":
      procedureFiles = (
        <AppointmentAttachment
          userId={userId}
          appointmentId={appointmentId}
          appointmentNumber={appointmentNumber}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
          delayedActionJob={delayedActionJob}
          updateReviewStatus={updateReviewStatus}
        />
      );
      break;
    case "user_case_record_buttons":
      procedureFiles = (
        <AppointmentButtons
          userId={userId}
          appointmentId={appointmentId}
          appointmentNumber={appointmentNumber}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
          delayedActionJob={delayedActionJob}
          updateReviewStatus={updateReviewStatus}
        />
      );
      break;
    default:
      break;
  }

  return procedureFiles;
};

export default AppointmentProcedureFiles;