import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { Checkbox, DropZone } from '@shopify/polaris';
import React from 'react'
import CreatedUpdated from './CreatedUpdated';
import DeleteBtn from './DeleteBtn';

const useStyles = makeStyles(() => {
    return {
        wrapper: {
            position: 'relative',
            cursor: 'pointer',
            height: '128px',
            width: '204px',
            borderRadius: '1.5px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            margin: '0.5rem 0',
        },
        imageSign: {
            position: 'absolute',
            width: 'fit-content',
            padding: '0 0.5rem',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#FFFFFF',
            backgroundColor: 'rgba(0,0,0,0.6)',
            fontSize: '9px !important',
            letterSpacing: 0,
            lineHeight: '13px',
            textAlign: 'center',
        },
        bottom: {
            bottom: 10
        },
        top: {
            top: '45%',
        },
        imagePlaceholder: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            color: '#007ACE',
            fontSize: '9px !important',
            width: 'fit-content',
            letterSpacing: 0,
            lineHeight: '13px',
            textAlign: 'right'
        },
        label: {
            position: 'absolute',
            cursor: 'pointer',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
        },
        dropZoneDesign: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            bottom: 0,
            border: '0.1rem solid',
            opacity: 0,
            '& div': {
                height: '100%',
                '& div': {
                    height: '100%',
                }
            }
        },
        imgBox: {
            height: '100%',
            width: '100%',
        },
        progressBar: {
            width: '100%',
            margin: '1rem 0',
        }
    }
})

export default function ScansDropZone({
    value,
    id,
    handleChange,
    message,
    name,
    disable,
    uploadProgress,
    createdAt,
    updatedAt,
    disabled,
    handleDelete,
    oemReplaced = null,
    handleOemReplaced,
    oemDisabled,
    folderUpload = false,
}) {
    const classes = useStyles();
    return (
        <Box>
            <Box className={classes.wrapper}>
                {value ?
                    <>
                        <Box component="img" className={classes.imgBox} src={value} />
                        <Typography className={`${classes.imageSign} ${classes.top}`}>{name}</Typography>
                    </>
                    :
                    <>
                        <Box className={classes.imgBox} />
                        <Typography className={classes.imagePlaceholder}>{message}</Typography>
                    </>
                }
                <Typography className={`${classes.imageSign} ${classes.bottom}`}>{value ? 'Replace' : 'Upload image'}</Typography>
                <div className={`${classes.dropZoneDesign} case-record-drop-zone-design`}>
                    {
                        !folderUpload ?
                        <DropZone
                            disabled={disable}
                            onDropAccepted={(file) => handleChange(file, id)}
                            id={id}
                        /> :
                        <label className={classes.label}>
                            <input
                                disabled={disable}
                                type="file"
                                webkitdirectory="true"
                                mozdirectory="true"
                                onChange={(file) => handleChange(file, id)}
                            />
                        </label>
                    }
                </div>
            </Box>
            {uploadProgress &&
                <Box className={classes.progressBar}>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                </Box>
            }
            {
                oemReplaced !== null &&
                <>
                    <Checkbox
                        label='Add substitute file'
                        checked={oemReplaced}
                        onChange={handleOemReplaced}
                        disabled={oemDisabled || !value}
                    />
                    <br />
                </>
            }
            <CreatedUpdated createdAt={createdAt} updatedAt={updatedAt} />
            <DeleteBtn disabled={disabled} handleDelete={handleDelete}/>
        </Box>
    )
}
